export const BlogsRow1 = [

    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/AI.webp",
        title: "The Impact of Artificial Intelligence on Aviation",
        link: "/The-Impact-of-Artificial-Intelligence-on-Aviation"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/Career%20Opportunites.webp",
        title: "Exploring Career Opportunities in Aviation",
        link: "/Exploring-Career-Opportunities-in-Aviation"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/Day%20in%20a%20Life%20of%20a%20Aviation%20Students.webp",
        title: "A Day in the Life of an Aviation Student",
        link: "/A-Day-in-the-Life-of-an-Aviation-Student"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/Desiding%20between%20studies%20in%20abroad.webp",
        title: "Deciding Between Studying CPL Abroad or in India",
        link: "/Deciding-Between-Studying-CPL-Abroad-or-in-India"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/Electric.webp",
        title: "The Development of Electric and hybrid Aircraft",
        link: "/The-Development-of-Electric-and-hybrid-Aircraft"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/Emerging%20Technologies%20in%20Aviation.webp",
        title: "Emerging Technologies in Aviation",
        link: "/Emerging-Technologies-in-Aviation"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/Fly%20High.webp",
        title: "Flying High: Why Choosing the Aviation Industry?",
        link: "/Flying-High:-Why-Choosing-the-Aviation-Industry"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/Navigating%20the%20Skies.webp",
        title: "Navigating the Skies",
        link: "/Navigating-the-Skies"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/Pilots%20Demand.webp",
        title: "Demand for Pilots",
        link: "/Demand-for-Pilots"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/Post.webp",
        title: "How the Pandemic Reshaped Air Travel",
        link: "/How-the-Pandemic-Reshaped-Air-Travel"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/12.webp",
        title: "The Future of Aviation Careers",
        link: "/The-Future-of-Aviation-Careers"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/11.webp",
        title: "The Benefits of an Aviation Degree",
        link: "/The-Benefits-of-an-Aviation-Degree"
    },

]

export const BlogsRow2 = [

    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/Reaching%20a%20pilot.webp",
        title: "Your Journey to Become a Pilot",
        link: "/Your-Journey-to-Become-a-Pilot"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/Top%2010%20Colleges.webp",
        title: "Top 10 Colleges for Pursuing BSc Aviation in India",
        link: "/Top-10-Colleges-for-Pursuing-BSc-Aviation-in-India"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/Urban.webp",
        title: "The Future of Urban Air Mobility",
        link: "/The-Future-of-Urban-Air-Mobility"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/Womens%20in%20Aviation.webp",
        title: "Zooming High: Celebrating Women in Aviation",
        link: "/Zooming-High:-Celebrating-Women-in-Aviation"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/1.webp",
        title: "Nurturing Future Aviators",
        link: "/Nurturing-Future-Aviators"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/2.webp",
        title: "Exciting Internship Opportunities",
        link: "/Exciting-Internship-Opportunities"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/3.webp",
        title: "Tips for a Successful Aviation Career Journey",
        link: "/Tips-for-a-Successful-Aviation-Career-Journey"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/Big%20Data%20in%20Aviation.webp",
        title: "Big Data Taking Flight",
        link: "/Big-Data-Taking-Flight"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/Air%20Traffic%20Management.webp",
        title: "Air traffic management (ATM)",
        link: "/Air-traffic-management"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/Impact%20of%20cybersecurity.webp",
        title: "The Impact of Cybersecurity on Aviation",
        link: "/The-Impact-of-Cybersecurity-on-Aviation"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/Different%20types%20of%20Aviation%20Degreee.webp",
        title: "Different Types of Aviation Degrees",
        link: "/Different-Types-of-Aviation-Degrees"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/10.webp",
        title: "The Best Aviation Schools and Programs",
        link: "/The-Best-Aviation-Schools-and-Programs"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/blogs/Tamilnadu%20Launchpad.webp",
        title: "Tamil Nadu: Your Launchpad for an Aviation Career in India",
        link: "/Tamil-Nadu:Your-Launchpad-for-an-Aviation-Career-in-India"
    },

]