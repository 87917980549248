import React from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function Blog12() {
    return (
        <div>
            <div className='xl:pt-0 sm:pt-12'>
                <FadeInSectionDownToUp>
                    <h1 className='text-center font-semibold text-3xl xl:text-3xl sm:text-xl max-sm:text-lg py-5'>The Benefits of an Aviation Degree</h1>
                </FadeInSectionDownToUp>
                <hr />
            </div>
            <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 text-justify'>
                <div className='py-5'>
                    <img src='https://ik.imagekit.io/mhcockpit1/blogs/11.webp' alt='The Benefits of an Aviation Degree' />
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            The aviation industry is a dynamic and exciting field, offering diverse career paths and the chance to be part of something truly global. But what if you could take your passion for aviation further and gain a competitive edge in the job market? An aviation degree might be the perfect launchpad for your career aspirations.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Wide-Ranging Career Opportunities
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            An aviation degree isn't just a one-way ticket to becoming a pilot. It opens doors to a multitude of fulfilling careers within the industry. Here are just a few examples:
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className="text-lg mb-5 text-justify">
                    <ul className='space-y-3'>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Pilots</b> Of course, piloting remains a popular choice for aviation degree holders. From commercial airlines to private jets and cargo carriers, various options exist to suit your preferences.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Air Traffic Controllers</b> Ensure the safety and efficiency of air travel by guiding pilots through the skies. This demanding role requires sharp focus, decision-making skills, and the ability to thrive under pressure.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Aircraft Mechanics</b> Keep the wheels (or wings) of the industry turning by maintaining and repairing aircraft, ensuring they meet strict safety standards.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Aviation Managers</b> Oversee the smooth operation of airports, airlines, and other aviation businesses, requiring strong leadership, organizational, and problem-solving skills.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Aerospace Engineers</b> Design and develop the next generation of aircraft, pushing the boundaries of innovation and technology.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                    </ul>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            These are just a glimpse into the diverse career paths available with an aviation degree.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Earning Potential and Job Market Outlook
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            The earning potential within the aviation industry varies depending on the specific career path, experience level, and location. However, many aviation professions offer competitive salaries and attractive benefits packages. According to the U.S. Bureau of Labor Statistics, airline pilots, copilots, and flight engineers have a median annual wage of $164,890 (as of May 2022) .
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            Furthermore, the job market for many aviation professions is projected to experience growth in the coming years. The Bureau of Labor Statistics anticipates a 5% growth in employment for airline and commercial pilots between 2022 and 2032, faster than the average for all occupations .
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            In Conclusion
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            An aviation degree equips you with the knowledge, skills, and industry know-how to navigate a rewarding career in this dynamic field. With diverse career paths, promising earning potential, and projected job market growth, pursuing an aviation degree can be a strategic investment in your future. If you're passionate about aviation and eager to explore the exciting possibilities it holds, an aviation degree might be the perfect way to take flight.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
        </div>
    )
}
