import React, { useEffect, useRef } from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp';
import { useNavigate } from 'react-router-dom';

export default function ATPL() {

    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;

        if (video) {
            const playPromise = video.play();

            if (playPromise !== undefined) {
                playPromise
                    .then(_ => {
                        // Autoplay started
                        console.log('Autoplay started');
                    })
                    .catch(error => {
                        // Autoplay was prevented, unmute and play
                        video.muted = true; // Unmute the video
                        video.play().catch(error => {
                            console.error('Autoplay was blocked:', error);
                        });
                    });
            }
        }
    }, []);

    const navigate = useNavigate();

    return (
        <div>
            <div>
                <video autoPlay loop playsInline className='w-full'>
                    <source src={"https://mh-cockpit.blr1.cdn.digitaloceanspaces.com/ATPL%20Final%20Out.mp4"} type='video/mp4' />
                </video>
            </div>
            <div className='px-20 pb-20 xl:px-20 lg:px-20 max-sm:px-5 sm:px-5'>
                <div className='w-full bg-white -mt-20 xl:-mt-56 max-sm:-mt-10 py-20 xl:py-20 max-sm:py-10 sm:py-10 px-20 xl:px-20 max-sm:px-5 sm:px-5 rounded-3xl shadow-2xl shadow-gray-700 relative'>
                    <FadeInSectionDownToUp>
                        <h1 className='text-5xl max-sm:text-3xl text-center font-semibold'>ATPL - <span className='text-[#0d7ecc]'>Ground Classes.</span></h1>
                    </FadeInSectionDownToUp>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='text-lg text-justify pt-20 max-sm:pt-10'>
                                ATPL - Ground Classes. Elevate Your Career with ATPL Ground Classes - Your Path to Commanding the Skies!
                            </p>
                            <p className='text-lg text-justify py-5 max-sm:py-5'>
                                Unlock boundless opportunities as you soar towards becoming a global captain with the Airline Transport Pilot License (ATPL). Recognized as the pinnacle of pilot licensure, the ATPL empowers you to take control of aircraft worldwide. At MH Cockpit, our ATPL Ground Classes seamlessly integrate with the DGCA CPL syllabus, ensuring you're well-prepared for the challenges of both the theory and oral examinations.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='flex xl:flex-row lg:flex-row max-sm:flex-col sm:flex-col pt-14 gap-2'>
                        <div className='bg-black text-white w-1/2 lg:w-1/2 xl:w-1/2 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 rounded-3xl'>
                            <div>
                                <FadeInSectionDownToUp>
                                    <p className='text-[#0d7ecc] font-bold text-xl pb-3'>Eligibility</p>
                                </FadeInSectionDownToUp>
                                <FadeInSectionDownToUp>
                                    <p className='text-[#0d7ecc] font-bold text-lg pt-3'>Age :</p>
                                    <p className='text-xl'>
                                        Applicants must be at least 21 years old when submitting their application.
                                    </p>
                                </FadeInSectionDownToUp>
                                <FadeInSectionDownToUp>
                                    <p className='text-[#0d7ecc] font-bold text-lg pt-3'>Medical :</p>
                                    <p className='text-xl'>
                                        Candidates need to provide a fitness certificate from an approved Medical Board, confirming their physical fitness based on specified requirements.
                                    </p>
                                </FadeInSectionDownToUp>
                                <FadeInSectionDownToUp>
                                    <p className='text-[#0d7ecc] font-bold text-lg pt-3'>Knowledge :</p>
                                    <p className='text-xl'>
                                        As part of the eligibility, candidates must successfully pass written exams and viva in the following subjects:
                                    </p>
                                </FadeInSectionDownToUp>
                                <FadeInSectionDownToUp>
                                    <p className='pl-5 text-lg pt-5'>Written Exams :</p>
                                    <ul className='pl-8'>
                                        <div className='flex'>
                                            <span className='text-xl text-[#0d7ecc] font-bold mr-2'>&#9992;</span><li className=''>Aviation Meteorology.</li>
                                        </div>
                                        <div className='flex'>
                                            <span className='text-xl text-[#0d7ecc] font-bold mr-2'>&#9992;</span><li className=''>Radio Aids and Instruments.</li>
                                        </div>
                                        <div className='flex'>
                                            <span className='text-xl text-[#0d7ecc] font-bold mr-2'>&#9992;</span><li className=''>General Navigation.</li>
                                        </div>
                                    </ul>
                                    <p className='pl-5 text-lg'>Viva :</p>
                                    <ul className='pl-8'>
                                        <div className='flex'>
                                            <span className='text-xl text-[#0d7ecc] font-bold mr-2'>&#9992;</span><li className=''>Radio Aids and Instruments.</li>
                                        </div>
                                        <div className='flex'>
                                            <span className='text-xl text-[#0d7ecc] font-bold mr-2'>&#9992;</span><li className=''>General Navigation.</li>
                                        </div>
                                    </ul>
                                </FadeInSectionDownToUp>
                            </div>
                        </div>
                        <div className='bg-black text-white w-1/2 lg:w-1/2 xl:w-1/2 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 max-sm:px-5 rounded-3xl'>
                            <div>
                                <FadeInSectionDownToUp>
                                    <p className='text-[#0d7ecc] font-bold text-xl pb-3'>Flying Experience</p>
                                </FadeInSectionDownToUp>
                                <FadeInSectionDownToUp>
                                    <p className='text-xl'>
                                        Minimum of 1500 Hrs of flying are required; out of which 150 Hrs must be in the last 12 months.
                                    </p>
                                </FadeInSectionDownToUp>
                                <FadeInSectionDownToUp>
                                    <p className='pl-5 text-lg pt-5'>His/Her total experience shall include :</p>
                                </FadeInSectionDownToUp>
                                <FadeInSectionDownToUp>
                                    <ul className='pl-8 pt-3'>
                                        <div className='flex'>
                                            <span className='text-xl text-[#0d7ecc] font-bold mr-2'>&#9992;</span><li className=''>500 Hrs of flight time as Pilot-in-Command or as Co-Pilot performing under the supervision of a pilot who fulfils the flying experience requirements of a Check Pilot.</li>
                                        </div>
                                        <div className='flex'>
                                            <span className='text-xl text-[#0d7ecc] font-bold mr-2'>&#9992;</span><li className=''>Out of above 500 Hrs of flight time, 200 Hrs must be Cross-Country including 50 Hrs of Night Flying.</li>
                                        </div>
                                        <div className='flex'>
                                            <span className='text-xl text-[#0d7ecc] font-bold mr-2'>&#9992;</span><li className=''>Minimum 1000 Hrs of Cross-Country flight time.</li>
                                        </div>
                                        <div className='flex'>
                                            <span className='text-xl text-[#0d7ecc] font-bold mr-2'>&#9992;</span><li className=''>Minimum 100 Hrs of Night flying.</li>
                                        </div>
                                        <div className='flex'>
                                            <span className='text-xl text-[#0d7ecc] font-bold mr-2'>&#9992;</span><li className=''>Minimum 100 Hrs of Instrument time.</li>
                                        </div>
                                        <div className='flex'>
                                            <span className='text-xl text-[#0d7ecc] font-bold mr-2'>&#9992;</span><li className=''>Minimum 10 Hrs of flight time in the last 6 months.</li>
                                        </div>
                                        <div className='flex'>
                                            <span className='text-xl text-[#0d7ecc] font-bold mr-2'>&#9992;</span><li className=''>Must have a valid Instrument Rating.</li>
                                        </div>
                                        <div className='flex'>
                                            <span className='text-xl text-[#0d7ecc] font-bold mr-2'>&#9992;</span><li className=''>Must have a valid Flight Radio Telephone Operators Licence.</li>
                                        </div>
                                    </ul>
                                </FadeInSectionDownToUp>
                            </div>
                        </div>
                    </div>
                    <div className='flex xl:flex-row lg:flex-row max-sm:flex-col sm:flex-col pt-2 gap-2'>
                        <div className='bg-black text-white w-1/3 lg:w-1/3 xl:w-1/3 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 max-sm:px-5 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-[#0d7ecc] font-bold text-xl pb-3'>Duration</p>
                                <p className='text-2xl'>45 Days</p>
                            </FadeInSectionDownToUp>
                        </div>
                        <div className='bg-black text-white w-1/3 lg:w-1/3 xl:w-1/3 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-[#0d7ecc] font-bold text-xl pb-3'>Subjects</p>
                                <ul>
                                    <div className='flex'>
                                        <span className='text-xl text-[#0d7ecc] font-bold mr-2'>&#9992;</span><li className=''>Aviation Meteorology.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#0d7ecc] font-bold mr-2'>&#9992;</span><li className=''>Radio Aids and Instruments.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#0d7ecc] font-bold mr-2'>&#9992;</span><li className=''>General Navigation.</li>
                                    </div>
                                </ul>
                            </FadeInSectionDownToUp>
                        </div>
                        <div className='bg-black text-white w-1/3 lg:w-1/3 xl:w-1/3 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-[#0d7ecc] font-bold text-xl pb-3'>VIVA</p>
                                <ul>
                                    <div className='flex'>
                                        <span className='text-xl text-[#0d7ecc] font-bold mr-2'>&#9992;</span><li className=''>Aviation Meteorology.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#0d7ecc] font-bold mr-2'>&#9992;</span><li className=''>Radio Aids and Instruments.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#0d7ecc] font-bold mr-2'>&#9992;</span><li className=''>General Navigation.</li>
                                    </div>
                                </ul>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                    <div className='flex justify-center items-center py-10'>
                        <FadeInSectionDownToUp>
                            <a href='/private-pilot-license' className='text-[#0d7ecc] underline text-3xl'>Course Content</a>
                        </FadeInSectionDownToUp>
                    </div>
                    <FadeInSectionDownToUp>
                        <div className='grid grid-cols-2 gap-2 justify-center items-center px-96 max-sm:px-0 sm:px-40'>
                            <button onClick={() => navigate('/contact')} className='bg-[#0d7ecc] text-white py-2 rounded-xl mx-2'>For Queries</button>
                            <button onClick={() => navigate('/contact')} className='bg-[#0d7ecc] text-white py-2 rounded-xl mx-2'>Apply Now</button>
                        </div>
                    </FadeInSectionDownToUp>
                </div>
            </div>
        </div>
    )
}
