export const CollegesData = [

    {
        src: "https://ik.imagekit.io/mhcockpit1/Colleges/VELS.webp",
        title: "Vels Institute of Science Technology and Advanced Studies.",
        location: "Chennai.",
        ugc: "UGC Recognised",
        courses: "B.Sc. Aeronautical science | B.Sc Aviation | BBA Aviation | MBA Aviation | Diploma in Cabin Crew | Diploma in Airport Management | PG Diploma in Aircraft Maintenance Engineering",
        button: "To Apply",
        link: "/vels-university"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Colleges/MRU.webp",
        title: "Malla Reddy University.",
        location: "Hyderabad.",
        ugc: "UGC Recognised",
        courses: "BBA in Aviation and Tourism Management | BBA in Logistics & Supply Chain | MBA HR, Marketing, Finance (with Aviation electives) | MBA in Logistics and Supply Chain Management",
        button: "To Apply",
        link: "https://mru.univirt.in/"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Colleges/CRESCENT.jpg",
        title: "Crescent University.",
        location: "Chennai.",
        ugc: "UGC Recognised",
        courses: "B.Sc Aviation",
        button: "To Apply",
        link: "/crescent-landing"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Colleges/JMC.webp",
        title: "Jamal Mohamed College.",
        location: "Trichy.",
        ugc: "UGC Recognised",
        courses: "BBA Aviation",
        button: "To Apply",
        link: "/jamal-mohamed-college"
    }

]