const metaDescriptions = {
    '/': 'MH Cockpit',
    '/training': "MH Cockpit offers comprehensive aviation training in partnership with India's Vels College. Prepare for exciting careers in airlines, airports, and more.",
    '/private-pilot-license': "Discover the gateway to your aviation dreams with MH Cockpit, in partnership with Vels College, India's leading aviation training institute. Our Private Pilot License program offers world-class education led by industry veterans, with a track record of success. Join our diverse community of 1000+ trained students from 21 states and beyond, and kickstart your journey towards a rewarding career in aviation. With internships in Civil aviation and Defense units, our alumni soar to new heights, with freshers securing placements earning up to 33 LPA. Explore your potential with us today!",
    '/commercial-pilot-license': "Embark on your journey to becoming a private pilot with MH Cockpit. Explore our comprehensive private pilot license program designed to equip you with the skills and knowledge needed for success in the skies. From rigorous training modules to expert guidance, unlock the freedom of flight with MH Cockpit today.",
    '/typerating':"Elevate your career with MH Cockpit's type rating programs. Gain the expertise and certification required to operate specific aircraft models safely and efficiently. Explore our tailored training courses and soar to new heights in your aviation journey.",
    '/airline-transport-pilot-license':"Achieve your dream of becoming an airline transport pilot with MH Cockpit. Our comprehensive ATP license program provides the rigorous training and hands-on experience necessary to excel in commercial aviation. Start your journey to commanding the skies with MH Cockpit today.",
    '/dgca-license-conversion':"Navigate the process of DGCA license conversion seamlessly with MH Cockpit. Our expert guidance and streamlined procedures ensure a smooth transition for pilots seeking to convert their licenses to DGCA standards. Trust MH Cockpit for a hassle-free conversion experience.",
    '/dgca-medical-requirements':"Stay informed and compliant with DGCA medical requirements at MH Cockpit. Discover the essential guidelines and procedures for obtaining and maintaining medical certification as per DGCA standards. Your aviation career begins with meeting the necessary medical prerequisites",
    '/dgca-pilot-computer-number':"Obtain your DGCA pilot computer number efficiently with MH Cockpit. Navigate the application process seamlessly with our expert assistance and ensure compliance with DGCA regulations. Trust MH Cockpit to streamline your pilot computer number acquisition.",
    '/bba-aviation':"Explore a career in aviation management with MH Cockpit's BBA program. Dive into comprehensive coursework tailored to the aviation industry, equipping you with the skills and knowledge needed for success in aviation management roles. Launch your career in aviation with MH Cockpit's BBA program today.",
    '/bsc-aircraft-maintenance-science':"Embark on a journey into aircraft maintenance science with MH Cockpit's BSc program. Explore comprehensive coursework and hands-on training designed to equip you with the skills needed for a successful career in aircraft maintenance. Launch your career in aviation maintenance with MH Cockpit",
    '/btech-aircraft-maintenance-engineering':"Pursue excellence in aircraft maintenance engineering with MH Cockpit's BTech program. Gain specialized knowledge and practical skills required for maintaining aircraft at the highest standards of safety and efficiency. Start your journey towards becoming a certified aircraft maintenance engineer with MH Cockpit.",
    '/bsc-aeronautical-science':"Unlock the secrets of flight with MH Cockpit's BSc program in aeronautical science. Dive into advanced coursework and practical training to understand the intricacies of aeronautics and aviation technology. Prepare for a rewarding career in the aerospace industry with MH Cockpit.",
    '/cabincrew':"Embark on a glamorous career in aviation as a cabin crew member with MH Cockpit. Explore our comprehensive training program designed to equip you with the skills and knowledge needed to excel in the skies. Join MH Cockpit and take your first step towards a rewarding career as cabin crew.",
    '/fixedwing':"Experience the thrill of flight with MH Cockpit's fixed-wing training programs. From beginner to advanced levels, our comprehensive training prepares you for piloting fixed-wing aircraft with confidence and skill. Start your journey towards becoming a proficient fixed-wing pilot with MH Cockpit.",
    '/agriculture':"Explore the intersection of aviation and agriculture with MH Cockpit's specialized programs. Discover how aviation technology is revolutionizing agriculture practices and embark on a career at the forefront of innovation. Join MH Cockpit and become a pioneer in agricultural aviation.",
    '/company':"Learn more about MH Cockpit, your partner in aviation excellence. Discover our commitment to providing top-tier aviation training and services, as well as our dedication to shaping the future of aviation professionals. Partner with MH Cockpit for a successful career journey in aviation.",
    '/aircraft-leasing-&-financing':"Navigate the complexities of aircraft leasing and financing with MH Cockpit. Explore our comprehensive services and expert guidance tailored to meet your aircraft leasing and financing needs. Trust MH Cockpit to help you achieve your aviation goals efficiently and effectively.",
    '/flight-simulator':"Experience the ultimate in flight training with MH Cockpit's advanced flight simulators. Immerse yourself in realistic flight scenarios and hone your skills in a safe and controlled environment. Elevate your training with MH Cockpit's state-of-the-art flight simulator technology.",
    '/cessna':"Explore the world of Cessna aircraft with MH Cockpit. From training to proficiency, we offer comprehensive programs tailored to Cessna pilots. Discover the excellence of Cessna aviation with MH Cockpit as your guide.",
    '/airbus':"Unlock the potential of Airbus aircraft with MH Cockpit. Our specialized training programs cater to Airbus pilots, providing the skills and knowledge necessary for safe and efficient operation. Trust MH Cockpit for your Airbus aviation journey.",
    '/boeing':"Take to the skies with confidence in Boeing aircraft, supported by MH Cockpit's expertise. Our tailored training programs are designed to empower Boeing pilots with the skills and knowledge required for successful flights. Partner with MH Cockpit for Boeing excellence",
    '/mhintellect':"Experience the power of MH Cockpit's aviation intelligence solutions. From data analytics to predictive modeling, MH Intellect provides cutting-edge insights to optimize aviation operations. Harness the intelligence of MH Cockpit for smarter aviation decision-making.",
    '/contactus':"Connect with MH Cockpit for all your aviation needs. Whether you're seeking training, information, or assistance, our dedicated team is here to help. Reach out to MH Cockpit and let us support your aviation journey.",
    '/whatdowedo':"Discover the breadth of services offered by MH Cockpit. From training to consulting, we specialize in all aspects of aviation excellence. Explore how MH Cockpit can support your aviation goals today.",
    '/gallery':"Immerse yourself in the world of aviation with MH Cockpit's gallery. Explore captivating images showcasing our training facilities, aircraft, and aviation events. Experience the excitement of aviation through MH Cockpit's gallery.",
    '/pilotgroundinstructor':"Become a certified pilot ground instructor with MH Cockpit. Our comprehensive training programs prepare you to educate and mentor aspiring pilots, shaping the future of aviation. Start your journey towards becoming a pilot ground instructor with MH Cockpit. /ameinstructor",
    '/ameinstructor':"Elevate your career as an aircraft maintenance engineer (AME) instructor with MH Cockpit. Our specialized training equips you with the skills and knowledge needed to train the next generation of AMEs. Join MH Cockpit and inspire future aviation professionals as an AME instructor.",
    '/dronepilots':"Join the future of aviation as a certified drone pilot with MH Cockpit. Our comprehensive training programs cover all aspects of drone operation and regulation compliance. Launch your career in the exciting field of drone piloting with MH Cockpit.",
    '/airportgroundoperationspersonnel':"Prepare for a dynamic career in airport ground operations with MH Cockpit. Our specialized training programs cover essential skills and knowledge required for various roles in airport operations. Start your journey towards becoming a vital part of airport ground operations with MH Cockpit.",
    '/cabincrewinstructor':"Inspire the next generation of cabin crew members as a certified cabin crew instructor with MH Cockpit. Our comprehensive training programs provide you with the skills and knowledge needed to train aspiring cabin crew professionals. Join MH Cockpit and shape the future of cabin crew training.",
    '/flightdispatch':"Master the art of flight dispatch with MH Cockpit's specialized training programs. From flight planning to operational control, our comprehensive curriculum prepares you for a rewarding career in flight dispatch. Start your journey towards becoming a certified flight dispatcher with MH Cockpit.",
    '/it':"Explore the intersection of aviation and technology with MH Cockpit's IT solutions. From software development to network security, we provide cutting-edge IT services tailored to the aviation industry. Partner with MH Cockpit for innovative IT solutions in aviation.",
    '/bdm':"Empower your aviation business with MH Cockpit's business development and management services. From strategy to execution, we offer comprehensive solutions to help your business thrive in the competitive aviation industry. Partner with MH Cockpit for business growth and success",
    '/finance':"Optimize your aviation finances with MH Cockpit's specialized financial services. From budgeting to investment strategies, our expert team provides tailored financial solutions to support your aviation ventures. Partner with MH Cockpit for financial excellence in aviation.",
    '/labestablishment':"Establish cutting-edge aviation training facilities with MH Cockpit's lab establishment services. From design to implementation, we provide comprehensive solutions to create state-of-the-art aviation training labs. Partner with MH Cockpit and set the standard for aviation training excellence.",
    '/ourvalues':"Discover the core values that drive MH Cockpit's commitment to excellence in aviation. From integrity to innovation, our values guide every aspect of our operations and service delivery. Partner with MH Cockpit and experience the difference our values make.",
    '/colleges':"Partner with MH Cockpit to shape the future of aviation education. Our collaboration opportunities for colleges and educational institutions offer access to cutting-edge aviation training programs and resources. Join forces with MH Cockpit to empower the next generation of aviation professionals.",
    '/vels-university':"Explore aviation education at Vels University with MH Cockpit. Discover specialized programs designed to prepare students for dynamic careers in the aviation industry. Partner with MH Cockpit and Vels University for excellence in aviation education",
    'https://mru.univirt.in/':"Unlock opportunities in aviation education at Malla Reddy University with MH Cockpit. Experience comprehensive training programs tailored to the needs of aspiring aviation professionals. Join MH Cockpit and Malla Reddy University for a transformative aviation education journey.",
    '/jamal-mohamed-university':"Embark on a journey of aviation excellence at Jamal Mohamed University with MH Cockpit. Explore innovative aviation training programs designed to nurture the talents of future aviation leaders. Partner with MH Cockpit and Jamal Mohamed University for a rewarding aviation education experience.",
    '/thank_you':"Thank you for engaging with MH Cockpit. We appreciate your interest in our aviation training programs and services. Stay connected with us for updates and insights into the exciting world of aviation.",
    '/privacy-policy':"MH Cockpit is committed to protecting your privacy. Read our privacy policy to understand how we collect, use, and protect your personal information. Trust MH Cockpit for transparent and secure handling of your data.",
    '/terms-and-conditions':"Review the terms and conditions governing the use of MH Cockpit's website and services. By accessing our website or engaging with our services, you agree to abide by these terms and conditions. Trust MH Cockpit for clear and fair terms of use.",
  };
  
  export default metaDescriptions;
  