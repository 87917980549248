export const OurPresenceColleges = [

    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Presence/vels.jpg",
        alt: "Our Presence"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Presence/malla%20reddy.jpg",
        alt: "Our Presence"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Presence/crescent.jpg",
        alt: "Our Presence"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Presence/Jamal%20College.jpg",
        alt: "Our Presence"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Presence/AAA.jpg",
        alt: "Our Presence"
    },

]

export const FTOPartners = [

    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Presence/4%20aviators%20FTO.jpg",
        alt: "FTO Partners"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Presence/Global%20Aviation%20SA%20FTO.jpg",
        alt: "FTO Partners"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Presence/nzaa_net%20FTO.jpg",
        alt: "FTO Partners"
    },

]