import React from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';

export default function DGCAStudents() {

    const CPL = Array.from({ length: 15 }, (_, i) => ({
        src: `https://ik.imagekit.io/mhcockpit1/Students/DGCA%20Exams%20Cleared/CPL/${i + 1}.png`,
    }));
    
    const AME = Array.from({ length: 6 }, (_, i) => ({
        src: `https://ik.imagekit.io/mhcockpit1/Students/DGCA%20Exams%20Cleared/AME/${i + 1}.png`,
    }));

    return (
        <div>
            <div className='text-center'>
                <FadeInSectionDownToUp>
                    <h1 className='font-semibold text-5xl max-sm:text-3xl tracking-tighter py-20 max-sm:py-10'>Commercial Pilot License</h1>
                </FadeInSectionDownToUp>
            </div>
            <div className='grid grid-cols-5 max-sm:grid-cols-2 gap-10 max-sm:gap-3 px-20 max-sm:px-3 py-10'>
                {CPL.map((image, index) => (
                    <div key={index}>
                        <FadeInSectionDownToUp>
                            <img src={image.src} alt='CPL' />
                        </FadeInSectionDownToUp>
                    </div>
                ))}
            </div>
            <div className='text-center'>
                <FadeInSectionDownToUp>
                    <h1 className='font-semibold text-5xl max-sm:text-3xl tracking-tighter py-20 max-sm:py-10'>Aircraft Maintenance Engineering</h1>
                </FadeInSectionDownToUp>
            </div>
            <div className='grid grid-cols-5 max-sm:grid-cols-2 gap-10 max-sm:gap-3 px-20 max-sm:px-3 py-10'>
                {AME.map((image, index) => (
                    <div key={index}>
                        <FadeInSectionDownToUp>
                            <img src={image.src} alt='AME' />
                        </FadeInSectionDownToUp>
                    </div>
                ))}
            </div>
        </div>
    )
}
