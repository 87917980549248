import React from 'react';
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp';

function DGCALC() {

    return (
        <div>
            <div className="relative text-justify">
                <div className="inset-0 overflow-hidden">
                    <img
                        className='w-full h-full object-cover animate-zoomOut'
                        src="https://ik.imagekit.io/mhcockpit1/DGCA-LC.webp"
                        alt="DGCA - License conversion"
                    />
                </div>
                <div className="relative py-10">
                    <div className='pl-40 max-sm:pl-10 text-4xl max-sm:text-2xl font-semibold tracking-tighter py-10'>
                        <FadeInSectionDownToUp>
                            <h1>DGCA - License conversion.</h1>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-xl'>
                        <FadeInSectionDownToUp>
                            <p>
                                <b>Guide for conversion of Foreign Pilot license to Indian Pilot license.</b> Guidelines for Conversion of Professional Pilot’s Licenses issued in ICAO contracting States into Indian Professional Pilot’s License.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Commercial Pilot's License.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Applicants must obtain from foreign for submission with application.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Valid foreign Professional Pilot’s License + verification letter from issuing State.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Valid foreign Class-I Medical Assessment from State of issue.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Authenticated Log book of a period of at least preceding 5 years, which must include information regarding dates, aircraft type(single/Multi) and registration, crew status (PIC, Co-pilot etc.), total time, Sectors, Departure-arrival times, day & Nights, X-country flights, X-country tests with no. of landings, Skill tests (Day / night / IR with no. of landings) instrument time(actual, simulated in aircraft), simulator flying (separately logged).
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        All the entries must be verified by the appropriate authority in the State of Issue of foreign license. Tests must be signed by the examiners as well.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Valid Certificates of Skill tests for Single as well as Multi engine(if multi aircraft rating is desired)(Note: The tests shall be on the type of aircraft which are also available and registered in India and the test shall be on Performa as laid down in India duly authenticated by the Instructor/ designated representative of State Regulatory Authority).
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Statement of 20Hrs Cross-country flights / 50 Hrs if IR is also requested. Cross-country check reports, total three(300NM with two full stop landings, 250NM with one full stop landing, 120NM by night while returning to point of departure without landing).
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        5Hrs Night Flying as PIC with certificate of 10numbers of take offs and 10 numbers of landings.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        For IR issue : Instrument time total =40 Hrs (actual on aircraft 20 or more+ simulated 20 or less) and at least 5Hr instrument time on actual aircraft in preceding six months (for IR issue).
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        For Multi endorsement on CPL: Record of ground training and pass result of technical exam, Statement indicating at least 10 hrs of training on multi engine aircraft for which endorsement is requested(including test/ check), and within 6 months General flying test by day with three solo take off and landings each, General flying test Night with three solo take off and landings each and for IR on multi IR test report with two approaches on aircraft for which multi -endorsement on license is requested.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Applicants need to do in India before submission of application.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        General Flying Test report by day, night and Instrument Rating test (if IR desired) from an approved Flying training institute in India, if not carried out in a flying training institute approved by the regulatory authority of the ICAO Contracting State within the preceding six months of the date of application in DGCA.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Signal reception tests report by an approved Flying training institute in India, if not carried out in a flying training institute approved by the regulatory authority of the ICAO Contracting State.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Pass results of Air Regulation and Composite Papers covering (Met+Navigation) in DGCA within preceding 30 months from the date of application.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Indian Class-I Medical assessment
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Indian RTR(A) (if flight radio telephony operator’s license is not issued earlier)
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Obtains Verification certificates from respective school Boards examination for 10th and 12th with original certificates
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Fees Rs. 5000 for each issue and Rs 5000 for each aircraft endorsements/ rating to be submitted in the form of Bank Draft Payable to PAO, DGCA, Ministry of Civil Aviation, New Delhi.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Note: Reference Sr. No. 1 and 2, the test reports from ICAO Contracting States shall be duly authenticated by a representative of the Regulatory Authority of the State where such tests are performed.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DGCALC;
