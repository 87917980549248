import React from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function Cessna() {
    return (
        <div>
            <div className='py-60 max-sm:py-20 pl-20 max-sm:pl-10 text-3xl'>
                <FadeInSectionDownToUp>
                    <h1 className='font-bold tracking-wide text-4xl'>Cessna.</h1>
                    <h2>Cessna Style Pannel & Components</h2>
                </FadeInSectionDownToUp>
            </div>
            <div className='flex max-sm:block justify-center items-center gap-40 xl:gap-40 sm:gap-20 py-5 px-10'>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='text-gray-500 text-2xl text-justify'>
                            The Runway may end, But the thrill <br className='max-sm:hidden' />
                            of Flight in a <b>Simulator</b> never does
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='text-3xl max-sm:text-2xl'>
                    <FadeInSectionDownToUp>
                        <p className='py-5'>
                            USB Plug n Play Modules to
                        </p>
                        <p className='font-bold'>
                            Start building your<br />
                            Home <span className='text-blue-700'>Cockpit</span> today !
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className='py-20'>
                <div className='relative'>
                    <div className='relative'>
                        <img src='https://ik.imagekit.io/mhcockpit1/Cessna%20-%20Image.png' alt='Cessna' />
                    </div>
                    <div className='absolute bottom-1/2 xl:bottom-1/3 sm:bottom-1/3 max-sm:bottom-1/3 left-52 xl:left-52 sm:left-5 max-sm:left-10 max-sm:right-14 sm:right-1/4'>
                        <FadeInSectionDownToUp>
                            <button className='text-start text-white bg-black bg-opacity-50 py-5 px-7 rounded-3xl'>
                                <span className='text-3xl max-sm:text-xl'>Click here for</span><br />
                                <span className='font-bold clear-start text-6xl max-sm:text-3xl'>Quotation</span>
                            </button>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
            </div>
            <div className='px-40 xl:px-40 sm:px-10 max-sm:px-10 text-xl py-5 text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        Select from various options like DIY Kits, 3D printed affordable panels. Realistic 11 Laser cut & Engraved panels, Ready to Go USB plug & play type components to match your requirements.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='px-40 xl:px-40 sm:px-10 max-sm:px-10 text-xl py-5 text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        All products are compatible with A2A Cessna C172, FSX, P3D, Xplane and FS2020.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            {/* <div className='flex justify-center items-center gap-2 px-40 xl:px-40 sm:px-10 max-sm:px-10 py-5'>
                <div className='bg-black text-white p-8 w-full h-full rounded-3xl'>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-bold text-xl'>
                                C172 G1000 Style IFR Training Panel Product Details
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='max-sm:px-10 text-xl py-5 text-justify'>
                        <FadeInSectionDownToUp>
                            <p>
                                The panel has all functionalities of that for a C172 Glass Cockpit or equivalent Aircraft simulator. All buttons and switches are fully working. The module is USB Plug n Play, it is compatible with Xplane, FS2020, FSX, P3D, DCS World and many other flight simulators. Frame is made of laser cut steel and painted with anticorrosive industrial paint. The panel is non backlit type.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-semibold text-xl'>
                                Main Features are
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Realistic dual encoders.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Panels in two plates for future backlighting.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Box type enclosure for Table Top installation.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        All indicators, switches and other elements are approx 1:1.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        100% tested with FSX, P3D, Xplane.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Rotary switches have PUSH functionality as well.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        USB plug n play.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Knobs are 3D printed in high quality ABS/ PETG.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Approx Measurements (in cm) are: depends on screen size selected.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Approximate weight of the packaged product: 2Kg onwards.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                </div>
                <div className='bg-black text-white p-8 w-full h-full rounded-3xl'>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-bold text-xl'>
                                Cessna/Baron/Piper Conventional Gauges IFR Training Panel Product Details
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='text-xl py-5 text-justify'>
                        <FadeInSectionDownToUp>
                            <p>
                                The panel has all functionalities of that for a conventional C172 Cockpit or equivalent aircraft simulator. All buttons, switches & dials are fully working. The module is USB Plug n Play, it is compatible with Xplane, FS2020, FSX, P3D, DCS World and many other flight simulators. Frame is made of laser cut steel and painted with anticorrosive industrial paint. The panel is non backlit type.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-semibold text-xl'>
                                Main Features of Panel are
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Realistic size switches.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Panels in two plates for future backlighting.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Steel frame with mounting holes.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        All indicators, switches and other elements are 1:1.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Set of Control cards to manage the entire module.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        All buttons & switches are functional.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        USB plug n play.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Buttons & Knobs are approx. 1:1.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Approx. Measurements (in cm) are: 100 x 45 x 15.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Approximate weight of the packaged product: 20Kg.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                </div>
            </div> */}
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-6 px-40 xl:px-40 sm:px-10 max-sm:px-5 py-5'>
                <div className='bg-black text-white p-8 w-full h-full rounded-3xl'>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-bold text-xl'>
                                C172 G1000 Style IFR Training Panel Product Details
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='text-xl py-5 text-justify'>
                        <FadeInSectionDownToUp>
                            <p>
                                The panel has all functionalities of that for a C172 Glass Cockpit or equivalent Aircraft simulator. All buttons and switches are fully working. The module is USB Plug n Play, it is compatible with Xplane, FS2020, FSX, P3D, DCS World and many other flight simulators. Frame is made of laser cut steel and painted with anticorrosive industrial paint. The panel is non backlit type.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-semibold text-xl'>
                                Main Features are
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Realistic dual encoders.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Panels in two plates for future backlighting.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Box type enclosure for Table Top installation.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    All indicators, switches and other elements are approx 1:1.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    100% tested with FSX, P3D, Xplane.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Rotary switches have PUSH functionality as well.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    USB plug n play.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Knobs are 3D printed in high quality ABS/ PETG.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Approx Measurements (in cm) are: depends on screen size selected.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Approximate weight of the packaged product: 2Kg onwards.
                                </li>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                </div>
                <div className='bg-black text-white p-8 w-full h-full rounded-3xl'>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-bold text-xl'>
                                Cessna/Baron/Piper Conventional Gauges IFR Training Panel Product Details
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='text-xl py-5 text-justify'>
                        <FadeInSectionDownToUp>
                            <p>
                                The panel has all functionalities of that for a conventional C172 Cockpit or equivalent aircraft simulator. All buttons, switches & dials are fully working. The module is USB Plug n Play, it is compatible with Xplane, FS2020, FSX, P3D, DCS World and many other flight simulators. Frame is made of laser cut steel and painted with anticorrosive industrial paint. The panel is non backlit type.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-semibold text-xl'>
                                Main Features of Panel are
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Realistic size switches.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Panels in two plates for future backlighting.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Steel frame with mounting holes.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    All indicators, switches and other elements are 1:1.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Set of Control cards to manage the entire module.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    All buttons & switches are functional.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    USB plug n play.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Buttons & Knobs are approx. 1:1.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Approx. Measurements (in cm) are: 100 x 45 x 15.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Approximate weight of the packaged product: 20Kg.
                                </li>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <div className='flex justify-center items-start gap-2 px-40 xl:px-40 sm:px-10 max-sm:px-10 py-5'>
                <div className='text-black p-8 w-full h-full rounded-3xl shadow-2xl'>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-bold text-xl'>
                                C172 Conventional Style Full Lower Panel Product Details
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='max-sm:px-10 text-xl py-5 text-justify'>
                        <FadeInSectionDownToUp>
                            <p>
                                The panel has all functionalities of that for a C172 or equivalent aircraft. All buttons and switches are fully working. The module is USB Plug n Play, it is compatible with Xplane, FS2020, FSX, P3D, DCS World and many other flight simulators. The panel can be provided with mounting holes or desktop placement style enclosure so that you can use it either in your home cockpit frame or simply put on your desktop close to your laptop or gaming PC.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-semibold text-xl'>
                                Main Features of Panel are
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Realistic C172 style panel.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Panels in two plates for future backlighting.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Steel frame with mounting holes.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        All indicators, switches and other elements are 1:1.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Set of Control cards to manage the entire module.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Compatible with IFS India Yoke (Sold separately).
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        USB plug n play.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Laser cut and CNC Engraved similar to a real aircraft panel.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Approx. Measurements (in cm) are: 100 x 10 x 15.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Approximate weight of the packaged product: 10Kg.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                </div>
                <div className='text-black p-8 w-full h-full rounded-3xl shadow-2xl'>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-bold text-xl'>
                                Flight Simulator Yoke Product Details
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='text-xl py-5 text-justify'>
                        <FadeInSectionDownToUp>
                            <p>
                                The Yoke is made of ABS plastic handles with Aluminum internal parts and Stainless Steel Shaft to give a very realistic look and feel while using in a flight simulator. All the buttons are re-programmable. There will be 3 to 6 Push Buttons for Trim, AP ARM, Radio etc, programmable controls. The Yoke is compatible with FS2020, Xplane, P3D, FSX and many other flight simulators and games.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-semibold text-xl'>
                                Main Features of Panels are
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Realistic stroke of approx. 150mm.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Realistic rotation of approx. 180 degree.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Yoke is USB plug n play.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Stainless Steel main shaft for sturdy operation.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Product is non FFB.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Also available as DIY low cost option.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Compatible with IFS India Lower Pedestal Panel for C172.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Measurements depends on type of product selected.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Approximate weight: 3kg.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                </div>
            </div> */}
            <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4 px-40 xl:px-40 sm:px-10 max-sm:px-5 py-5'>
                <div className='text-black p-8 w-full h-full rounded-3xl shadow-2xl'>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-bold text-xl'>
                                C172 Conventional Style Full Lower Panel Product Details
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='text-xl py-5 text-justify'>
                        <FadeInSectionDownToUp>
                            <p>
                                The panel has all functionalities of that for a C172 or equivalent aircraft. All buttons and switches are fully working. The module is USB Plug n Play, it is compatible with Xplane, FS2020, FSX, P3D, DCS World and many other flight simulators. The panel can be provided with mounting holes or desktop placement style enclosure so that you can use it either in your home cockpit frame or simply put on your desktop close to your laptop or gaming PC.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-semibold text-xl'>
                                Main Features of Panel are
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Realistic C172 style panel.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Panels in two plates for future backlighting.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Steel frame with mounting holes.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    All indicators, switches and other elements are 1:1.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Set of Control cards to manage the entire module.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Compatible with IFS India Yoke (Sold separately).
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    USB plug n play.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Laser cut and CNC Engraved similar to a real aircraft panel.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Approx. Measurements (in cm) are: 100 x 10 x 15.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Approximate weight of the packaged product: 10Kg.
                                </li>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                </div>
                <div className='text-black p-8 w-full h-full rounded-3xl shadow-2xl'>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-bold text-xl'>
                                Flight Simulator Yoke Product Details
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='text-xl py-5 text-justify'>
                        <FadeInSectionDownToUp>
                            <p>
                                The Yoke is made of ABS plastic handles with Aluminum internal parts and Stainless Steel Shaft to give a very realistic look and feel while using in a flight simulator. All the buttons are re-programmable. There will be 3 to 6 Push Buttons for Trim, AP ARM, Radio etc, programmable controls. The Yoke is compatible with FS2020, Xplane, P3D, FSX and many other flight simulators and games.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-semibold text-xl'>
                                Main Features of Panels are
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Realistic stroke of approx. 150mm.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Realistic rotation of approx. 180 degree.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Yoke is USB plug n play.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Stainless Steel main shaft for sturdy operation.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Product is non FFB.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Also available as DIY low cost option.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Compatible with IFS India Lower Pedestal Panel for C172.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Measurements depends on type of product selected.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Approximate weight: 3kg.
                                </li>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='flex justify-center items-start gap-2 px-40 xl:px-40 sm:px-10 max-sm:px-5 py-5'>
                <div className='bg-black text-white p-8 w-full h-full rounded-3xl shadow-2xl'>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-bold text-xl'>
                                C172 Style Throttle-Mixture-Propeller Unit Product Details
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='text-xl py-5 text-justify'>
                        <FadeInSectionDownToUp>
                            <p>
                                TPM Unit is made of ABS plastic handles with Stainless steel Shaft to give a very realistic look and feel while using in a flight simulator. Levers are re-programmable, can be assigned to anything like Throttle-1/2/3; Throttle/Prop/Mixture; Throttle/Flap /Mixture etc, combinations. The levers are push pull type and do not have rotation function. Product is compatible with FS2020, Xplane, P3D, FSX, DCS World and many other flight simulators and games.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-semibold text-xl'>
                                Main Features of Panels are
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Realistic stroke of approx. 100mm.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Non-rotation shaft.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Mounting holes for permanent mounting on table top.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Stainless Steel main shaft for sturdy operation.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Product is non FFB.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Also available as DIY low cost option.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Compatible with all other own built Panels for C172.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Approximate weight: 1.5kg.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
