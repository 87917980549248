import React from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function Blog23() {
    return (
        <div>
            <div className='xl:pt-0 sm:pt-12'>
                <FadeInSectionDownToUp>
                    <h1 className='text-center font-semibold text-3xl xl:text-3xl sm:text-xl max-sm:text-lg py-5'>The Different Types of Aviation Degrees: What are the different specializations available? Which degree is right for you?</h1>
                </FadeInSectionDownToUp>
                <hr />
            </div>
            <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 text-justify'>
                <div className='py-5'>
                    <img src='https://ik.imagekit.io/mhcockpit1/blogs/Different%20types%20of%20Aviation%20Degreee.webp' alt='The Different Types of Aviation Degrees: What are the different specializations available? Which degree is right for you?' />
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            The world of aviation is vast and exciting, offering a multitude of career paths. But before you take flight, choosing the right degree is crucial. Here's a breakdown of different specializations and how to find the perfect fit.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Major Branches of Aviation Degrees:
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            1. Flight Operations
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className="text-lg mb-5 text-justify">
                    <ul className='space-y-3'>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Professional Pilot</b> Earn your wings and navigate the skies as a commercial pilot, airline pilot, or corporate pilot. This path requires specific flight training programs alongside a degree in aeronautical science, aviation management, or professional pilot technology.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Unmanned Aircraft Systems (UAS)</b> Operate drones for various applications like aerial photography, surveying, and inspections. A degree in aviation, aerospace engineering, or computer science can be beneficial.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                    </ul>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            2. Aviation Management
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className="text-lg mb-5 text-justify">
                    <ul className='space-y-3'>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Aviation Business Administration</b> Gain expertise in the business side of aviation, including airport management, airline operations, and logistics. A degree in aviation management or business administration with an aviation focus is ideal.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Air Traffic Control</b> Manage air traffic flow safely and efficiently. Specialized air traffic control programs or degrees in aviation technology or management are required.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                    </ul>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            3. Aviation Maintenance
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className="text-lg mb-5 text-justify">
                    <ul className='space-y-3'>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Aircraft Maintenance Technician</b> Keep aircraft airworthy through inspections, repairs, and troubleshooting. Earn your Federal Aviation Administration (FAA) A&P certification alongside an associate degree in aviation maintenance or a bachelor's in aviation maintenance science.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                    </ul>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            4. Aviation Engineering
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className="text-lg mb-5 text-justify">
                    <ul className='space-y-3'>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Aeronautical Engineering</b> Design, develop, and test aircraft and aerospace systems. A bachelor's degree in aeronautical or aerospace engineering is essential.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                    </ul>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Choosing Your Path:
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            <b>Interests:</b> What excites you most? Piloting through clouds, managing airports, or designing cutting-edge aircraft? Align your degree with your passion.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            <b>Skills:</b> Consider your strengths. Do you excel in technical tasks, leadership, or problem- solving? Choose a program that hones your existing skills.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            <b>Career Goals:</b> Research specific jobs and their required qualifications. Align your degree with your dream career path.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Additional Factors
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className="text-lg mb-5 text-justify">
                    <ul className='space-y-3'>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Program Accreditation:</b> Ensure your chosen program holds industry-recognized accreditation for better career prospects.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Flight Training Costs:</b> Pilot training programs can be expensive. Factor in these costs when choosing your path.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Internships and Networking:</b> Utilize internships and networking opportunities to gain valuable experience and connections.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                    </ul>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            <b>Remember:</b> This is just a starting point. Explore different programs, talk to professionals, and research thoroughly to make an informed decision. Take to the skies with the perfect aviation degree and soar towards your dream career!
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
        </div>
    )
}
