import React from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function Campus() {

    const PlacedStudents = Array.from({ length: 69 }, (_, i) => ({
        src: `https://ik.imagekit.io/mhcockpit1/Students/Placed%20Students/${i + 1}.png`,
    }));

    // const DGCAExamsClearedStudents = Array.from({ length: 15 }, (_, i) => ({
    //     src: `https://ik.imagekit.io/mhcockpit1/DGCA/DGCA%20${i + 1}.jpg`,
    // }));

    return (
        <div>
            <div className='text-center'>
                <FadeInSectionDownToUp>
                    <h1 className='font-semibold text-5xl max-sm:text-3xl tracking-tighter py-20 max-sm:py-10'>Campus Placed Students</h1>
                </FadeInSectionDownToUp>
            </div>
            <div className='grid grid-cols-6 max-sm:grid-cols-3 gap-10 max-sm:gap-3 px-20 max-sm:px-3 py-10'>
                {PlacedStudents.map((image, index) => (
                    <div key={index} className='rounded-xl sha'>
                        <FadeInSectionDownToUp>
                            <img src={image.src} alt='Placed Students' />
                        </FadeInSectionDownToUp>
                    </div>
                ))}
            </div>
            {/* <div className='text-center'>
                <FadeInSectionDownToUp>
                    <h1 className='font-semibold text-5xl max-sm:text-3xl tracking-tighter py-20 max-sm:py-10'>DGCA Exams Cleared Students</h1>
                </FadeInSectionDownToUp>
            </div>
            <div className='grid grid-cols-4 max-sm:grid-cols-2 gap-10 max-sm:gap-3 px-20 max-sm:px-3 py-10'>
                {DGCAExamsClearedStudents.map((image, index) => (
                    <div key={index}>
                        <FadeInSectionDownToUp>
                            <img src={image.src} alt='Placed Students' />
                        </FadeInSectionDownToUp>
                    </div>
                ))}
            </div> */}
        </div>
    )
}
