import React from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function Blog16() {
    return (
        <div>
            <div className='xl:pt-0 sm:pt-12'>
                <FadeInSectionDownToUp>
                    <h1 className='text-center font-semibold text-3xl xl:text-3xl sm:text-xl max-sm:text-lg py-5'>Zooming High: Celebrating Women in Aviation</h1>
                </FadeInSectionDownToUp>
                <hr />
            </div>
            <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 text-justify'>
                <div className='py-5'>
                    <img src='https://ik.imagekit.io/mhcockpit1/blogs/Womens%20in%20Aviation.webp' alt='Zooming High: Celebrating Women in Aviation' />
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            In a world where the sky is the limit, women have broken through barriers, shattered stereotypes, and taken flight in the male-dominated field of aviation. The skies are no longer just a man's domain; women have not only earned their wings but have soared to new heights, leaving an indelible mark on the aviation industry. This blog post is a tribute to the remarkable women who have paved the way and continue to inspire generations of aspiring aviators.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            History of Women in Aviation
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            The journey of women in aviation is a fascinating tale that dates back to the early 20th century. From the fearless Amelia Earhart, the first woman to fly solo across the Atlantic, to the courageous Women Airforce Service Pilots (WASP) who served during World War II, women have played pivotal roles in shaping the aviation landscape. Despite facing adversity, these trailblazers laid the foundation for future generations of female pilots.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Breaking the Glass Cockpit
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            While the aviation industry has come a long way, challenges persist for women aiming to reach the cockpit. The gender gap in aviation remains noticeable, with women comprising only a small percentage of pilots worldwide. However, organizations and initiatives dedicated to promoting gender diversity, such as Women in Aviation International, are working tirelessly to bridge this gap and create equal opportunities for women in all aspects of aviation.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Inspiring Stories of Women Aviators
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            Highlighting the stories of contemporary women aviators adds a personal touch to the narrative. From commercial airline pilots to aerobatic performers, these women share their triumphs, struggles, and the sheer joy of navigating the skies. Interviews with these pioneers provide valuable insights into the unique experiences and perspectives of women in aviation.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Women in Aviation Today
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            Despite the challenges, women continue to break barriers and contribute significantly to the aviation industry. Female astronauts, air traffic controllers, engineers, and aviation executives are making their mark. The blog post will delve into the various roles women play in the modern aviation landscape, showcasing their expertise, leadership, and innovation.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Encouraging the Next Generation
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            Empowering the next generation of female aviators is crucial for the continued growth of women in aviation. The blog post will explore mentorship programs, scholarships, and educational initiatives aimed at encouraging young girls to pursue their dreams of flying. Inspiring stories of young women overcoming obstacles and achieving success in aviation will serve as a beacon for those aspiring to join the ranks.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Conclusion
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            The sky is not the limit for women in aviation; it's a canvas waiting to be painted with the diverse talents, skills, and dreams of female aviators. As we celebrate the progress made, it is essential to recognize the work still ahead in ensuring equal opportunities for women in all facets of the aviation industry. The legacy of women in aviation is one of resilience, determination, and the unwavering belief that, when given the chance, women can soar to incredible heights.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
        </div>
    )
}
