import React from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function Agriculture() {
    return (
        <div>
            <div className='relative'>
                <div className='relative'>
                    <video autoPlay muted loop className="w-full h-full object-cover">
                        <source src="https://mh-cockpit.blr1.cdn.digitaloceanspaces.com/MH%20AGRICULTURE.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className='absolute bottom-10 xl:bottom-10 sm:bottom-0 max-sm:bottom-1 left-20 xl:left-20 sm:left-5 max-sm:left-10 max-sm:right-14 sm:right-1/4'>
                    <FadeInSectionDownToUp>
                        <h1 className='text-4xl xl:text-4xl lg:text-6xl sm:text-3xl max-sm:text-xl font-bold py-10 xl:py-10 max-sm:py-2 text-white'>
                            MH Agriculture.
                        </h1>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className='px-20 max-sm:px-10 text-xl py-10 text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        <b>Agricultural Drones</b> are unmanned aerial vehicles equipped with sensors and cameras to monitor and collect data on crops. They assist farmers in precision Agriculture by providing real-time information on crop health, moisture levels, and pest infestations. These drones enable efficient crop management, reduce resource usage, and enhance overall farm productivity. By utilizing advanced imaging technology, they help Farmers make data-driven decisions for optimal yield and sustainability. Agricultural drones contribute to modernizing farming practices through technology-driven insights and improved operational efficiency.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='flex xl:flex sm:block max-sm:block justify-center items-center gap-20 px-20 xl:px-20 sm:px-20 max-sm:px-10 py-3'>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='text-4xl xl:text-start sm:text-center max-sm:text-center xl:py-0 sm:py-3 max-sm:py-3'>
                            Precision<br />
                            <span className='text-[#6b8d36]'>Agriculture.</span>
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='bg-[#6b8d36] p-7 rounded-3xl w-1/3 xl:w-1/3 sm:w-full max-sm:w-full text-white text-lg text-justify'>
                    <FadeInSectionDownToUp>
                        <p>
                            Drones equipped with Sensors and Cameras can collect High-Resolution data about Crops, soil conditions, and field health. This data helps farmers to precisely monitor crop health, detect diseases, assess irrigation needs, and optimize resource usage like water and fertilizer.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className='flex xl:flex-row sm:flex-col-reverse max-sm:flex-col-reverse justify-center items-center gap-20 xl:gap-20 sm:gap-0 max-sm:gap-0 px-20 xl:px-20 sm:px-20 max-sm:px-10 py-3'>
                <div className='bg-[#4a67ef] p-7 rounded-3xl w-1/3 xl:w-1/3 sm:w-full max-sm:w-full text-white text-lg text-justify'>
                    <FadeInSectionDownToUp>
                        <p>
                            Drones can cover large areas of farmland quickly and Efficiently, Reducing the Time and Labor required for manual inspection. This can lead to cost savings and more effective use of resources.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='text-4xl xl:text-start sm:text-center max-sm:text-center xl:py-0 sm:py-3 max-sm:py-3'>
                            Efficiency<br />
                            <span className='text-[#4a67ef]'>& Cost Reduction.</span>
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className='flex xl:flex sm:block max-sm:block justify-center items-center gap-20 px-20 xl:px-20 sm:px-20 max-sm:px-10 py-3'>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='text-4xl xl:text-start sm:text-center max-sm:text-center xl:py-0 sm:py-3 max-sm:py-3'>
                            Crop <br className='xl:block sm:hidden max-sm:hidden' />
                            Spraying<br />
                            <span className='text-[#06a263]'>& Monitoring.</span>
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='bg-[#06a263] p-7 rounded-3xl w-1/3 xl:w-1/3 sm:w-full max-sm:w-full text-white text-lg text-justify'>
                    <FadeInSectionDownToUp>
                        <p>
                            Drones are equipped with Spraying Systems can precisely apply Pesticides, Fertilizers, or Herbicides to specific Areas of the Field, Reducing Chemical usage and Minimizing Environmental Impact. They can also monitor the effectiveness of these applications.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className='flex xl:flex-row sm:flex-col-reverse max-sm:flex-col-reverse justify-center items-center gap-20 xl:gap-20 sm:gap-0 max-sm:gap-0 px-20 xl:px-20 sm:px-20 max-sm:px-10 py-3'>
                <div className='bg-[#40bbb8] p-7 rounded-3xl w-1/3 xl:w-1/3 sm:w-full max-sm:w-full text-white text-lg text-justify'>
                    <FadeInSectionDownToUp>
                        <p>
                            In areas that are difficult to access by traditional methods or large machinery, drones can reach remote or challenging terrain, providing farmers with insights into areas that might otherwise be overlooked.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='text-4xl xl:text-start sm:text-center max-sm:text-center xl:py-0 sm:py-3 max-sm:py-3'>
                            Access to <br className='xl:block sm:hidden max-sm:hidden' />
                            Remote or<br />
                            <span className='text-[#40bbb8]'>
                                Challenging <br className='xl:block sm:hidden max-sm:hidden' />
                                Terrain.
                            </span>
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className='flex xl:flex sm:block max-sm:block justify-center items-center gap-20 px-20 xl:px-20 sm:px-20 max-sm:px-10 py-3'>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='text-4xl xl:text-start sm:text-center max-sm:text-center xl:py-0 sm:py-3 max-sm:py-3'>
                            Data-Driven<br />
                            <span className='text-[#e8715d]'>Decision Making.</span>
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='bg-[#e8715d] p-7 rounded-3xl w-1/3 xl:w-1/3 sm:w-full max-sm:w-full text-white text-lg text-justify'>
                    <FadeInSectionDownToUp>
                        <p>
                            The data collected by agricultural drones helps Farmers make informed decisions about Crop management strategies, leading to better yields and improved agricultural practices.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className='px-20 max-sm:px-10 text-xl py-10 text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        Overall, Agricultural Drones offer a Technological edge to Farming Operations, Enabling Farmers to gather data, make informed decisions, and optimize their processes for better crop yields and Sustainability.
                    </p>
                </FadeInSectionDownToUp>
            </div>
        </div>
    )
}
