export const OurGovernanceData = [

    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/CEO.jpg",
        name: "Ahamed Subair",
        role: "Founder Chairman",
        linkedin: "https://www.linkedin.com/in/ahamed-subair-142255aa/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/yasir%20sir.jpg",
        name: "Ahamed Yasir",
        role: "CEO"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/Haja.jpg",
        name: "Haja",
        role: "Director Advisory Board"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/Alhaf%20Malik%20New.webp",
        name: "Alhaf Malik",
        role: "CTO"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/Jehath.jpg",
        name: "Jehad Al Shehri",
        role: "Director - GCC operations"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/Burhan%20Mohamed.jpg",
        name: "Burhan Mohamed",
        role: "COO - GCC operations"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/Yacqub%20Lye.webp",
        name: "Yacqub Lye",
        role: "Director Sales And Marketing - UAE"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/KIRIAKOS%20KIRANOS.jpg",
        name: "Capt. Kiriakos Kiranos",
        role: "Director European Operations"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/Sonam%20Mishra.jpg",
        name: "Sonam Mishra",
        role: "Head of operations (India)"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/VINOTH.jpg",
        name: "Vinoth",
        role: "Head Program Delivery"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/Aadhil.jpg",
        name: "Adhil Sharief",
        role: "Campus Manager (Vels Operation)"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/Umakarthika.jpg",
        name: "Umakarthika",
        role: "Assistant Campus Manager (Vels Operation)"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/Prasanth.jpg",
        name: "Prasanth",
        role: "Sales & Marketing Head"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/Harivarathan.jpg",
        name: "Harivaradhan",
        role: "Senior Business Development Manager"
    },
    // {
    //     src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/Safi.jpg",
    //     name: "Sabiullah",
    //     role: "Finance"
    // },

]

export const BoardMembers = [

    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/Wasim%20Raja.jpg",
        name: "Shiekh Mohamed Waseem Raja"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/Musheer%20Ahmed%20Hyderi.jpg",
        name: "Lt .col- Musheer Ahmed Hyderi"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/Capt.%20Adil%20.%20A%20Baria.jpg",
        name: "Capt. Adil . A Baria"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/Farah%C2%A0Khan%C2%A0Pethe.jpg",
        name: "Capt. Farah Khan Pethe"
    },

]