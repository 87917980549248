import React from 'react';
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp';

function DGCAMR() {

    return (
        <div>
            <div className="relative text-justify">
                <div className="inset-0 overflow-hidden">
                    <img
                        className='w-full h-full object-cover animate-zoomOut'
                        src="https://ik.imagekit.io/mhcockpit1/DGCA-LC.webp"
                        alt="DGCA - License conversion"
                    />
                </div>
                <div className="relative py-10">
                    <div className='pl-40 max-sm:pl-10 text-4xl max-sm:text-2xl font-semibold tracking-tighter py-10'>
                        <FadeInSectionDownToUp>
                            <h1>DGCA - Medical Requirments.</h1>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='pl-40 max-sm:pl-10 text-3xl max-sm:text-xl py-5'>
                        <FadeInSectionDownToUp>
                            <h1>Class II Medical Examinations.</h1>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Class II medical is the the first step towards a pilot’s career. It’s required for you to get an SPL (Student Pilot License) and to join a flying school.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                STEP 1 :
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-48 max-sm:px-14 text-xl'>
                        <FadeInSectionDownToUp>
                            <p>
                                Go through the list of DGCA examiners. From the list, select the doctor who suits you best, contact and fix an appointment with them. DO NOT get your medicals done by any other doctor, or it’ll be deemed invalid.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                STEP 2 :
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-48 max-sm:px-14 text-xl'>
                        <FadeInSectionDownToUp>
                            <p>
                                Fill out relevant forms and get all the tests done, as instructed by your doctor. This includes blood, urine, BMI (body mass index), ECG, Blood pressure, chest x-ray, and ENT tests.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                STEP 3 :
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-48 max-sm:px-14 text-xl'>
                        <FadeInSectionDownToUp>
                            <p>
                                The doctor will approve all your reports will send them to DGCA, Delhi for approval.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                STEP 4 :
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-48 max-sm:px-14 text-xl'>
                        <FadeInSectionDownToUp>
                            <p>
                                Once your medical documents reach DGCA, a file will be prepared on your respective name and you will receive a file number. Collect your Medical Assessment from the DGCA Medical Cell, Delhi.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='pl-40 max-sm:pl-10 text-3xl max-sm:text-xl py-5'>
                        <FadeInSectionDownToUp>
                            <h1>Class I Medical Examinations.</h1>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                A class I medical certificate is required for commercial pilots. The holder of this medical certificate shall be mentally and physically fit to exercise safely the privileges of the applicable licence.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                STEP 1 :
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-48 max-sm:px-14 text-xl'>
                        <FadeInSectionDownToUp>
                            <p>
                                Check whether the intended medical is an initial or a renewal medical.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Initial Medical. Initial medical is done when a person with a valid Class 2 medical applies for the first time for a Class 1 Medical (a requirement for commercial license). Also, in case the last medical date (Class 1) is more than two years old, a fresh initial (Re-initial) Class 1 medical is to be conducted only at Air Force Medical Boarding centres, along with approved NOC by Medical Cell, DGCA.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Renewal Medical. Class 1 Medical Assessments are valid for one year for persons less than 60 yrs. of age in Multi crew commercial Air Transport Operations or six months for persons above 40 yrs of age in Single crew commercial air transport operations. The renewal medical may be done anytime from one month before date of expiry of validity of Medical Assessment to the date of expiry of validity. If done earlier or later than that, an NOC is required. Renewal Class 1 medical may be done after expiry of validity of previous (but not beyond two years since date of last medical) along with NOC. The NOC form available in Procedure & training Manual, available on DGCA websites Annexure ‘D’ to be filled completely. Applications for NOC are to be made after obtaining confirmed appointment and to be sent along with Last Valid Medical Assessment, issued by DGCA. Renewal medical exam is conducted in IAF / AF Centres and by DGCA empanelled Class 1 Examiners.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Renewals mandatory at IAM/ AFCME/ MEC(E)/ 11 Air Force Hospital (11 AFH) / Command Hospital Air Force Bangalore (CHAF’B’). The following medicals will compulsorily be done only at IAM/AFCME/ MEC(E) /11 AFH/
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                CHAF 'B'
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        All medicals after 60 years of age and
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Medicals after attaining age 25, 30, 35, 40, 45, 50, 55, 60 years and every medical after 60 years of age.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                STEP 2 :
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-48 max-sm:px-14 text-xl'>
                        <FadeInSectionDownToUp>
                            <p>
                                Appointment & Previous Medical Record (PMR) Forwarding.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        IAF Centres. After ascertaining type of medical, kindly seek appointment well in advance keeping in view the time required for grant of appointments and PMR forwarding from DGCA. The procedure for seeking appointment can be accesses in the DGCA website under the Medical Section.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Civil Centres & Class 1 Examiners. For Civil Hospitals (initial Medical) and Class 1 Examiners (renewal medicals), kindly contact the centres/ examiners directly at the addresses given below. PMR forwarding is not required for Class 1 initial medicals which are to be conducted at Nanavati Hospital, Apollo Hospital, and for renewal medical examinations conducted by Class 1 Medical Examiners & for Air Force Medical Examination Centres (except IAM/AFCME/MEC(E) /11 AFH/ CHAF’B’).
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                STEP 3 :
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-48 max-sm:px-14 text-xl py-2'>
                        <FadeInSectionDownToUp>
                            <p>
                                PMR Status Query.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-48 max-sm:px-14 text-xl py-2'>
                        <FadeInSectionDownToUp>
                            <p>
                                PMR files are forwarded to IAF Centres 2-3 weeks prior to date of appointment (even if applications are received early).For any queries regarding PMR file no., its status and its assessment, any correction or updating any address/details/name – it is kindly requested to contact on below mentioned contact no. of SO Medical Cell (DGCA HQ) 01124622500 Extn 453 OR You can visit or can authorize any of your rep. to collect your Medical Assessment on Public Days from Med Date at DGCA i.e. Wednesday & Thursday between 1400h to 1730h. Further, for any queries regarding taking of appointment and status of your medical appointment, it is kindly requested to contact on below mentioned contact no. or e-mail on dgcamedicalappointment@gmail.com 01124622500 Extn 526 / 453 and after confirmation of the same you can apply for NOC.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                STEP 4 :
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-48 max-sm:px-14 text-xl py-2'>
                        <FadeInSectionDownToUp>
                            <p>
                                No Objection Certificate (NOC).
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-48 max-sm:px-14 text-xl py-2'>
                        <FadeInSectionDownToUp>
                            <p>
                                For Re-initial /delayed/ early/ Post TU or Special Medicals only, kindly obtain a No Objection Certificate (NOC) from Medical Date at DGCA, New Delhi. The NOC form available in Procedure & training Manual, available on DGCA websites Annexure ‘D’ to be filled completely. Applications for NOC are to be made after obtaining confirmed appointment and to be sent along with Last Valid Medical Assessment, issued by DGCA.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-48 max-sm:px-14 text-xl py-2'>
                        <FadeInSectionDownToUp>
                            <p>
                                Class 1 Initial Medical Examination are conducted at Seven (07) Centres amongst which Five (05) are of the Indian Air Force and two (02) are at civil hospitals. The details of which are appended below
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='flex justify-center items-center px-40 max-sm:px-0'>
                        <div className='grid grid-cols-2 max-sm:block max-sm:px-10 gap-52'>
                            <div className='py-5'>
                                <div>
                                    <FadeInSectionDownToUp>
                                        <p className='text-2xl xl:text-2xl sm:text-sm max-sm:text-lg font-semibold'>INITIAL MEDICAL EXAMINATION</p>
                                    </FadeInSectionDownToUp>
                                </div>
                                <div>
                                    <FadeInSectionDownToUp>
                                        <img src='https://ik.imagekit.io/mhcockpit1/dgcamr-img-1.png' alt='DGCA - Medical Requirments.' />
                                    </FadeInSectionDownToUp>
                                </div>
                            </div>
                            <div className='py-5'>
                                <div>
                                    <FadeInSectionDownToUp>
                                        <p className='text-2xl xl:text-2xl sm:text-sm max-sm:text-lg font-semibold'>RENEWAL MEDICAL EXAMINATION</p>
                                    </FadeInSectionDownToUp>
                                </div>
                                <div>
                                    <FadeInSectionDownToUp>
                                        <img src='https://ik.imagekit.io/mhcockpit1/dgcamr-img-2.png' alt='DGCA - Medical Requirments.' />
                                    </FadeInSectionDownToUp>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DGCAMR;
