import React from 'react';
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';

function GroundStaff() {

    return (
        <div>
            <div className="relative text-justify">
                <div className="inset-0 overflow-hidden">
                    <img
                        className='w-full h-full object-cover animate-zoomOut'
                        src="https://ik.imagekit.io/mhcockpit1/Airport%20Ground%20Operations%20Personnel%20-%20Image.webp"
                        alt="DGCA - License conversion"
                    />
                </div>
                <div className="relative py-10">
                    <div className='pl-40 xl:pl-40 sm:pl-20 max-sm:pl-10 text-4xl max-sm:text-2xl font-semibold tracking-tighter py-10'>
                        <FadeInSectionDownToUp>
                            <h1>Ground Operations Trainer.</h1>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-xl'>
                        <FadeInSectionDownToUp>
                            <p>
                                An Airport Ground Operations College Trainer educates aspiring aviation professionals in airport procedures, safety guidelines, and industry standards. They design curriculum modules covering a spectrum of subjects, from aircraft handling to passenger logistics, ensuring a comprehensive understanding of ground operations. Utilizing simulations and practical training, they prepare students for real-world scenarios, emphasizing safety protocols and efficient procedures. These trainers continually update coursework to align with industry advancements and regulations, shaping competent and knowledgeable future professionals in airport ground operations.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-xl'>
                        <FadeInSectionDownToUp>
                            <p>
                                The purpose of this role is to develop and deliver Ground Operations Learning and Development interventions for all areas of the company as determined by the Training Manager . To act as a role model for ground operations excellence in terms of actions and behavior.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Main responsibilities.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Motivated and encouraged to exceed expectations by the training delivered.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Fully skilled on the use of all systems and equipment.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Fully aware of compliance policies and procedures for Airline and the regulatory procedures.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Properly briefed on all changes to product and procedures.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Coach students at the universities/ academy.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Support Training Manager in dealing with Training efficiency and Quality observations. Also, in areas of safety risk assessments.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Work with the Training Manager to continually review training materials, ensuring that all changes in policy, operational requirements and legislation are incorporated in the training to ensure compliance at all times.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Ensure that all training is conducted to the highest levels so that security, safety, and service standards are understood by all trainees.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Provide accurate and comprehensive reporting as required.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Job Requirements.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        University Qualifications: Bachelor’s Degree or equivalent.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Nature and length of previous experience: Minimum three years of experience in ground handling industry in a supervisory level OR Minimum of one year experience in training in an aviation / ground operations environment.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Specialist knowledge: Expert knowledge in the field of Passenger handling, ramp and load control, etc.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Expert level Knowledge of load control/ Load & trim is compulsory.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Soft Skills and Personality traits: Excellent analytical ability and organizational skills.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Experience in Auditing will be an advantage.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Excellent presentation skills with strong written and verbal skills in English plus one additional language.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Excellent motivational skills and has the ability to influence staff performance and increase productivity.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Comfortable working to tight deadlines and able to work on shift basis as and when required to delivery training in the workplace.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-2 py-5'>
                        <div className='py-5 px-5 max-sm:px-2 text-2xl max-sm:text-lg font-bold text-center bg-black text-white rounded-3xl'>
                            <div className='flex justify-center items-center'>
                                <div className='grid grid-cols-2'>
                                    <div className='text-start'>
                                        <FadeInSectionDownToUp>
                                            <p>
                                                Reporting To
                                            </p>
                                        </FadeInSectionDownToUp>
                                    </div>
                                    <div className='text-start'>
                                        <FadeInSectionDownToUp>
                                            <p>
                                                <span className='pr-5 max-sm:pr-2'>:</span>Training Manager
                                            </p>
                                        </FadeInSectionDownToUp>
                                    </div>
                                    <div className='text-start'>
                                        <FadeInSectionDownToUp>
                                            <p>
                                                Duration
                                            </p>
                                        </FadeInSectionDownToUp>
                                    </div>
                                    <div className='text-start'>
                                        <FadeInSectionDownToUp>
                                            <p>
                                                <span className='pr-5 max-sm:pr-2'>:</span>Permanent
                                            </p>
                                        </FadeInSectionDownToUp>
                                    </div>
                                    <div className='text-start'>
                                        <FadeInSectionDownToUp>
                                            <p>
                                                Travel requirements
                                            </p>
                                        </FadeInSectionDownToUp>
                                    </div>
                                    <div className='text-start'>
                                        <FadeInSectionDownToUp>
                                            <p>
                                                <span className='pr-5 max-sm:pr-2'>:</span>Sporadic travel required
                                            </p>
                                        </FadeInSectionDownToUp>
                                    </div>
                                    <div className='text-start'>
                                        <FadeInSectionDownToUp>
                                            <p>
                                                Age Range
                                            </p>
                                        </FadeInSectionDownToUp>
                                    </div>
                                    <div className='text-start'>
                                        <FadeInSectionDownToUp>
                                            <p>
                                                <span className='pr-5 max-sm:pr-2'>:</span>30-55 yrs
                                            </p>
                                        </FadeInSectionDownToUp>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center items-center'>
                        <FadeInSectionDownToUp>
                            <button
                                className='px-5 py-1 bg-slate-600 text-white font-semibold rounded-lg animate-pulse hover:scale-105 transition-transform duration-700 ease-in-out'
                            >
                                <a href='https://forms.gle/BQKYYuKCrrqaae2KA' target='blank'>
                                    Apply Now
                                </a>
                            </button>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GroundStaff;
