import React from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp'
import { FTOPartners, OurPresenceColleges } from '../../../Components/Our Presence/OurPresenceData'

export default function OurPresence() {
    return (
        <div>
            <div>
                <img src='https://ik.imagekit.io/mhcockpit1/Our%20Presence/Globe%20Image%20New.jpg' alt='Our Presence' />
            </div>
            <div className='flex justify-center items-center px-1'>
                <div className='grid grid-cols-2 gap-1'>
                    <div className='text-start max-sm:py-0 text-3xl max-sm:text-xl flex justify-center items-center'>
                        <FadeInSectionDownToUp>
                            <p>Our Indian</p>
                            <p className='font-semibold'>Training Delivery</p>
                            <p className='font-semibold text-gray-500'>Centres.</p>
                        </FadeInSectionDownToUp>
                    </div>
                    {OurPresenceColleges.map((image, index) => (
                        <div key={index}>
                            <FadeInSectionDownToUp>
                                <img src={image.src} alt={image.alt} />
                            </FadeInSectionDownToUp>
                        </div>
                    ))}
                </div>
            </div>
            <div className='flex justify-center items-center px-1 pt-1'>
                <div className='grid grid-cols-2 gap-1'>
                    {/* <div className='pl-28 max-sm:pl-14 py-10 text-3xl'>
                        <FadeInSectionDownToUp>
                            <p>Our Global <span className='font-semibold'>FTO Partners.</span></p>
                        </FadeInSectionDownToUp>
                    </div> */}
                    <div className='text-start max-sm:py-0 text-3xl max-sm:text-xl flex justify-center items-center'>
                        <FadeInSectionDownToUp>
                            <p>Our Global <br className='hidden xl:hidden sm:block max-sm:block' /><span className='font-semibold'>FTO Partners.</span></p>
                        </FadeInSectionDownToUp>
                    </div>
                    {FTOPartners.map((image, index) => (
                        <div key={index}>
                            <FadeInSectionDownToUp>
                                <img src={image.src} alt={image.alt} />
                            </FadeInSectionDownToUp>
                        </div>
                    ))}
                </div>
            </div>
            {/* <div className='text-start pl-40 max-sm:pl-20 max-sm:pr-10 py-10 max-sm:py-0 max-sm:pb-10 text-3xl xl:text-3xl sm:text-xl max-sm:text-xl'>
                <FadeInSectionDownToUp>
                    <p>
                        New Zealand Aviation AOTEAROA.<br />
                        Global Aviation SA
                    </p>
                </FadeInSectionDownToUp>
            </div> */}
            {/* <div className='flex justify-center items-center'>
                <div className='flex-col space-y-1'>
                    {TrainingPartners.map((image, index) => (
                        <div key={index}>
                            <FadeInSectionDownToUp>
                                <img src={image.src} alt={image.alt} />
                            </FadeInSectionDownToUp>
                        </div>
                    ))}
                </div>
            </div> */}
        </div>
    )
}
