// import React, { useRef } from 'react'
// import AliceCarousel from 'react-alice-carousel';
// import 'react-alice-carousel/lib/alice-carousel.css';
// import { GrNext, GrPrevious } from 'react-icons/gr';
// import { CollegesData } from '../../Components/Colleges/CollegesData';
// import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';

// // Define the button components
// const Button = ({ onClick, position, children }) => (
//     <button
//         className={`absolute top-1/2 transform -translate-y-1/2 bg-gray-700 bg-opacity-85 text-white p-2 rounded-full ${position === 'next' ? 'right-4' : 'left-4'}`}
//         onClick={onClick}
//     >
//         {children}
//     </button>
// );

// const CarouselSection = ({ items, title }) => {
//     const carouselRef = useRef(null);

//     const goToNext = () => {
//         if (carouselRef.current) {
//             carouselRef.current.slideNext();
//         }
//     };

//     const goToPrev = () => {
//         if (carouselRef.current) {
//             carouselRef.current.slidePrev();
//         }
//     };

//     return (
//         <div className="relative">
//             <FadeInSectionDownToUp>
//                 <p className="font-semibold text-5xl tracking-tighter pl-12 py-10 xl:py-10 sm:pt-20 text-gray-400">{title}</p>
//             </FadeInSectionDownToUp>
//             <AliceCarousel
//                 ref={carouselRef}
//                 autoPlay
//                 disableButtonsControls
//                 disableDotsControls
//                 autoPlayInterval={2000}
//                 buttonsDisabled={true}
//                 dotsDisabled={true}
//                 infinite={true}
//                 swipeable={true}
//                 draggable={true}
//                 responsive={{
//                     0: { items: 1 },
//                     568: { items: 2 },
//                     1024: { items: 2 },
//                     1280: { items: 3 },
//                 }}
//             >
//                 {items.map((item, index) => (
//                     <div className='relative hover:scale-105 transition-transform duration-700 ease-in-out'>
//                         <a href={item.link} key={index} className='bg-opacity-25 shadow-lg shadow-gray-300 m-10 h-[650px] max-sm:h-[600px] flex flex-col items-center rounded-2xl' rel="noopener noreferrer">
//                             <img src={item.src} alt={item.alt} className='w-full rounded-t-2xl' />
//                             <p className='px-5 py-10 text-center font-semibold text-md'>{item.title}</p>
//                             <div className='flex gap-20'>
//                                 <p>{item.location}📍</p>
//                                 <p className='text-gray-400'>({item.ugc})</p>
//                             </div>
//                             <p className='text-center px-2 py-3'>{item.courses}</p>
//                             <div className='absolute bottom-6'>
//                                 <a href={item.link}>
//                                     <button className='bg-gray-300 px-5 py-1 rounded-lg'>{item.button}</button>
//                                 </a>
//                             </div>
//                         </a>
//                     </div>
//                 ))}
//             </AliceCarousel>
//             <Button onClick={goToNext} position="next"><GrNext /></Button>
//             <Button onClick={goToPrev} position="prev"><GrPrevious /></Button>
//         </div>
//     );
// };

// export default function Colleges() {
//     return (
//         <div>
//             <CarouselSection items={CollegesData} title="Colleges." />
//         </div>
//     )
// }


import React, { useRef } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { GrNext, GrPrevious } from 'react-icons/gr';
import { CollegesData } from '../../Components/Colleges/CollegesData';
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';

// Define the button components
const Button = ({ onClick, position, children }) => (
    <button
        className={`absolute top-1/2 transform -translate-y-1/2 bg-gray-700 bg-opacity-85 text-white p-2 rounded-full z-10 ${position === 'next' ? 'right-4' : 'left-4'}`}
        onClick={onClick}
    >
        {children}
    </button>
);

const CarouselSection = ({ items, title }) => {
    const carouselRef = useRef(null);

    const goToNext = () => {
        if (carouselRef.current) {
            carouselRef.current.slideNext();
        }
    };

    const goToPrev = () => {
        if (carouselRef.current) {
            carouselRef.current.slidePrev();
        }
    };

    return (
        <div className="relative">
            <FadeInSectionDownToUp>
                <p className="font-semibold text-3xl md:text-4xl lg:text-5xl tracking-tighter pl-4 py-8 md:pl-8 lg:pl-12 lg:py-10 text-gray-400">{title}</p>
            </FadeInSectionDownToUp>
            <AliceCarousel
                ref={carouselRef}
                autoPlay
                disableButtonsControls
                disableDotsControls
                autoPlayInterval={2000}
                buttonsDisabled={true}
                dotsDisabled={true}
                infinite={true}
                swipeable={true}
                draggable={true}
                responsive={{
                    0: { items: 1 },
                    568: { items: 2 },
                    768: { items: 2 },
                    1024: { items: 3 },
                    1280: { items: 3 },
                }}
            >
                {items.map((item, index) => (
                    <div
                        key={index}
                        className='relative flex flex-col bg-opacity-25 shadow-lg shadow-gray-300 rounded-2xl overflow-hidden h-[650px] max-sm:h-[600px] mx-5 my-10 hover:scale-105 transition-transform duration-700 ease-in-out'
                    >
                        <a href={item.link} className='flex flex-col h-full' rel="noopener noreferrer">
                            <img src={item.src} alt={item.alt} className='w-full h-1/2' />
                            <div className='flex flex-col flex-grow p-4'>
                                <p className='font-semibold text-lg md:text-xl'>{item.title}</p>
                                <div className='flex flex-col md:flex-row justify-start gap-4 mt-2'>
                                    <p>{item.location}📍</p>
                                    <p className='text-gray-400'>({item.ugc})</p>
                                </div>
                                <p className='mt-2 text-center'>{item.courses}</p>
                            </div>
                            <div className='p-4 flex justify-center'>
                                <a href={item.link}>
                                    <button className='bg-gray-300 px-4 py-2 rounded-lg text-center'>{item.button}</button>
                                </a>
                            </div>
                        </a>
                    </div>
                ))}
            </AliceCarousel>
            <Button onClick={goToNext} position="next"><GrNext /></Button>
            <Button onClick={goToPrev} position="prev"><GrPrevious /></Button>
        </div>
    );
};

export default function Colleges() {
    return (
        <div>
            <CarouselSection items={CollegesData} title="Colleges." />
        </div>
    );
}
