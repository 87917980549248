import React from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function Blog5() {
    return (
        <div>
            <div className='xl:pt-0 sm:pt-12'>
                <FadeInSectionDownToUp>
                    <h1 className='text-center font-semibold text-3xl xl:text-3xl sm:text-xl max-sm:text-lg py-5'>The Development ofElectric and hybrid Aircraft</h1>
                </FadeInSectionDownToUp>
                <hr />
            </div>
            <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 text-justify'>
                <div className='py-5'>
                    <img src='https://ik.imagekit.io/mhcockpit1/blogs/Electric.webp' alt='The Development ofElectric and hybrid Aircraft' />
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            Electric and hybrid aircraft are taking flight, but the journey promises to be exciting yet bumpy. While brimming with opportunities, these technologies face considerable challenges before becoming mainstream. Here's a breakdown:
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Challenges
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className="text-lg mb-5 text-justify">
                    <ul className='space-y-3'>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Battery weight and energy density:</b> Current batteries store less energy per kilogram than jet fuel, limiting range and payload capacity, especially for larger aircraft.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Charging infrastructure:</b> Building and maintaining a network of charging stations, particularly for larger aircraft, needs significant investment and planning.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Regulations and safety standards:</b> Certifying new propulsion systems and addressing concerns about redundancy and emergency procedures requires collaboration between manufacturers and regulators.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Cost:</b> Development and initial purchase costs are currently higher than conventional aircraft.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Noise:</b> While quieter than gas turbines, propeller noise and electric hum require consideration.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                    </ul>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Opportunities
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className="text-lg mb-5 text-justify">
                    <ul className='space-y-3'>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Environmental benefits:</b> Zero or reduced emissions can significantly decrease the aviation industry's carbon footprint.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Operational efficiency:</b> Electric motors have lower maintenance requirements and potentially offer better fuel economy for shorter routes.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>New market segments:</b> Urban air mobility (UAM) and electric vertical takeoff and landing (eVTOL) aircraft open up possibilities for short-distance, point-to- point travel.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Technological advancements:</b> Research in battery technology, energy management systems, and advanced airframe design holds promise for overcoming current limitations.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                    </ul>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Impact on the future of air travel
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            The future of air travel with electric and hybrid aircraft seems likely to be <b>multifaceted:</b>
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className="text-lg mb-5 text-justify">
                    <ul className='space-y-3'>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Short-haul flights:</b> Electric and hybrid options could dominate regional and intra-city routes, offering quieter, cleaner, and potentially more affordable travel.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Long-haul flights:</b> Larger electric aircraft for long-haul journeys may take longer to materialize, requiring significant advancements in battery technology and infrastructure.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>UAM and eVTOL:</b> These innovative concepts could revolutionize short-distance travel, offering quicker connections within cities and between nearby urban centers.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                    </ul>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            Overall, the development of electric and hybrid aircraft presents both significant challenges and exciting opportunities. Overcoming these hurdles will require collaboration between various stakeholders, but the potential rewards for the environment and the future of air travel are substantial.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            It's important to note that the timeline for these changes is uncertain. While technological advancements are occurring rapidly, widespread adoption of electric and hybrid aircraft in various segments of the aviation industry may take years, if not decades.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
        </div>
    )
}
