import React from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function BscAME() {
    return (
        <div>
            <div className="relative text-justify">
                <div className="inset-0 overflow-hidden">
                    <img
                        className='w-full h-full object-cover animate-zoomOut'
                        src="https://ik.imagekit.io/mhcockpit1/AME%20Instructors.webp"
                        alt="DGCA - License conversion"
                    />
                </div>
                <div className="relative py-10">
                    <div className='pl-40 max-sm:pl-10 text-4xl max-sm:text-2xl font-semibold tracking-tighter py-10'>
                        <FadeInSectionDownToUp>
                            <h1>B.Sc Aircraft Maintenance Science.</h1>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-xl'>
                        <FadeInSectionDownToUp>
                            <p>
                                AME plays a vital role in the aviation industry to ensure that aircraft’s are maintained to the highest standards, as laid down by the DGCA. In the course of their duties, AMEs are responsible for service and maintenance of the aircraft which includes repairs, replacements, overhaul, and inspection of the aircraft, aircraft components and other related items of equipment. The aviation and airline industry in India is expected to continue to grow rapidly and also the number of modern aircraft flying in India is expected to increase. India is likely to emerge as the third largest aviation market in the next few years. As a direct result, the need for more AMEs will continue to be felt even more strongly, as airlines and other aircraft operators will require skilled and licensed engineers to maintain and service aircraft.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='flex xl:flex-row lg:flex-row max-sm:flex-col sm:flex-col pt-14 gap-2 px-40 max-sm:px-10'>
                        <div className='bg-black text-white w-1/4 lg:w-1/2 xl:w-1/4 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='font-bold text-xl pb-3'>Duration</p>
                                <p className='text-2xl'>3 years</p>
                            </FadeInSectionDownToUp>
                        </div>
                        <div className='bg-black text-white w-3/4 lg:w-3/4 xl:w-3/4 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 max-sm:px-5 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='font-bold text-xl pb-3'>Eligibility</p>
                                <ul>
                                    <div className='flex'>
                                        <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                            Candidate must passed Degree in Engineering such as (Mechanical, Electrical and Electronics, Electronics and Communication, Computer Science).
                                        </li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                            Candidates age can be between 21 to 28 years at the time of admission.
                                        </li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                            Candidate must not have color blindness and any physical disabilities.
                                        </li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                            Candidates must have minimum 45% mark in 10 +2 PCM or equivalent.
                                        </li>
                                    </div>
                                </ul>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                    <div className='pt-2 px-40 max-sm:px-10'>
                        <div className='bg-black text-white py-5 pl-20 max-sm:pl-5 flex justify-start items-center rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className=' font-bold text-xl pb-3'>Career Opportunities</p>
                                <ul>
                                    <div className='flex'>
                                        <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                            Scheduled Airlines.
                                        </li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                            Non-Scheduled Airline.
                                        </li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                            Cargo Carriers.
                                        </li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                            Approved Maintenance Organisation.
                                        </li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                            Aircraft Manufacturing Companies.
                                        </li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                            Aircraft Operations Organisation.
                                        </li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                            Aviation Training Centres.
                                        </li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                            Flying Clubs.
                                        </li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                            Civil Defence Forces.
                                        </li>
                                    </div>
                                </ul>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Course Specialisations.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-lg max-sm:text-sm font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                A is the license for the engineer who can maintain, repair, and troubleshoot the simple defect of an aircraft.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        A1 license holder solve the simple defect in aeroplane with jet engine.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        A2 license holder rectify and repair the simple defect in aeroplane with piston engine.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        A3 licensed AME maintain, repair, and solve the simple defect in helicopter with jet engine.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        A4 licensed AME maintain, repair, and solve the simple defect in helicopter with piston engine.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-lg max-sm:text-sm font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                B1 is the license for mechanical stream in aircrafts. The mechanical stream is related to the components such as Fuselage, Landing Gear, and Wings etc. This license have further divided in the following categories.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        License B1.1 certifies the aeroplanes with Jet Engine.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        License B1.2 certifies the aeroplanes with Piston Engine.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        License B1.3 certifies the helicopters with Jet Engine.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        License B1.4 certifies the helicopters with Piston Engine.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-lg max-sm:text-sm font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                B2 is the license for avionics stream in aircrafts. The avionics stream is related to the electronics system. This license can be attained in following categories.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Electrical System Instrumental System.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Radio Navigation and Communication.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='flex justify-center items-center px-40 max-sm:px-10'>
                        <div className='grid grid-cols-2 max-sm:block'>
                            <div>
                                <div className='py-5 text-2xl max-sm:text-lg font-semibold'>
                                    <FadeInSectionDownToUp>
                                        <p>
                                            DGCA AME Modules.
                                        </p>
                                    </FadeInSectionDownToUp>
                                </div>
                                <div className="text-lg mb-5 text-justify py-5">
                                    <ul className='space-y-3'>
                                        <FadeInSectionDownToUp>
                                            <div className='flex'>
                                                <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                                    Electrical Fundamentals.
                                                </li>
                                            </div>
                                        </FadeInSectionDownToUp>
                                        <FadeInSectionDownToUp>
                                            <div className='flex'>
                                                <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                                    Electronic Fundamentals.
                                                </li>
                                            </div>
                                        </FadeInSectionDownToUp>
                                        <FadeInSectionDownToUp>
                                            <div className='flex'>
                                                <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                                    Digital Techniques & Electronic Instruments Systems.
                                                </li>
                                            </div>
                                        </FadeInSectionDownToUp>
                                        <FadeInSectionDownToUp>
                                            <div className='flex'>
                                                <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                                    Materials & Hardware.
                                                </li>
                                            </div>
                                        </FadeInSectionDownToUp>
                                        <FadeInSectionDownToUp>
                                            <div className='flex'>
                                                <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                                    Maintenance Practices.
                                                </li>
                                            </div>
                                        </FadeInSectionDownToUp>
                                        <FadeInSectionDownToUp>
                                            <div className='flex'>
                                                <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                                    Propulsion.
                                                </li>
                                            </div>
                                        </FadeInSectionDownToUp>
                                        <FadeInSectionDownToUp>
                                            <div className='flex'>
                                                <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                                    Gas Turbine Engine.
                                                </li>
                                            </div>
                                        </FadeInSectionDownToUp>
                                        <FadeInSectionDownToUp>
                                            <div className='flex'>
                                                <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                                    Aerodynamics.
                                                </li>
                                            </div>
                                        </FadeInSectionDownToUp>
                                        <FadeInSectionDownToUp>
                                            <div className='flex'>
                                                <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                                    Human Factors.
                                                </li>
                                            </div>
                                        </FadeInSectionDownToUp>
                                        <FadeInSectionDownToUp>
                                            <div className='flex'>
                                                <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                                    Aviation Legislation.
                                                </li>
                                            </div>
                                        </FadeInSectionDownToUp>
                                        <FadeInSectionDownToUp>
                                            <div className='flex'>
                                                <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                                    Turbine Aeroplane Aerodynamics Structure & Systems.
                                                </li>
                                            </div>
                                        </FadeInSectionDownToUp>
                                        <FadeInSectionDownToUp>
                                            <div className='flex'>
                                                <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                                    Piston Aeroplane Aerodynamics, Structure & Systems.
                                                </li>
                                            </div>
                                        </FadeInSectionDownToUp>
                                        <FadeInSectionDownToUp>
                                            <div className='flex'>
                                                <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                                    Piston Engine.
                                                </li>
                                            </div>
                                        </FadeInSectionDownToUp>
                                        <FadeInSectionDownToUp>
                                            <div className='flex'>
                                                <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                                    Propellers.
                                                </li>
                                            </div>
                                        </FadeInSectionDownToUp>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <div className='py-5 text-2xl max-sm:text-lg font-semibold'>
                                    <FadeInSectionDownToUp>
                                        <p>
                                            AME Syllabus.
                                        </p>
                                    </FadeInSectionDownToUp>
                                </div>
                                <div className='py-5 text-xl'>
                                    <FadeInSectionDownToUp>
                                        <p>
                                            AME Course is 3 years licensed program in which candidate has to undergo 5 semesters (2.5 years) academic program and 6 months training in flying environment. In this 3 years academic program, candidate studies about theoretical and practical knowledge of aircraft engineering, principals and components. There are 17 modules by DGCA, Govt of India. The candidate has to choose stream, modules are depends upon the stream of candidate. Candidate has to clear modules to achieve the AME License. Module 1 and 2 are not applicable in India.
                                        </p>
                                    </FadeInSectionDownToUp>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
