import React, { useState } from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function LabEstablishment() {

    // State to manage which accordion item is expanded
    const [expanded, setExpanded] = useState(null);

    // Function to handle the toggle of accordion items
    const toggleAccordion = (index) => {
        setExpanded(expanded === index ? null : index);
    };

    return (
        <div className='text-justify'>
            <div>
                <img src='https://ik.imagekit.io/mhcockpit1/Building%20Lab%20Infrastructure%20-%20Image.webp' alt='Lab Establishment' />
            </div>
            <div className='space-y-5 px-20 max-sm:px-5 py-10 text-xl max-sm:text-sm text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        The civil aviation industry in India has emerged as one of the fastest growing industries in the country during the last three years. India has become the third largest domestic aviation market in the world and is expected to overtake UK to become the third largest air passenger* market by 2024.
                    </p>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <p>
                        India's aviation industry is largely untapped with huge growth opportunities, considering that air transport is still expensive for majority of the country's population, of which nearly 40% is the upwardly mobile middle class. The industry stakeholders should engage and collaborate with policy makers to implement efficient and rational decisions that would boost India's civil aviation industry. With the right policies and relentless focus on quality, cost and passenger interest, India would be well placed to achieve its vision of becoming the third-largest aviation market by 2020. The expenditure of Indian travellers is expected to grow up to Rs. 9.5 lakh crore (US$ 136 billion) by 2021. Due to rise in demand in air travel, India will need 2,380 new commercial airplanes by 2038.
                    </p>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <p>
                        Aviation education with practical approach to learning domain will make huge difference to create next generation professionals to the industry. MH Cockpit is committed to deliver excellence in establishing world class lab facilities to or clients. We are experts in setting up the following labs listed below.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className="space-y-4 pb-10">

                <div className="rounded-xl mx-20 max-sm:mx-5 border">
                    <div
                        className="px-10 max-sm:px-5 py-2 cursor-pointer rounded-xl bg-black text-white hover:text-[#ffa500]"
                        onClick={() => toggleAccordion(1)}
                    >
                        <h5 className="text-lg font-semibold">
                            <FadeInSectionDownToUp>
                                Turbo jet engine lab.
                            </FadeInSectionDownToUp>
                        </h5>
                    </div>
                    <div
                        className={`transition-max-height duration-700 ease-in-out overflow-hidden ${expanded === 1 ? 'max-h-max rounded-xl shadow-lg opacity-100' : 'max-h-0 opacity-0'
                            }`}
                    >
                        <FadeInSectionDownToUp>
                            <div className="p-4 bg-white space-y-5 text-lg max-sm:text-sm px-10 max-sm:px-4">
                                <p>
                                    MH Cockpit equipment is a self-contained turbojet engine test cell. Utilized by hundreds of leading educational institutions, the included SR30 turbojet engine offers exciting teaching opportunities in applied thermodynamics and jet propulsion.
                                </p>
                                <p>
                                    Our data acquisition system displays and records compressor inlet temperature and pressure, turbine inlet temperature and pressure, turbine exit temperature and pressure, thrust and fuel flow and makes possible studies of:
                                </p>
                                <p>
                                    Brayton Cycle.
                                </p>
                                <p>
                                    Compressor Performance.
                                </p>
                                <p>
                                    Turbine Performance - work & power, expansion ratio, turbine efficiency.
                                </p>
                                <p>
                                    Combustion/Emissions Analysis.
                                </p>
                            </div>
                        </FadeInSectionDownToUp>
                    </div>
                </div>

                <div className="rounded-xl mx-20 max-sm:mx-5 border">
                    <div
                        className="px-10 max-sm:px-5 py-2 cursor-pointer rounded-xl bg-black text-white hover:text-[#ffa500]"
                        onClick={() => toggleAccordion(2)}
                    >
                        <h5 className="text-lg font-semibold">
                            <FadeInSectionDownToUp>
                                SR30 Turbojet Engine.
                            </FadeInSectionDownToUp>
                        </h5>
                    </div>
                    <div
                        className={`transition-max-height duration-700 ease-in-out overflow-hidden ${expanded === 2 ? 'max-h-max rounded-xl shadow-lg opacity-100' : 'max-h-0 opacity-0'
                            }`}
                    >
                        <FadeInSectionDownToUp>
                            <div className="p-4 bg-white space-y-5 text-lg max-sm:text-sm px-10 max-sm:px-4">
                                <p>
                                    All engine support systems are fully integrated. The system is easily rolled to any convenient location. No dedicated test cell or facilities modifications are required. The time between delivery and first run is measured in minutes. The included data acquisition system makes real-time engine data display and recording effortless.CAD model of all primary engine components are also available, along with their flow-cavity negatives, to support CFD, FEA, or other turbomachinery related analysis.
                                </p>
                                <p>
                                    Equipped with the Auto Start System, virtually anyone can operate Start sequencing is completely automatic with all critical engine parameters monitored during operation. In the unlikely event of an engine fault, the system will stop the engine and alert the operator to the problem.
                                </p>
                                <p>
                                    On-Board Jet Engine Data Acquisition.
                                </p>
                                <p>
                                    The pre-installed Interactive Virtual Instrument (VI) Panel displays and records pertinent engine data for follow-on study. This LabVIEW™ generated VI offers a real-time graphical perspective of engine performance. All engine operating parameters are displayed clearly on the engine cutaway graphic.
                                </p>
                                <p>
                                    Engine pressures, temperatures and fuel flow are displayed in digital data windows. Engine RPM and thrust are displayed on analog-style round meters for a neat visual cue (the readings are also displayed digitally below each meter).
                                </p>
                                <p>
                                    The real-time plotting feature lets the operator plot any parameter on screen as it occurs to provide a clear sense of how the data is reacting to the actual system operating conditions. Operators can toggle between all the parameters to watch them graphically. Data logging functionality is conveniently controlled from the VI screen. All the jet engine power data can be observed and tracked through its full throttle range. System units can be changed with a simple click of the mouse. Feeding this interactive VI is data from the on-board National Instruments 6218 Data Acquisition System. That same data is also stored for later retrieval and analysis and the VI code is even open for user programming and customization!
                                </p>
                                <p>
                                    An engine cut-away model is also available.
                                </p>
                            </div>
                        </FadeInSectionDownToUp>
                    </div>
                </div>

                <div className="rounded-xl mx-20 max-sm:mx-5 border">
                    <div
                        className="px-10 max-sm:px-5 py-2 cursor-pointer rounded-xl bg-black text-white hover:text-[#ffa500]"
                        onClick={() => toggleAccordion(3)}
                    >
                        <h5 className="text-lg font-semibold">
                            <FadeInSectionDownToUp>
                                Turbo shaft engine lab.
                            </FadeInSectionDownToUp>
                        </h5>
                    </div>
                    <div
                        className={`transition-max-height duration-700 ease-in-out overflow-hidden ${expanded === 3 ? 'max-h-max rounded-xl shadow-lg opacity-100' : 'max-h-0 opacity-0'
                            }`}
                    >
                        <FadeInSectionDownToUp>
                            <div className="p-4 bg-white space-y-5 text-lg max-sm:text-sm px-10 max-sm:px-4">
                                <p>
                                    The primary component is a self-contained turboshaft engine. The engines mechanically-free power turbine drives an electric generator. The system is expressly designed for energy conversion research and education.
                                </p>
                                <p>
                                    To compliment the impressive hands-on hardware, features an extensive sensor suite, National Instruments Data Acquisition System, and LabVIEW™ Interactive Virtual Instrument Panel, which allows for real time monitoring and recording of sensor data.
                                </p>
                                <p>
                                    The Interactive Virtual Instrument (VI) Panel is a high-powered extension of the system’s main operator control panel. This generated VI offers a real-time graphical cutaway perspective of the system operation, which allows operators to “look inside”. All system operating parameters are displayed as they occur with system locations clearly indicated on the cutaway graphic.
                                </p>
                            </div>
                        </FadeInSectionDownToUp>
                    </div>
                </div>

                <div className="rounded-xl mx-20 max-sm:mx-5 border">
                    <div
                        className="px-10 max-sm:px-5 py-2 cursor-pointer rounded-xl bg-black text-white hover:text-[#ffa500]"
                        onClick={() => toggleAccordion(4)}
                    >
                        <h5 className="text-lg font-semibold">
                            <FadeInSectionDownToUp>
                                Virtual Instrument Panel.
                            </FadeInSectionDownToUp>
                        </h5>
                    </div>
                    <div
                        className={`transition-max-height duration-700 ease-in-out overflow-hidden ${expanded === 4 ? 'max-h-max rounded-xl shadow-lg opacity-100' : 'max-h-0 opacity-0'
                            }`}
                    >
                        <FadeInSectionDownToUp>
                            <div className="p-4 bg-white space-y-5 text-lg max-sm:text-sm px-10 max-sm:px-4">
                                <p>
                                    Pressures, temperatures, fuel flow, voltage, current, and power parameters are displayed in numerical display windows. Turbo-shaft engine and generator RPM are displayed on analog-style round meters for a neat visual cue. (The readings are also displayed digitally below each meter).
                                </p>
                                <p>
                                    The real-time plotting feature lets the operator plot any parameter on screen as it occurs in order to provide a clear sense of how the data is reacting to the actual system operating conditions. Operators can toggle between all the parameters to watch them graphically. Data logging functionality is conveniently controlled from the VI screen. All the jet engine power data can be observed and tracked as it affects the electric generator data. System units can be changed with a simple click of the mouse.
                                </p>
                                <p>
                                    6218 Data Acquisition System.
                                </p>
                                <p>
                                    Feeding this interactive VI is data from the on-board National Instruments 6218 Data Acquisition System. That same data is also stored on the included laptop PC for later retrieval and analysis.
                                </p>
                                <p>
                                    Researchers and students alike will find easy to operate while providing extensive performance capabilities. The engine is fully throttle-able while the free turbine can be loaded over a wide operational range (by varying generator load) giving researchers and students a broad, combined, study bandwidth.
                                </p>
                                <p>
                                    It features many innovative operational sub-systems including a fuel-cooled alternator, exhaust powered cabinet cooling fan, integrated resistive load element, and custom speed tach generators. It comes standard with unsurpassed factory-direct support. If you demand more than virtual reality for your gas power research or educational lab, it’s as real as it gets.
                                </p>
                            </div>
                        </FadeInSectionDownToUp>
                    </div>
                </div>

                <div className="rounded-xl mx-20 max-sm:mx-5 border">
                    <div
                        className="px-10 max-sm:px-5 py-2 cursor-pointer rounded-xl bg-black text-white hover:text-[#ffa500]"
                        onClick={() => toggleAccordion(5)}
                    >
                        <h5 className="text-lg font-semibold">
                            <FadeInSectionDownToUp>
                                Experimental Opportunities.
                            </FadeInSectionDownToUp>
                        </h5>
                    </div>
                    <div
                        className={`transition-max-height duration-700 ease-in-out overflow-hidden ${expanded === 5 ? 'max-h-max rounded-xl shadow-lg opacity-100' : 'max-h-0 opacity-0'
                            }`}
                    >
                        <FadeInSectionDownToUp>
                            <div className="p-4 bg-white space-y-5 text-lg max-sm:text-sm px-10 max-sm:px-4">
                                <p>
                                    Energy relationships and the First Law of Thermodynamics.
                                </p>
                                <p>
                                    Cycle analysis and the Second Law of Thermodynamics.
                                </p>
                                <p>
                                    Control volume analysis.
                                </p>
                                <p>
                                    Entropy and enthalpy analysis.Isentropic analysis.
                                </p>
                                <p>
                                    Isentropic analysis.
                                </p>
                                <p></p>
                                Electric power generation analysis.
                                <p>
                                    Cycle and component efficiency studies.
                                </p>
                                <p>
                                    FEA & CFD analysis via available component CAD models.
                                </p>
                                <p>
                                    Airfoil velocity vector diagram construction.
                                </p>
                                <p>
                                    Experimental and data acquisition technique.
                                </p>
                            </div>
                        </FadeInSectionDownToUp>
                    </div>
                </div>

                <div className="rounded-xl mx-20 max-sm:mx-5 border">
                    <div
                        className="px-10 max-sm:px-5 py-2 cursor-pointer rounded-xl bg-black text-white hover:text-[#ffa500]"
                        onClick={() => toggleAccordion(6)}
                    >
                        <h5 className="text-lg font-semibold">
                            <FadeInSectionDownToUp>
                                Steam Turbine Engine.
                            </FadeInSectionDownToUp>
                        </h5>
                    </div>
                    <div
                        className={`transition-max-height duration-700 ease-in-out overflow-hidden ${expanded === 6 ? 'max-h-max rounded-xl shadow-lg opacity-100' : 'max-h-0 opacity-0'
                            }`}
                    >
                        <FadeInSectionDownToUp>
                            <div className="p-4 bg-white space-y-5 text-lg max-sm:text-sm px-10 max-sm:px-4">
                                <p>
                                    The steam turbine power plant utilizes beautifully-crafted working components. Front and rear boiler doors open with push button ease, providing a clear view of flame tubes, the fuel nozzle, vortex generator and igniter.
                                </p>
                                <p>
                                    Sensors are installed at all key locations and measure temperatures, pressures, fuel-flow rate (energy input) and electrical energy output. Signal outputs are conditioned and factory calibrated for “real-time” display on the included, student-configurable virtual instrument.
                                </p>
                                <p>
                                    It's a truly unmatched value in hands-on energy cycle analysis.
                                </p>
                                <p>
                                    It allows participants to complete many energy/efficiency related experiments.
                                </p>
                            </div>
                        </FadeInSectionDownToUp>
                    </div>
                </div>

                <div className="rounded-xl mx-20 max-sm:mx-5 border">
                    <div
                        className="px-10 max-sm:px-5 py-2 cursor-pointer rounded-xl bg-black text-white hover:text-[#ffa500]"
                        onClick={() => toggleAccordion(7)}
                    >
                        <h5 className="text-lg font-semibold">
                            <FadeInSectionDownToUp>
                                Experimental Opportunities.
                            </FadeInSectionDownToUp>
                        </h5>
                    </div>
                    <div
                        className={`transition-max-height duration-700 ease-in-out overflow-hidden ${expanded === 7 ? 'max-h-max rounded-xl shadow-lg opacity-100' : 'max-h-0 opacity-0'
                            }`}
                    >
                        <FadeInSectionDownToUp>
                            <div className="p-4 bg-white space-y-5 text-lg max-sm:text-sm px-10 max-sm:px-4">
                                <p>
                                    Energy relationships and the First Law of Thermodynamics.
                                </p>
                                <p>
                                    Cycle analysis and the Second Law of Thermodynamics.
                                </p>
                                <p>
                                    Control volume analysis.
                                </p>
                                <p>
                                    Entropy analysis.
                                </p>
                                <p>
                                    Isentropic analysis and the study of turbine/nozzle efficiency.
                                </p>
                                <p>
                                    Heat transfer analysis and the study of boiler efficiency.
                                </p>
                                <p>
                                    Combustion processes.
                                </p>
                                <p>
                                    Vapor power system fundamentals.
                                </p>
                                <p>
                                    Electric power generation.
                                </p>
                                <p>
                                    Electric power generation.
                                </p>
                            </div>
                        </FadeInSectionDownToUp>
                    </div>
                </div>

                <div className="rounded-xl mx-20 max-sm:mx-5 border">
                    <div
                        className="px-10 max-sm:px-5 py-2 cursor-pointer rounded-xl bg-black text-white hover:text-[#ffa500]"
                        onClick={() => toggleAccordion(8)}
                    >
                        <h5 className="text-lg font-semibold">
                            <FadeInSectionDownToUp>
                                Purchase Specifications.
                            </FadeInSectionDownToUp>
                        </h5>
                    </div>
                    <div
                        className={`transition-max-height duration-700 ease-in-out overflow-hidden ${expanded === 8 ? 'max-h-max rounded-xl shadow-lg opacity-100' : 'max-h-0 opacity-0'
                            }`}
                    >
                        <FadeInSectionDownToUp>
                            <div className="p-4 bg-white space-y-5 text-lg max-sm:text-sm px-10 max-sm:px-4">
                                <p>
                                    A steam turbine plant designed for engineering steam power education.
                                </p>
                                <p>
                                    Consisting of a fossil-fuelled boiler, steam turbine and condenser tower mounted on a rigid, mobile frame.
                                </p>
                                <p>
                                    Boiler of a tube-type, flame through design with access doors to view the inner workings.
                                </p>
                                <p>
                                    Steam rate adjustable through a steam admission valve, regulating turbine speed and energy output.
                                </p>
                                <p>
                                    Axial flow turbine used to drive an alternating current generator.
                                </p>
                                <p>
                                    Generator output to be rectified allowing the output of direct current.
                                </p>
                                <p>
                                    Generator output capable of delivering 15 Volts at 1 Amp to infinitely adjustable 15 Watt load.
                                </p>
                                <p>
                                    Unit to include analog boiler pressure gauge, generator voltage and generator current meters.
                                </p>
                                <p>
                                    To be supplied with a USB based digital data acquisition system complete with computer and user configurable data acquisition software capable of measuring and recording analog, digital and frequency signals.
                                </p>
                                <p>
                                    Equipped with calibrated transducers and thermocouples capable of measuring boiler temperature and pressure, turbine inlet and exit temperature and pressure, turbine RPM, fuel flow rate and generator load, voltage and current.
                                </p>
                                <p>
                                    All metal surfaces to be stainless steel, anodized or powder coated to promote durability and wear resistance.
                                </p>
                                <p>
                                    Provided with a comprehensive Operator's Manual with design, operation and construction information.
                                </p>
                                <p>
                                    Provided with summary operating checklists for all operating conditions.
                                </p>
                                <p>
                                    Provided with safety instructions to address all operating conditions.
                                </p>
                                <p>
                                    To be covered by a free two year warranty.
                                </p>
                            </div>
                        </FadeInSectionDownToUp>
                    </div>
                </div>

                <div className="rounded-xl mx-20 max-sm:mx-5 border">
                    <div
                        className="px-10 max-sm:px-5 py-2 cursor-pointer rounded-xl bg-black text-white hover:text-[#ffa500]"
                        onClick={() => toggleAccordion(9)}
                    >
                        <h5 className="text-lg font-semibold">
                            <FadeInSectionDownToUp>
                                Wind Turbine Lab.
                            </FadeInSectionDownToUp>
                        </h5>
                    </div>
                    <div
                        className={`transition-max-height duration-700 ease-in-out overflow-hidden ${expanded === 9 ? 'max-h-max rounded-xl shadow-lg opacity-100' : 'max-h-0 opacity-0'
                            }`}
                    >
                        <FadeInSectionDownToUp>
                            <div className="p-4 bg-white space-y-5 text-lg px-10 max-sm:px-4">
                                <p>
                                    Its is housed in a self-contained wind tunnel and includes a variable frequency drive for programming custom wind profiles. Its 3-phase generator is driven by a planetary gearbox. A unique aspect includes rapid prototyped airfoils that enable open-ended student design.
                                </p>
                                <p>
                                    State of the Art Technology.
                                </p>
                                <p>
                                    This state-of-the-art wind power education system features custom on-board instrumentation created and has an open architecture for student designed instrumentation and data collection.
                                </p>
                                <p className='text-2xl font-semibold'>Purchase Specifications.</p>
                                <p>
                                    A wind turbine electric power plant designed for engineering classrooms, wind energy training, and research.
                                </p>
                                <p>
                                    A programmable wind fan and scale wind turbine mounted in a rigid, mobile wind tunnel cabinet.
                                </p>
                                <p>
                                    Wind fan to be driven by on-board, variable frequency drive with panel mounted speed controller.
                                </p>
                                <p>
                                    Wind turbine to be industry-standard three blade horizontal axis configuration with adjustable/replaceable blades.
                                </p>
                                <p>
                                    Wind turbine to drive planetary gear configuration step-up gear box.
                                </p>
                                <p>
                                    Generator to consist of a three phase, DC excited 8 pole rotor, eight pole three phase stator with alternating current output.
                                </p>
                                <p>
                                    Unit to include operator panel-mounted generator voltage and current meters for each electrical power phase.
                                </p>
                                <p>
                                    Load to be supplied by three operator panel-mounted rheostats, adjusted equally for balanced load, or unequally for unbalanced load scenarios.
                                </p>
                                <p>
                                    To be supplied with a USB based digital data acquisition system complete with computer and user configurable data acquisition software capable of measuring, recording and displaying analog, digital and frequency signals, including: Wind Speed, Turbine Rotor Speed, Generator RPM, Voltage, Current, Power and Frequency.
                                </p>
                                <p>
                                    Equipped with calibrated transducers capable of measuring all elements required for data acquisition system.
                                </p>
                                <p>
                                    All metal surfaces to be stainless steel, anodized, or powder coated to promote durability and wear resistance.
                                </p>
                                <p>
                                    Provided with a comprehensive Operator's Manual with design, operation, and construction information.
                                </p>
                            </div>
                        </FadeInSectionDownToUp>
                    </div>
                </div>

                <div className="rounded-xl mx-20 max-sm:mx-5 border">
                    <div
                        className="px-10 max-sm:px-5 py-2 cursor-pointer rounded-xl bg-black text-white hover:text-[#ffa500]"
                        onClick={() => toggleAccordion(10)}
                    >
                        <h5 className="text-lg font-semibold">
                            <FadeInSectionDownToUp>
                                Diesel Engine Lab.
                            </FadeInSectionDownToUp>
                        </h5>
                    </div>
                    <div
                        className={`transition-max-height duration-700 ease-in-out overflow-hidden ${expanded === 10 ? 'max-h-max rounded-xl shadow-lg opacity-100' : 'max-h-0 opacity-0'
                            }`}
                    >
                        <FadeInSectionDownToUp>
                            <div className="p-4 bg-white space-y-5 text-lg max-sm:text-sm px-10 max-sm:px-4">
                                <p>
                                    Diesel is a portable diesel engine performance analysis system comprised of a two-cylinder industrial diesel engine driving an air-cooled dynamometer.The eddy-current dynamometer is induction-loaded through an integrated 48 volt DC variable-excitation system. A built-in torque arm (1 foot in length) actuates a load cell (calibrated to read in lbs) to provide a direct torque readout in ft-lbs.
                                </p>
                                <p>
                                    The eddy-current dynamometer is induction-loaded through an integrated 48 volt DC variable-excitation system. A built-in torque arm (1 foot in length) actuates a load cell (calibrated to read in lbs) to provide a direct torque readout in ft-lbs.
                                </p>
                                <p>
                                    Provided with summary operating checklists and safety instructions for all operating conditions.
                                </p>
                                <p>
                                    To be covered by a free two-year warranty.
                                </p>
                                <p>
                                    Wind energy education today for tomorrow's wind turbine technicians!
                                </p>
                                <p>
                                    Industrial Human-Machine Interface (HMI).
                                </p>
                                <p>
                                    The total system is controlled by a built-in Industrial Human-Machine Interface (HMI) which communicates with an integrated Allen Bradley Programmable Automation Controller (PAC) to control an engine start/stop, throttle position servo drive and dynamometer loading.
                                </p>
                                <p>
                                    The PAC will enable PID feedback control to automatically adjust engine speed based on dynamometer loading (torque). This provides an opportunity to program a variety of operational scenarios that demonstrate the capabilities of diesel power.
                                </p>
                                <p className='text-2xl font-semibold'>Diesel Machine will measure and display the following system operational conditions.</p>
                                <p>
                                    Engine Head Pressure.
                                </p>
                                <p>
                                    Engine Fuel Pressure.
                                </p>
                                <p>
                                    Engine Head Temperature.
                                </p>
                                <p>
                                    Engine Oil Temperature.
                                </p>
                                <p>
                                    Engine Exhaust Gas Temperature.
                                </p>
                                <p>
                                    Engine RPM.
                                </p>
                                <p>
                                    Engine Fuel Flow.
                                </p>
                                <p>
                                    Engine Torque.
                                </p>
                                <p>
                                    Engine Power (Horsepower and Watts).
                                </p>
                                <p>
                                    Dynamometer Excitation Voltage.
                                </p>
                                <p>
                                    Dynamometer Excitation Current.
                                </p>
                            </div>
                        </FadeInSectionDownToUp>
                    </div>
                </div>

                <div className="rounded-xl mx-20 max-sm:mx-5 border">
                    <div
                        className="px-10 max-sm:px-5 py-2 cursor-pointer rounded-xl bg-black text-white hover:text-[#ffa500]"
                        onClick={() => toggleAccordion(11)}
                    >
                        <h5 className="text-lg font-semibold">
                            <FadeInSectionDownToUp>
                                Pumps & Process Automation Lab.
                            </FadeInSectionDownToUp>
                        </h5>
                    </div>
                    <div
                        className={`transition-max-height duration-700 ease-in-out overflow-hidden ${expanded === 11 ? 'max-h-max rounded-xl shadow-lg opacity-100' : 'max-h-0 opacity-0'
                            }`}
                    >
                        <FadeInSectionDownToUp>
                            <div className="p-4 bg-white space-y-5 text-lg max-sm:text-sm px-10 max-sm:px-4">
                                <p>
                                    It is a state-of-the-art process automation and pumps teaching system. It’s constructed with clear-view flow path components that dramatically increase visual learning and comprehension. Students actually get to see how their inputs cause dynamic changes in a fluid system.
                                </p>
                                <p>
                                    Utilizing a Rockwell Automation PAC, along with a large format touch-screen HMI, students are presented with curriculum-driven skill building activities that include process control and programming of tank levels, flow rates, pressures/suction, temperature and mixing. Many characteristics of fluid flow can be viewed and demonstrated, including: cavitation effects on pumps & motors, Net Positive Suction Head (NPSH) calculations, head loss calculations, valve throttling, etc.
                                </p>
                                <p className='text-2xl font-semibold'>About Centrifugal Pumping.</p>
                                <p>
                                    Read, Understand & Develop Pump Curves.
                                </p>
                                <p>
                                    Calculate Net Positive Suction Head (NPSH).
                                </p>
                                <p>
                                    See Fluid Cavitation.
                                </p>
                                <p>
                                    Experiment with Impeller Profiles & Effects on System Performance.
                                </p>
                                <p>
                                    About Plant Automation.
                                </p>
                                <p>
                                    Program Various Mixing, Filling & Flow Scenarios.
                                </p>
                                <p>
                                    Tune multiple P.I.D. loops.
                                </p>
                                <p>
                                    Develop & Troubleshoot Ladder Logic & Structured Text Code.
                                </p>
                                <p>
                                    Network Components & Plant for Remote Operation.
                                </p>
                                <p>
                                    Understand Plant Safety- Lock-out Tag-out.
                                </p>
                            </div>
                        </FadeInSectionDownToUp>
                    </div>
                </div>

                <div className="rounded-xl mx-20 max-sm:mx-5 border">
                    <div
                        className="px-10 max-sm:px-5 py-2 cursor-pointer rounded-xl bg-black text-white hover:text-[#ffa500]"
                        onClick={() => toggleAccordion(12)}
                    >
                        <h5 className="text-lg font-semibold">
                            <FadeInSectionDownToUp>
                                Controls Lab.
                            </FadeInSectionDownToUp>
                        </h5>
                    </div>
                    <div
                        className={`transition-max-height duration-700 ease-in-out overflow-hidden ${expanded === 12 ? 'max-h-max rounded-xl shadow-lg opacity-100' : 'max-h-0 opacity-0'
                            }`}
                    >
                        <FadeInSectionDownToUp>
                            <div className="p-4 bg-white space-y-5 text-lg max-sm:text-sm px-10 max-sm:px-4">
                                <p className='text-2xl font-semibold'>Students Will.</p>
                                <p>
                                    Learn About Closed-Loop Control.
                                </p>
                                <p>
                                    Experiment with Manual & Auto Tuning of a Set-Point.
                                </p>
                                <p>
                                    Gain Hands-on Experience with Proportional, Integral & Derivative Control P.I.D.
                                </p>
                                <p>
                                    Program & Troubleshoot Ladder Logic & Structured Text Code.
                                </p>
                                <p>
                                    Calibrate a Laser Based Distance Measuring Sensor.
                                </p>
                                <p>
                                    Work with a 3-Phase motor, Variable Frequency Drive, Industrial PLC, & HMI.
                                </p>
                                <p>
                                    Students utilize airflow to control the positioning of an aerodynamic float.
                                </p>
                                <p>
                                    The float represents a control dynamic that is analogous to many real world scenarios, such as an automobile's cruise control, temperature control, autopilot set-point capture, or a buildings HVAC control.The float is a very dynamic and visual cue as to the effectiveness of proper tuning.
                                </p>
                                <p>
                                    The on-board programmable automation controller is Rockwell Automation's latest Logix device. Topics including Sensors, Control Loops, Ladder Logic Programming, Industrial Ethernet, HMI, Networking, lock-out/tag outs, and enhanced PID tuning are all explored within the included curriculum.
                                </p>
                                <p>
                                    This comes equipped with pre-installed Rockwell Automation programming software (FactoryTalk View ME and Studio 5000) and allows students to devise real-world, custom industrial process scenarios. No additional hardware or software is required.
                                </p>
                                <p className='text-2xl font-semibold'>This affordable, yet turnkey system consists of the following primary components.</p>
                                <p>
                                    Programmable Process Automation Controller (PAC) Ladder logic with PIDE Autotune, alarms, lock-out tag out, etc.
                                </p>
                                <p>
                                    On-board, pre-configured Wi-Fi for remote & LAN operation with multiple classroom units.
                                </p>
                                <p>
                                    Human Machine Interface (HMI) installed virtual instrument screens for out-of-the-box real-time monitoring of motor performance characteristics, along with manual & PIDE motor control.
                                </p>
                                <p>
                                    Custom open-ended design of instrumentation & process automation scenarios made available by on-board Rockwell Factory Talk View ME V 8.0 & Studio 5000.eaturing a large format, 20-inch color touch screen.
                                </p>
                                <p>
                                    Featuring a large format, 20-inch color touch screen.
                                </p>
                                <p>
                                    Variable Frequency Drive (VFD).
                                </p>
                                <p>
                                    Photoelectric Laser Sensor.
                                </p>
                                <p>
                                    3-Phase Air Blower.
                                </p>
                                <p>
                                    Dual Solenoid-Actuated Air Louvers.
                                </p>
                                <p>
                                    Convenient Wall Power & Data Connectivity.
                                </p>
                            </div>
                        </FadeInSectionDownToUp>
                    </div>
                </div>

                <div className="rounded-xl mx-20 max-sm:mx-5 border">
                    <div
                        className="px-10 max-sm:px-5 py-2 cursor-pointer rounded-xl bg-black text-white hover:text-[#ffa500]"
                        onClick={() => toggleAccordion(13)}
                    >
                        <h5 className="text-lg font-semibold">
                            <FadeInSectionDownToUp>
                                Strain Analysis Lab.
                            </FadeInSectionDownToUp>
                        </h5>
                    </div>
                    <div
                        className={`transition-max-height duration-700 ease-in-out overflow-hidden ${expanded === 13 ? 'max-h-max rounded-xl shadow-lg opacity-100' : 'max-h-0 opacity-0'
                            }`}
                    >
                        <FadeInSectionDownToUp>
                            <div className="p-4 bg-white space-y-5 text-lg max-sm:text-sm px-10 max-sm:px-4">
                                <p>
                                    This is a complete system for the exploration of structural engineering topics that involve bending, torsion and combined loads. A variety of test specimens, an I-Beam, tube and wing section, are included.
                                </p>
                                <p>
                                    Simple and complex bending, shear and torsion are demonstrated in a progressive fashion using an I-beam, torsion tube, and production aircraft airfoil structure. A durable powder coated main support frame is made from structural steel tubing and mounted on rolling casters for mobility.
                                </p>
                                <p>
                                    It's is sized to fit through any standard interior door, making it convenient for both laboratory and in-classroom use.
                                </p>
                                <p>
                                    It is sized to fit through any standard interior door, making it convenient for both laboratory and in-classroom use.
                                </p>
                                <p>
                                    Test specimens are built of commercial-grade aluminum for long life. Industry standard linear and rosette foil strain gauges are strategically mounted on all test specimens. A load cell equipped jack-screw mechanism allows the operator to apply an incremental force to each test specimen.
                                </p>
                                <p>
                                    Strain gauge measurements feed a fully integrated and pre-calibrated National Instruments data acquisition system (full bridge completion).
                                </p>
                            </div>
                        </FadeInSectionDownToUp>
                    </div>
                </div>

                <div className="rounded-xl mx-20 max-sm:mx-5 border">
                    <div
                        className="px-10 max-sm:px-5 py-2 cursor-pointer rounded-xl bg-black text-white hover:text-[#ffa500]"
                        onClick={() => toggleAccordion(14)}
                    >
                        <h5 className="text-lg font-semibold">
                            <FadeInSectionDownToUp>
                                Virtual Instrument (VI) panel.
                            </FadeInSectionDownToUp>
                        </h5>
                    </div>
                    <div
                        className={`transition-max-height duration-700 ease-in-out overflow-hidden ${expanded === 14 ? 'max-h-max rounded-xl shadow-lg opacity-100' : 'max-h-0 opacity-0'
                            }`}
                    >
                        <FadeInSectionDownToUp>
                            <div className="p-4 bg-white space-y-5 text-lg max-sm:text-sm px-10 max-sm:px-4">
                                <p>
                                    The system is configured with extra bridge completion channels, which allows custom placement of additional strain gauges by student experimenters. Strain gauge measurement data is sent via USB connection to a LabVIEW generated virtual instrument (VI) panel on the provided laptop computer. This system displays real time data and has interactive operator control. Data can be recorded for future analysis. Software strain gauge calibration allows users to load an offset value to each strain gauge, and users can also automatically tare all strain gauges to a zero point for easy on-screen graphing and analysis. The VI source code is provided for student experimentation and modification and supports teaching efforts in areas of modern instrumentation and data acquisition methods.
                                </p>
                                <p>
                                    A multi-lesson laboratory procedure is provided to illustrate common usage of the lab. Solid models are also included to show the details of each test specimen, including the internal airfoil structure. These solid models and laboratory procedures are included on CD-ROM
                                </p>
                                <p>
                                    A unique feature of the lab is the fully instrumented aerospace component representing real-world hardware. Students are able to simulate aerodynamic and vehicle loads, measure the resulting strains and experimentally determine the stress condition.
                                </p>
                                <p>
                                    Students are able to focus on results rather than the implementation. An “open system” design allows unlimited future expansion. The included lab procedures provide step-by-step instructions on the basic operation of the lab.
                                </p>
                            </div>
                        </FadeInSectionDownToUp>
                    </div>
                </div>

                <div className="rounded-xl mx-20 max-sm:mx-5 border">
                    <div
                        className="px-10 max-sm:px-5 py-2 cursor-pointer rounded-xl bg-black text-white hover:text-[#ffa500]"
                        onClick={() => toggleAccordion(15)}
                    >
                        <h5 className="text-lg font-semibold">
                            <FadeInSectionDownToUp>
                                Jet Engine Cutaway Model.
                            </FadeInSectionDownToUp>
                        </h5>
                    </div>
                    <div
                        className={`transition-max-height duration-700 ease-in-out overflow-hidden ${expanded === 15 ? 'max-h-max rounded-xl shadow-lg opacity-100' : 'max-h-0 opacity-0'
                            }`}
                    >
                        <FadeInSectionDownToUp>
                            <div className="p-4 bg-white space-y-5 text-lg max-sm:text-sm px-10 max-sm:px-4">
                                <p>
                                    Light enough to carry- strong enough to travel.
                                </p>
                                <p>
                                    The model SR-30 engine is now available in cut-away form. Your students will be able to follow a detailed flow path- from compressor inlet to thrust nozzle exit! The rotating assembly is mounted on ball bearings, which allows for clear and tactile demonstration of rotating and stationary component interaction.
                                </p>
                                <p>
                                    The SR-30 is comprised of an axial flow turbine stage consisting of a super alloy cast nozzle vane guide ring and a high nickel content bladed disk (blisk) turbine wheel. It features a reverse flow annular combustion chamber and 6 high-pressure, return flow fuel atomizer nozzles. Detailed views of bearing components (including oil galley ways), rotating and stationary parts and combustion chamber geometry are also made clearly visible.
                                </p>
                                <p>
                                    The ATA approved flight case is lightweight yet extremely durable. This cut-away engine is designed to carry from classroom to classroom for years to come.
                                </p>
                                <p className='text-2xl font-semibold'>The ATA approved flight case is lightweight yet extremely durable.</p>
                                <p className='text-xl font-semibold'>Dimensions.</p>
                                <p>
                                    R-30&trade Cutaway: 14.5 x 11.0 x 8.0 inches (37 x 28 x 20 cm).
                                </p>
                                <p>
                                    Storage Case: 18.0 x 14.0 x 12.0 inches (46 x 36 x 31 cm).
                                </p>
                                <p className='text-xl font-semibold'>Weight.</p>
                                <p>
                                    SR-30™ Cutaway: 10 lbs (5 kg).
                                </p>
                                <p>
                                    Storage Case: 23 lbs (10 kg).
                                </p>
                                <p>
                                    Combined: 33 lbs (15 kg).
                                </p>
                                <p className='text-xl font-semibold'>All Actual and Representative Components.</p>
                                <p>
                                    Inlet Bell and Compressor Casing.
                                </p>
                                <p>
                                    Compressor Spinner / 2 Pole Generator for Engine RPM Sensing.
                                </p>
                                <p>
                                    Centrifugal Flow Compressor and Diffuser.
                                </p>
                                <p>
                                    Reverse Flow Annular Combustor with Laser Cut Air Flow Holes.
                                </p>
                                <p className='text-xl font-semibold'>Outer Combustor Mantle.</p>
                                <p>
                                    Metal Spun Combustor Transition Liner.
                                </p>
                                <p>
                                    Nozzle / Vane Guide Ring.
                                </p>
                                <p>
                                    Bladed Axial Flow Turbine Disk with Main Engine Shaft.
                                </p>
                                <p>
                                    Containment Ring.
                                </p>
                                <p className='text-xl font-semibold'>Thrust Cone and Thrust Nozzle.</p>
                                <p>
                                    Fuel Manifold, Fuel Nozzles, Fuel Control Unit and Engine Backplate.
                                </p>
                                <p>
                                    Compressor and Oil Seals and Main Bearings.
                                </p>
                                <p>
                                    Typical Air, Fuel and Oil Fittings with Internal Engine Fluid Galleyways.
                                </p>
                                <p className='text-xl font-semibold'>Storage Case.</p>
                                <p>
                                    Air Transport Association Approved Delicate Equipment Case.
                                </p>
                                <p>
                                    Fully Lined and Latchable for Safe, Secure Transportation.
                                </p>
                            </div>
                        </FadeInSectionDownToUp>
                    </div>
                </div>

                <div className="rounded-xl mx-20 max-sm:mx-5 border">
                    <div
                        className="px-10 max-sm:px-5 py-2 cursor-pointer rounded-xl bg-black text-white hover:text-[#ffa500]"
                        onClick={() => toggleAccordion(16)}
                    >
                        <h5 className="text-lg font-semibold">
                            <FadeInSectionDownToUp>
                                Fuel Atomization Verification System Lab.
                            </FadeInSectionDownToUp>
                        </h5>
                    </div>
                    <div
                        className={`transition-max-height duration-700 ease-in-out overflow-hidden ${expanded === 16 ? 'max-h-max rounded-xl shadow-lg opacity-100' : 'max-h-0 opacity-0'
                            }`}
                    >
                        <FadeInSectionDownToUp>
                            <div className="p-4 bg-white space-y-5 text-lg max-sm:text-sm px-10 max-sm:px-4">
                                <p>
                                    Portable Laboratory-Grade Fuel Atomization Verification System.
                                </p>
                                <p>
                                    Clear-View Spray Observation Chamber with Spray Verification Impingement Plates.
                                </p>
                                <p>
                                    Supplied with a Jet Engine Spray Manifold with Precision Variable Flow Control.
                                </p>
                                <p>
                                    Helps students gain an understanding of the Brayton Power Cycle as well as research fuel formulations for performance and emissions results.
                                </p>
                                <p>
                                    Instrumented with Fuel Delivery Pressure and Digital Fuel Flow Meters.
                                </p>
                                <p>
                                    Requires No Tools, Facilities Modifications or Special Support to Operate.
                                </p>
                                <p>
                                    Integrated Fuel Spray Vacuum Capture / Drain System.
                                </p>
                                <p>
                                    Suitable for University, Technical and Military Education, Training and Research.
                                </p>
                                <p>
                                    Supplied with Comprehensive Instructions.
                                </p>
                                <p>
                                    Industry Leading Warranty with Unsurpassed End-User Support.
                                </p>
                                <p>
                                    Designed and Manufactured in the India- Chennai.
                                </p>
                            </div>
                        </FadeInSectionDownToUp>
                    </div>
                </div>

                <div className="rounded-xl mx-20 max-sm:mx-5 border">
                    <div
                        className="px-10 max-sm:px-5 py-2 cursor-pointer rounded-xl bg-black text-white hover:text-[#ffa500]"
                        onClick={() => toggleAccordion(17)}
                    >
                        <h5 className="text-lg font-semibold">
                            <FadeInSectionDownToUp>
                                Aerodynamics Lab.
                            </FadeInSectionDownToUp>
                        </h5>
                    </div>
                    <div
                        className={`transition-max-height duration-700 ease-in-out overflow-hidden ${expanded === 17 ? 'max-h-max rounded-xl shadow-lg opacity-100' : 'max-h-0 opacity-0'
                            }`}
                    >
                        <FadeInSectionDownToUp>
                            <div className="p-4 bg-white space-y-5 text-lg max-sm:text-sm px-10 max-sm:px-4">
                                <p>
                                    The aerodynamics range is used for the teaching of a vast range of aerodynamic principles, from fundamentals through to advanced theories. Subsonic wind tunnels teach students the basics about lift, drag and pitching moments, plus the high-level topics such as the boundary layer and pressure distribution around models. Students can also perform wake investigations. Supersonic wind tunnels are for the more advanced teaching of aerodynamic engineering, with experiments that start with nozzle pressure distribution, on to analysis of Mach numbers, and the measurement and visualisation of pressure and shock waves using Schlieren apparatus. The comprehensive range means that a product can be found to suit every space, budget and complexity requirement.
                                </p>
                                <p>
                                    The wind tunnels available span a variety of sizes and experimentation capabilities, from bench top models for learning the basics to products requiring large laboratories for more detailed understanding of aerodynamics.
                                </p>
                            </div>
                        </FadeInSectionDownToUp>
                    </div>
                </div>

            </div>
        </div>
    )
}
