export const CountryCode = [
    { value: "", disabled: true, selected: true, hidden: true, text: "+" },
    { value: "+91" },
    { value: "+971" },
    { value: "+966" },
    { value: "+974" },
    { value: "+965" },
    { value: "+94" },
    { value: "+960" },
    { value: "+1" },
    { value: "+7" },
    { value: "+20" },
    { value: "+27" },
    { value: "+30" },
    { value: "+31" },
    { value: "+32" },
    { value: "+33" },
    { value: "+34" },
    { value: "+36" },
    { value: "+39" },
    { value: "+40" },
    { value: "+41" },
    { value: "+43" },
    { value: "+44" },
    { value: "+45" },
    { value: "+46" },
    { value: "+47" },
    { value: "+48" },
    { value: "+49" },
    { value: "+51" },
    { value: "+52" },
    { value: "+53" },
    { value: "+54" },
    { value: "+55" },
    { value: "+56" },
    { value: "+57" },
    { value: "+58" },
    { value: "+60" },
    { value: "+61" },
    { value: "+62" },
    { value: "+63" },
    { value: "+64" },
    { value: "+65" },
    { value: "+66" },
    { value: "+81" },
    { value: "+82" },
    { value: "+84" },
    { value: "+86" },
    { value: "+90" },
    { value: "+92" },
    { value: "+93" },
    { value: "+95" },
    { value: "+98" },
    { value: "+211" },
    { value: "+212" },
    { value: "+213" },
    { value: "+216" },
    { value: "+218" },
    { value: "+220" },
    { value: "+221" },
    { value: "+222" },
    { value: "+223" },
    { value: "+224" },
    { value: "+225" },
    { value: "+226" },
    { value: "+227" },
    { value: "+228" },
    { value: "+229" },
    { value: "+230" },
    { value: "+231" },
    { value: "+232" },
    { value: "+233" },
    { value: "+234" },
    { value: "+235" },
    { value: "+236" },
    { value: "+237" },
    { value: "+238" },
    { value: "+239" },
    { value: "+240" },
    { value: "+241" },
    { value: "+242" },
    { value: "+243" },
    { value: "+244" },
    { value: "+245" },
    { value: "+246" },
    { value: "+247" },
    { value: "+248" },
    { value: "+249" },
    { value: "+250" },
    { value: "+251" },
    { value: "+252" },
    { value: "+253" },
    { value: "+254" },
    { value: "+255" },
    { value: "+256" },
    { value: "+257" },
    { value: "+258" },
    { value: "+260" },
    { value: "+261" },
    { value: "+262" },
    { value: "+263" },
    { value: "+264" },
    { value: "+265" },
    { value: "+266" },
    { value: "+267" },
    { value: "+268" },
    { value: "+269" },
    { value: "+290" },
    { value: "+291" },
    { value: "+297" },
    { value: "+298" },
    { value: "+299" },
    { value: "+350" },
    { value: "+351" },
    { value: "+352" },
    { value: "+353" },
    { value: "+354" },
    { value: "+355" },
    { value: "+356" },
    { value: "+357" },
    { value: "+358" },
    { value: "+359" },
    { value: "+370" },
    { value: "+371" },
    { value: "+372" },
    { value: "+373" },
    { value: "+374" },
    { value: "+375" },
    { value: "+376" },
    { value: "+377" },
    { value: "+378" },
    { value: "+380" },
    { value: "+381" },
    { value: "+382" },
    { value: "+385" },
    { value: "+386" },
    { value: "+387" },
    { value: "+389" },
    { value: "+420" },
    { value: "+421" },
    { value: "+423" },
    { value: "+500" },
    { value: "+501" },
    { value: "+502" },
    { value: "+503" },
    { value: "+504" },
    { value: "+505" },
    { value: "+506" },
    { value: "+507" },
    { value: "+508" },
    { value: "+509" },
    { value: "+590" },
    { value: "+591" },
    { value: "+592" },
    { value: "+593" },
    { value: "+594" },
    { value: "+595" },
    { value: "+596" },
    { value: "+597" },
    { value: "+598" },
    { value: "+599" },
    { value: "+670" },
    { value: "+672" },
    { value: "+673" },
    { value: "+674" },
    { value: "+675" },
    { value: "+676" },
    { value: "+677" },
    { value: "+678" },
    { value: "+679" },
    { value: "+680" },
    { value: "+681" },
    { value: "+682" },
    { value: "+683" },
    { value: "+685" },
    { value: "+686" },
    { value: "+687" },
    { value: "+688" },
    { value: "+689" },
    { value: "+690" },
    { value: "+691" },
    { value: "+692" },
    { value: "+850" },
    { value: "+852" },
    { value: "+853" },
    { value: "+855" },
    { value: "+856" },
    { value: "+870" },
    { value: "+880" },
    { value: "+886" },
    { value: "+961" },
    { value: "+962" },
    { value: "+963" },
    { value: "+964" },
    { value: "+967" },
    { value: "+968" },
    { value: "+970" },
    { value: "+972" },
    { value: "+973" },
    { value: "+975" },
    { value: "+976" },
    { value: "+977" },
    { value: "+992" },
    { value: "+993" },
    { value: "+994" },
    { value: "+995" },
    { value: "+996" },
    { value: "+998" },
    { value: "+1242" },
    { value: "+1246" },
    { value: "+1264" },
    { value: "+1268" },
    { value: "+1284" },
    { value: "+1340" },
    { value: "+1345" },
    { value: "+1441" },
    { value: "+1473" },
    { value: "+1649" },
    { value: "+1664" },
    { value: "+1670" },
    { value: "+1671" },
    { value: "+1684" },
    { value: "+1721" },
    { value: "+1758" },
    { value: "+1767" },
    { value: "+1784" },
    { value: "+1787" },
    { value: "+1809" },
    { value: "+1829" },
    { value: "+1849" },
    { value: "+1868" },
    { value: "+1869" },
    { value: "+1876" },
    { value: "+1939" }
  ];