import React, { useState } from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function FixedWingElectricAircraft() {

    // State to manage the active tab
    const [activeTab, setActiveTab] = useState('concept');

    // Function to handle tab clicks
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <div>
            <div className='relative'>
                <div className='relative'>
                    <video autoPlay muted loop className="w-full h-full object-cover">
                        <source src="https://mh-cockpit.blr1.cdn.digitaloceanspaces.com/ELECTRIC%20AIRCRAFT%20%20VIDEO%20converted.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className='absolute top-1/3 xl:top-1/3 sm:bottom-0 max-sm:bottom-1 left-20 xl:left-20 sm:left-5 max-sm:left-10 max-sm:right-14 sm:right-1/4'>
                    <FadeInSectionDownToUp>
                        <h1 className='text-4xl xl:text-4xl lg:text-6xl sm:text-3xl max-sm:text-xl font-bold py-10 xl:py-10 max-sm:py-2 text-white'>
                            Fixed wing<br />
                            Electric Aircraft.
                        </h1>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className='pl-20 text-4xl py-40'>
                <FadeInSectionDownToUp>
                    <p>
                        Transforming<br />
                        Ideas into <span className='font-bold text-gray-500'>Reality.</span>
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-5 px-20 xl:px-20 sm:px-20 max-sm:px-10 text-justify text-xl'>
                <FadeInSectionDownToUp>
                    <p>
                        Fixed-wing electric aircraft are cutting-edge planes powered by electric motors, ditching traditional combustion engines. These aircraft rely on electric propulsion systems, often fueled by advanced batteries or hybrid setups. They boast eco-friendliness, emitting zero pollutants during flight, and offering quieter operations While they reduce maintenance costs due to fewer moving parts, their main limitation lies in battery energy density, which limits their range compared to conventional planes. Ongoing research targets enhancing battery efficiency and exploring alternative power sources to extend their capabilities. Despite limitations, these aircraft find utility in short-haul flights, aerial surveys, and training missions, showcasing their potential in various aviation sectors.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className="my-4 border-gray-200 dark:border-gray-700 flex justify-center">
                <ul className="flex flex-wrap -mb-px text-3xl xl:text-3xl sm:text-2xl max-sm:text-lg font-medium text-center gap-28 xl:gap-28 sm:gap-5 max-sm:gap-3" role="tablist">
                    <li className="me-2" role="presentation">
                        <button
                            className={`inline-block border-b-2 rounded-t-lg ${activeTab === 'concept' ? 'border-black' : 'border-transparent'}`}
                            onClick={() => handleTabClick('concept')}
                            type="button"
                            role="tab"
                            aria-controls="concept"
                            aria-selected={activeTab === 'concept'}
                        >
                            Concept
                        </button>
                    </li>
                    <li className="me-2" role="presentation">
                        <button
                            className={`inline-block border-b-2 rounded-t-lg ${activeTab === 'safe' ? 'border-black' : 'border-transparent'}`}
                            onClick={() => handleTabClick('safe')}
                            type="button"
                            role="tab"
                            aria-controls="safe"
                            aria-selected={activeTab === 'safe'}
                        >
                            Safe
                        </button>
                    </li>
                    <li className="me-2" role="presentation">
                        <button
                            className={`inline-block border-b-2 rounded-t-lg ${activeTab === 'efficient' ? 'border-black' : 'border-transparent'}`}
                            onClick={() => handleTabClick('efficient')}
                            type="button"
                            role="tab"
                            aria-controls="efficient"
                            aria-selected={activeTab === 'efficient'}
                        >
                            Efficient
                        </button>
                    </li>
                    <li role="presentation">
                        <button
                            className={`inline-block border-b-2 rounded-t-lg ${activeTab === 'reliable' ? 'border-black' : 'border-transparent'}`}
                            onClick={() => handleTabClick('reliable')}
                            type="button"
                            role="tab"
                            aria-controls="reliable"
                            aria-selected={activeTab === 'reliable'}
                        >
                            Reliable
                        </button>
                    </li>
                    <li role="presentation">
                        <button
                            className={`inline-block border-b-2 rounded-t-lg ${activeTab === 'zero-emission' ? 'border-black' : 'border-transparent'}`}
                            onClick={() => handleTabClick('zero-emission')}
                            type="button"
                            role="tab"
                            aria-controls="zero-emission"
                            aria-selected={activeTab === 'zero-emission'}
                        >
                            Zero Emission
                        </button>
                    </li>
                </ul>
            </div>
            <div>
                {activeTab === 'concept' && (
                    <div className="" role="tabpanel" aria-labelledby="concept-tab">
                        <div className='py-20 xl:py-20 sm:py-40'>
                            <div className='py-5 px-20 xl:px-20 sm:px-20 max-sm:px-10 text-justify text-xl'>
                                <FadeInSectionDownToUp>
                                    <p>
                                        Our fixed-wing electric aircraft represent a revolutionary leap in aviation technology. Designed with cutting-edge aerodynamics and advanced propulsion systems, our aircraft combine sleek, aerodynamic designs with powerful electric engines to deliver a superior flying experience. We envision a future where flying is as clean and efficient as it is exhilarating. Our concept pushes the boundaries of traditional aviation, integrating state-of-the-art technology to create a new standard in air travel.
                                    </p>
                                </FadeInSectionDownToUp>
                            </div>
                        </div>
                    </div>
                )}
                {activeTab === 'safe' && (
                    <div className="" role="tabpanel" aria-labelledby="safe-tab">
                        <div className='py-20 xl:py-20 sm:py-40'>
                            <div className='py-5 px-20 xl:px-20 sm:px-20 max-sm:px-10 text-justify text-xl'>
                                <FadeInSectionDownToUp>
                                    <p>
                                        Safety is our top priority. Our fixed-wing electric aircraft are built with the highest safety standards, featuring redundant systems, advanced avionics, and rigorous testing protocols. Each aircraft undergoes extensive safety checks and simulations to ensure optimal performance under all conditions. Our commitment to safety is reflected in every aspect of our design, from the robust structural integrity to the intuitive, user-friendly controls. Fly with confidence, knowing that our aircraft are engineered to keep you safe and secure.
                                    </p>
                                </FadeInSectionDownToUp>
                            </div>
                        </div>
                    </div>
                )}
                {activeTab === 'efficient' && (
                    <div className="" role="tabpanel" aria-labelledby="efficient-tab">
                        <div className='py-20 xl:py-20 sm:py-40'>
                            <div className='py-5 px-20 xl:px-20 sm:px-20 max-sm:px-10 text-justify text-xl'>
                                <FadeInSectionDownToUp>
                                    <p>
                                        Efficiency is at the core of our design philosophy. Our fixed-wing electric aircraft leverage cutting-edge battery technology and aerodynamic advancements to deliver exceptional performance with minimal energy consumption. With lower operational costs compared to traditional fossil-fuel aircraft, our electric planes not only reduce your expenses but also optimize your flying experience. Experience the perfect balance of power and efficiency, allowing you to go further, faster, and with greater economy.
                                    </p>
                                </FadeInSectionDownToUp>
                            </div>
                        </div>
                    </div>
                )}
                {activeTab === 'reliable' && (
                    <div className="" role="tabpanel" aria-labelledby="reliable-tab">
                        <div className='py-20 xl:py-20 sm:py-40'>
                            <div className='py-5 px-20 xl:px-20 sm:px-20 max-sm:px-10 text-justify text-xl'>
                                <FadeInSectionDownToUp>
                                    <p>
                                        Reliability is essential for every flight, and our fixed-wing electric aircraft are engineered to meet the highest standards of dependability. Built with robust materials and tested rigorously in various conditions, our aircraft are designed to perform consistently, offering you peace of mind on every journey. From takeoff to landing, our advanced systems and quality components ensure that you can rely on our aircraft for a smooth and trouble-free flight experience.
                                    </p>
                                </FadeInSectionDownToUp>
                            </div>
                        </div>
                    </div>
                )}
                {activeTab === 'zero-emission' && (
                    <div className="" role="tabpanel" aria-labelledby="zero-emission-tab">
                        <div className='py-20 xl:py-20 sm:py-40'>
                            <div className='py-5 px-20 xl:px-20 sm:px-20 max-sm:px-10 text-justify text-xl'>
                                <FadeInSectionDownToUp>
                                    <p>
                                        Our fixed-wing electric aircraft are at the forefront of zero-emission aviation. By harnessing the power of electric propulsion, we eliminate harmful emissions, contributing to a cleaner, healthier environment. Our commitment to sustainability is reflected in our innovative approach, which not only reduces carbon footprints but also promotes greener skies. Join us in advancing the future of aviation with an aircraft that is as kind to the planet as it is exhilarating to fly.
                                    </p>
                                </FadeInSectionDownToUp>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
