import React, { useEffect, useRef } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { GrNext, GrPrevious } from 'react-icons/gr';
import { useLocation } from 'react-router-dom';
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';
import { BlogsRow1, BlogsRow2 } from '../../Components/Blogs/BlogsData';

// Define the button components
const Button = ({ onClick, position, children }) => (
    <button
        className={`absolute top-1/2 transform -translate-y-1/2 bg-gray-700 bg-opacity-85 text-white p-2 rounded-full ${position === 'next' ? 'right-4' : 'left-4'}`}
        onClick={onClick}
    >
        {children}
    </button>
);

const CarouselSection = ({ items, title }) => {
    const carouselRef = useRef(null);

    const goToNext = () => {
        if (carouselRef.current) {
            carouselRef.current.slideNext();
        }
    };

    const goToPrev = () => {
        if (carouselRef.current) {
            carouselRef.current.slidePrev();
        }
    };

    return (
        <div className="relative">
            <AliceCarousel
                ref={carouselRef}
                autoPlay
                disableButtonsControls
                disableDotsControls
                autoPlayInterval={2000}
                buttonsDisabled={true}
                dotsDisabled={true}
                infinite={true}
                swipeable={true}
                draggable={true}
                responsive={{
                    0: { items: 1 },
                    568: { items: 3 },
                    1024: { items: 4 },
                }}
            >
                {items.map((item, index) => (
                    <a href={item.link} key={index} className='bg-opacity-25 shadow-lg shadow-gray-300 m-10 flex flex-col items-center rounded-2xl hover:scale-105 transition-transform duration-1000 ease-in-out h-[300px]' rel="noopener noreferrer">
                        <img src={item.src} alt={item.alt} className='w-full rounded-t-2xl' />
                        <p className='px-5 py-10 text-center font-semibold text-md'>{item.title}</p>
                    </a>
                ))}
            </AliceCarousel>
            <Button onClick={goToNext} position="next"><GrNext /></Button>
            <Button onClick={goToPrev} position="prev"><GrPrevious /></Button>
        </div>
    );
};

const Blogs = () => {

    const location = useLocation();

    useEffect(() => {
        const scrollToElement = (hash) => {
            const element = document.getElementById(hash.substring(1)); // remove the # from hash
            if (element) {
                element.scrollIntoView({ behavior: 'auto' });
            }
        };

        const hash = location.hash;
        if (hash === '#pilot-training' || hash === '#degree-programs' || hash === '#diploma-programs' || hash === '#mh-intellect') {
            scrollToElement(hash);
        }
    }, [location]);

    return (
        <div className="relative scroll-smooth">
            <div className='text-center'>
                <FadeInSectionDownToUp>
                    <h1 className='font-semibold text-5xl tracking-tighter py-10 max-sm:py-10'>Blogs</h1>
                </FadeInSectionDownToUp>
            </div>
            <CarouselSection items={BlogsRow1} />
            <CarouselSection items={BlogsRow2} />
        </div>
    );
};

export default Blogs;
