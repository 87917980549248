import React from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function DGCACN() {
    return (
        <div>
            <div className="relative text-justify">
                <div className="inset-0 overflow-hidden">
                    <img
                        className='w-full h-full object-cover animate-zoomOut'
                        src="https://ik.imagekit.io/mhcockpit1/DGCA-LC.webp"
                        alt="DGCA - License conversion"
                    />
                </div>
                <div className="relative py-10">
                    <div className='pl-40 max-sm:pl-10 text-4xl max-sm:text-2xl font-semibold tracking-tighter py-10'>
                        <FadeInSectionDownToUp>
                            <h1>DGCA Computer Number.</h1>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 text-xl py-5'>
                        <FadeInSectionDownToUp>
                            <p>
                                The DGCA Computer Number, also known as the DGCA File Number or Computer Number, is a unique identification code assigned by the Directorate General of Civil Aviation (DGCA) in India to individuals, aircraft, or specific documents related to civil aviation.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 text-xl py-5'>
                        <FadeInSectionDownToUp>
                            <p>
                                This alphanumeric code serves as a reference or identifier within the DGCA's systems and records. It helps in tracking and managing various aspects of aviation, including licenses, certifications, aircraft registrations, and other related documentation. The DGCA Computer Number plays a crucial role in maintaining accurate and organized records within the aviation regulatory framework in India.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Hardware / Software Requirement
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 text-xl py-5'>
                        <FadeInSectionDownToUp>
                            <p>
                                The minimum system requirements for accessing the Web Based Software Application for FLIGHT CREW Licence at <a href='http://www.pariksha.dgca.gov.in/home' target='blank' className='text-blue-600 underline underline-offset-1'>http://www.pariksha.dgca.gov.in/home</a> website are
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Google Chrome version 12 and above.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Internet Explorer version 12 and above.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Mozilla Firefox.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Internet Connection.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Application Requirement
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Individual E-mail ID and Mobile No of the candidate is an essential component of the system. All important information such as Computer Number, Roll Number, Examination Schedules, and Application Status etc. will be communicated to the candidate on their e-mail only.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        One e-mail ID is confined to one candidate only for Registration.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        The Login ID and the Password is unique to each applicant and applicant is responsible to protect it. DGCA will not be held responsible for misuse of the login credentials.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Password Policy: Minimum Password length should be 8 characters. It must contain at least one upper case letter (A-Z), one lower case letter (a-z), one special character (!@#$%) and one numeric value [ 0-9 ].
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Candidate needs to keep ready scanned/ soft copy of the following :
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className="text-lg px-48 max-sm:px-16 mb-5 text-justify py-1">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Applicant Photograph in JPG, JPEG format.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Applicant Signature in JPG, JPEG format.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 max-sm:px-10 text-xl py-5'>
                        <FadeInSectionDownToUp>
                            <p>
                                <b>NOTE:</b> Uploading of Photograph and Signature is a onetime exercise. It is therefore advised that only latest color Photograph is uploaded. Once the Application is submitted successfully by an applicant, she/he has no provision to replace it. If an applicant desires to change her/his photograph due to any reason, she/ he may contact to Helpdesk / CEO, DGCA for the same.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-10 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Following are the maximum size of the document which may be uploaded.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='flex justify-center items-center px-10'>
                        <div className='grid grid-cols-2 max-sm:block'>
                            <div>
                                <img src='https://ik.imagekit.io/mhcockpit1/dgcapcn-img.png' alt='DGCA Computer Number' />
                            </div>
                            <div>
                                <div className='px-5 max-sm:px-3 py-1 text-2xl max-sm:text-lg font-semibold'>
                                    <FadeInSectionDownToUp>
                                        <p>
                                            Specification of the Images.
                                        </p>
                                    </FadeInSectionDownToUp>
                                </div>
                                <div className="text-lg px-5 max-sm:px-3 mb-5 text-justify py-1">
                                    <ul className='space-y-3'>
                                        <FadeInSectionDownToUp>
                                            <div className='flex'>
                                                <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                                    The Size of the colour photograph has to be 45mm height X 35mm width taken on a white background without borders.
                                                </li>
                                            </div>
                                        </FadeInSectionDownToUp>
                                        <FadeInSectionDownToUp>
                                            <div className='flex'>
                                                <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                                    The face on the photograph must be of 35mm height and 25mm width (face size should be 70% of the Photograph). The photograph must be of MATT finish.
                                                </li>
                                            </div>
                                        </FadeInSectionDownToUp>
                                        <FadeInSectionDownToUp>
                                            <div className='flex'>
                                                <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                                    The size of the scanned colour photo image should not be more than 70kb. The size of the scanned signature image is 20mm height X 45mm width on a white background without border.
                                                </li>
                                            </div>
                                        </FadeInSectionDownToUp>
                                        <FadeInSectionDownToUp>
                                            <div className='flex'>
                                                <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                                    The size of the scanned Signature image should not be more than 20kb. The photograph and Signature must be in JPEG/JPG Format only.
                                                </li>
                                            </div>
                                        </FadeInSectionDownToUp>
                                    </ul>
                                </div>
                                <div className='px-5 max-sm:px-3 py-1 text-2xl max-sm:text-lg font-semibold'>
                                    <FadeInSectionDownToUp>
                                        <p>
                                            Name
                                        </p>
                                    </FadeInSectionDownToUp>
                                </div>
                                <div className='px-5 max-sm:px-3 text-lg py-1'>
                                    <FadeInSectionDownToUp>
                                        <p>
                                            The name will be accepted as mentioned in 10th Std. Mark Sheet Matriculation certificate. If the name appears different and / or has been changed subsequent to acquiring the basic qualification or equivalent qualification / due to marriage / any other reason, the self-attested copy(s) of amended mark sheet & pass certificate / Gazette Notification issued by the state or central government for name change and / or the recent passport issued with the current name shall be accepted against proof of name.
                                        </p>
                                    </FadeInSectionDownToUp>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Date of Birth Certificate.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 text-xl py-5'>
                        <FadeInSectionDownToUp>
                            <p>
                                10th Std. Certificate & Mark sheet of a recognized Board (recognized by Council of Boards of School education, State/Central Govt education board) or its equivalent or the Birth Certificate issued by a Municipal Corporation / Committee shall be the documents acceptable as proof of age.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Educational Qualification.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        10th Std. Certificate & Mark List, Matriculation certificate (for PPL, CPL, ATPL, FDEG, FE and FN Examination) from a recognized Board (recognized by Council of Boards of Secondary Education, State Govt. Education Board) or its equivalent.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        10+2 Standard Mark List & Pass certificate (for CPL, ATPL, FDEG, FE and FN category) as a proof of having passed 10+2 with Mathematics & Physics subjects from a recognized board/University recognized by Council of Boards of School Education (COBSE), State Govt. Education Board or its equivalent examination.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        In the case of applicant not having 10+2 with Mathematics & Physics and like to use the provision of Rule 47A of Aircraft Rules 1937, as an evidence for having flown before 1st Jan 1994, the attested copies of first page of his Log Book where his personal particulars are mentioned and the page where the Date of First Flight is recorded.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                NOTE
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 text-xl py-5'>
                        <FadeInSectionDownToUp>
                            <p>
                                The equivalent qualification acquired by the applicant from the board recognized by CBSE, State Govt. Education Board and in the case of Technical education like Diploma, it should be recognized either by AICTE or by the respective Directorate of Technical Education of State Govt. shall be considered acceptable. With respect to acceptance of pass status, the applicant has to be declared passed including the Physics & Mathematics subjects (in 10+2 STD) and pass awarded by the board with fail status of these subjects shall not be considered acceptable.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 text-xl py-5'>
                        <FadeInSectionDownToUp>
                            <p>
                                Verification Certificate of 10th and 10+2 Standard (with Mathematics & Physics) mark lists obtained from the Board / University concerned, either they are under the education system of
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Indian School / Board / University situated in India or Abroad.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        International School / Board / University situated in India or Abroad.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 max-sm:px-10 text-xl py-5'>
                        <FadeInSectionDownToUp>
                            <p>
                                If the verification has been obtained by the training institutions and / or verification addressed to the training institutions, the applicant should send an attested copy of (attested by the Chief Flying Instructor) the Verification along with his application for allotment of Computer Number. In case the candidate personally collect the verification of education qualification certificate, he should send an attested copy of verification of education qualification certificate along with his application for allotment of computer number to Central Examination Organization, O/o DGCA, East Block-III, Level-III R K Puram, New Delhi-110066.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 text-xl py-5'>
                        <FadeInSectionDownToUp>
                            <p>
                                In case the Board / University intends to forward the verification certificate to CEO directly, the candidate has to submit/upload the evidence, such as application acknowledgement received from the board and / or the challan copy for having submitted the fee to the Board / University along with the application for allotment of Computer Number & in such cases, the application will be processed only after receipt of the Verification Certificate from the Board / University concerned by CEO.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                NOTE
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        In the case of applicants who earned the qualification from foreign university, education system and / or studied in foreign country, the verification certificates for such qualifications are to be forwarded by the concerned board (under whom the institution is awarding pass certificates / mark sheets) to Central Examination Organization, O/o DGCA by e-mail at help.pariksha.dgca@gov.in. In this regard, the applicant may request and submit necessary application to the Board / University concerned as required by them.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        If Verification Certificate of 10th and 10+2 Standard (with Mathematics & Physics) mark lists obtained from the Board / University concerned addressed to Flying Training Institutions, the Computer Number shall be issued on the basis of the attested (attested by the Chief Flying Instructor of the approved flying training institute) copy of the board verification.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        In the recent past certain Boards / Institutions / Universities have made the qualification verifications available in their respective web site. If such verifications are accessible in web site, DGCA will have no objection in taking such verifications for acceptance of the Computer Number application for further processing. The respective link and other necessary credentials for the said verification should be mentioned in the application. If such verifications are not made available directly online and if any registration process mandated by such boards, DGCA will not be in a position to entertain the applications submitted without proper verification obtained by the applicant as mentioned above in Para – “c” and Note (i) to (iii).
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 max-sm:px-10 text-xl py-5'>
                        <FadeInSectionDownToUp>
                            <p>
                                In case the applicant has acquired the Basic Qualification (10th, 10+2 or equivalent) from other than Council of Boards of Secondary Education, State/Central Govt. Education Board i.e. any International School / Board / University situated in India or abroad, in addition to the verification certificate mentioned as above at Para-c, enclose self-attested copy of equivalent certificate issued by Association of Indian Universities (AIU) House, 16, Kotla Marg, New Delhi – 110022.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 text-xl py-5'>
                        <FadeInSectionDownToUp>
                            <p>
                                However, at the time of applying for issue of Flight Crew License, the applicant has to submit the original to licensing authority as per the requirement of Directorate of Training & Licensing.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 text-xl py-5'>
                        <FadeInSectionDownToUp>
                            <p>
                                <b>Proof of Address:</b> Self attested copies of one of the following.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Aadhaar Card.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Passport.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Voter ID.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Ration Card.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        License issued by DGCA.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Proof of address issued by State/Central Government.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 max-sm:px-10 text-xl py-5'>
                        <FadeInSectionDownToUp>
                            <p>
                                The applications meeting all the above requirement shall only be processed for issue of Computer Number.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Self-Attested copy of an identification such as.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        SPL issued by the CFI of approved Flying Training Institute.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        FRTOL / PPL / CPL as the case may be issued by DGCA (India).
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Passport.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Airport entry card – issued by BCAS.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        UID / AADHAR.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Voter ID.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Defence Identity Card in the case of Defence Personnel.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Dependent Card issued by Defence Organization.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Ration Card if, candidate’s Photograph is printed therein.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Pass certificate / Mark List of 10th, 10+2, Diploma, Degree issued by recognized Board / University wherein the photograph of the candidate printed and clearly visible.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Central / State Govt. Health Cards with photograph of the candidate printed Therein.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Eligibility criteria for which documents required to be submitted by the applicant for obtaining Computer Number are as follows.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 text-xl py-5'>
                        <FadeInSectionDownToUp>
                            <p>
                                Copy of Passport / Security Clearance in case of foreign national. In case the applicant is a citizen of other than India / holder of Overseas Citizen of India (OCI) status / Persons of Indian Origin (PIO) status, they are required to have copy of passport and be cleared from security point of view from authorities concerned before allotment of Computer Number.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 text-xl py-5'>
                        <FadeInSectionDownToUp>
                            <p>
                                For obtaining the Security Clearance, an individual or the sponsoring organization may apply to CEO, DGCA in the prescribed format given in Annexure-A to this User Manual. Duly filled in application should be forwarded in quintuplicate (5 sets of application & documents) to The Director of Airworthiness, Central Examination Organization, Office of The Director General of Civil Aviation, East Block – III, Level III, R K Puram, New Delhi – 110066 along with all necessary supporting documents as mentioned in the form by Speed / Registered Post for necessary action.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 text-xl py-5'>
                        <FadeInSectionDownToUp>
                            <p>
                                The application for Security Clearance will be processed only after satisfying with the application and documents submitted for Allotment of Computer Number. Queries if any, the candidates may contact CEO at help.pariksha.dgca@gov.in for clarification and initiating the process of Security Clearance.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                PPL/CPL
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Printout of the ONLINE submitted application signed and duly completed in all respect.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Passed Class Ten/ Ten plus Two or an equivalent examination with Physics and Mathematics, from a recognized Board/University.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        In case of Candidate desire to apply Technical Paper and type aircraft is more than 5700Kgs. MTOW / twin engine Helicopters, Certificate for having successfully completed the ground training classes on technical specific / performance on the type aircraft from the DGCA approved training institute. It is also required to enclose an evidence to support the approval status of the training/institute to that effect in accordance with CAR, Section-7, Series-B, Part-I.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Hard copy of the application along with its enclosures (self-attested) must reach CEO within 10 days of submission of online application.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 max-sm:px-10 text-xl py-5'>
                        <FadeInSectionDownToUp>
                            <p>
                                <b>NOTE :</b> Only 10th std. education qualification is required for PPL candidates.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                ATPL.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Printout of the ONLINE submitted application signed and duly completed in all respect.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Passed Class Ten / Ten plus Two or an equivalent examination with Physics and Mathematics, from a recognized Board/University.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Proof of having Indian CPL. In the case of Defence Personnel in lieu of CPL, evidence for having completed 500Hrs flying experience of which 200 Hrs. should be as Pilot-in-Command.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        In case of Candidate desire to apply Technical Paper and type aircraft is more than 5700Kgs. MTOW / twin engine Helicopters, Certificate for having successfully completed the ground training classes on technical specific / performance on the type aircraft from the DGCA approved training institute. It is also required to enclose an evidence to support the approval status of the training/institute to that effect in accordance with CAR, Section-7, Series-B, Part-I.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Hard copy of the application along with its enclosures (self-attested) must reach CEO within 10 days of submission of online application.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Conversion of Pilots License (CPL/ATPL) issued by Foreign Authority.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Printout of the ONLINE submitted application signed and duly completed in all respect.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Copy of Foreign FLIGHT CREW License issued by ICAO Contracting State for the respective category.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Hard copy of the application along with its enclosures (self-attested) must reach CEO within 10 days of submission of online application.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Flight Navigation (FN).
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Printout of the ONLINE submitted application signed and duly completed in all respect.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Passed Class Ten/ Ten plus Two or an equivalent examination with Physics and Mathematics, from a recognized Board/University.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Hard copy of the application along with its enclosures (self attested) must reach CEO within 10 days of submission of online application.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Flight Dispatcher / Flight Engineer (FDEG/FE).
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Printout of the ONLINE submitted application signed and duly completed in all respect.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Passed Class Ten/ Ten plus Two or an equivalent examination with Physics and Mathematics, from a recognized Board/University.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Having completed Training Certificate (Course completion Certificate) on the type of aircraft which has undergone.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Hard copy of the application along with its enclosures (self attested) must reach CEO within 10 days of submission of online application.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 max-sm:px-10 py-5 text-2xl max-sm:text-lg font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                FATA.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Printout of the ONLINE submitted application signed and duly completed in all respect.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        FATA Candidates are required to submit FATA Authorization Letter issued by DGCA.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Copy of Passport, Visa and Pilot License details.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Hard copy of the application along with its enclosures (self-attested) must reach CEO within 10 days of submission of online application.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 max-sm:px-10 text-xl py-5'>
                        <FadeInSectionDownToUp>
                            <p>
                                <b>NOTE :</b> Education qualification is not required to be submitted for FATA candidates.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
            </div>
        </div>
    )
}
