import React, { useEffect, useRef } from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp';
import { useNavigate } from 'react-router-dom';

export default function PgDiplomaAME() {

    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;

        if (video) {
            const playPromise = video.play();

            if (playPromise !== undefined) {
                playPromise
                    .then(_ => {
                        // Autoplay started
                        console.log('Autoplay started');
                    })
                    .catch(error => {
                        // Autoplay was prevented, unmute and play
                        video.muted = true; // Unmute the video
                        video.play().catch(error => {
                            console.error('Autoplay was blocked:', error);
                        });
                    });
            }
        }
    }, []);

    const navigate = useNavigate();

    return (
        <div>
            <div>
                <video autoPlay loop playsInline className='w-full'>
                    <source src={"https://mh-cockpit.blr1.cdn.digitaloceanspaces.com/PG%20Diploma%20in%20AME.mp4"} type='video/mp4' />
                </video>
            </div>
            <div className='px-20 pb-20 xl:px-20 lg:px-20 max-sm:px-5 sm:px-5'>
                <div className='w-full bg-white -mt-20 xl:-mt-56 max-sm:-mt-10 py-20 xl:py-20 max-sm:py-10 sm:py-10 px-20 xl:px-20 max-sm:px-5 sm:px-5 rounded-3xl shadow-2xl shadow-gray-700 relative'>
                    <FadeInSectionDownToUp>
                        <h1 className='text-5xl max-sm:text-3xl text-center font-semibold'>PG Diploma in <span className='text-[#6b8d36]'>Aircraft Maintenance Engineering.</span></h1>
                    </FadeInSectionDownToUp>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='text-lg text-justify pt-20 max-sm:pt-10'>
                                A Postgraduate Diploma in Aircraft Maintenance Engineering (AME) is a specialized program designed to provide students with the knowledge and skills necessary to pursue a career in aircraft maintenance. This diploma covers topics such as aircraft systems, avionics, propulsion, airframe structures, and regulations governing aircraft maintenance. Students learn through a combination of theoretical coursework, practical training, and hands-on experience, preparing them for roles as licensed aircraft maintenance engineers responsible for inspecting, repairing, and maintaining aircraft to ensure their safe operation.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='flex xl:flex-row lg:flex-row max-sm:flex-col sm:flex-col pt-14 gap-2'>
                        <div className='bg-black text-white w-1/4 lg:w-1/2 xl:w-1/4 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-[#6b8d36] font-bold text-xl pb-3'>Duration</p>
                                <p className='text-2xl'>1 Year</p>
                            </FadeInSectionDownToUp>
                        </div>
                        <div className='bg-black text-white w-3/4 lg:w-3/4 xl:w-3/4 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 max-sm:px-5 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-[#6b8d36] font-bold text-xl pb-3'>Eligibility</p>
                                <ul>
                                    <div className='flex'>
                                        <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>Graduate in any engineering degree or science degree (or) equivalent from a recognised board.</li>
                                    </div>
                                </ul>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                    <div className='bg-black text-white flex justify-start items-center py-7 px-10 max-sm:px-5 rounded-3xl mt-2 pl-20 max-sm:pl-5'>
                        <FadeInSectionDownToUp>
                            <p className='text-[#6b8d36] font-bold text-xl pb-3'>Practical Exposure</p>
                            <ul>
                                <div className='flex'>
                                    <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>Hangar Training.</li>
                                </div>
                                <div className='flex'>
                                    <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>Engine Overhauls.</li>
                                </div>
                                <div className='flex'>
                                    <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>Avionics Workshops.</li>
                                </div>
                                <div className='flex'>
                                    <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>Structural Repairs.</li>
                                </div>
                                <div className='flex'>
                                    <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>Aircraft Systems.</li>
                                </div>
                                <div className='flex'>
                                    <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>Regulatory Compliance.</li>
                                </div>
                            </ul>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='flex justify-center items-center py-10'>
                        <FadeInSectionDownToUp>
                            <a href='/private-pilot-license' className='text-[#6b8d36] underline text-3xl'>Course Content</a>
                        </FadeInSectionDownToUp>
                    </div>
                    <FadeInSectionDownToUp>
                        <div className='grid grid-cols-2 gap-2 justify-center items-center px-96 max-sm:px-0 sm:px-40'>
                            <button onClick={() => navigate('/contact')} className='bg-[#6b8d36] text-white py-2 rounded-xl mx-2'>For Queries</button>
                            <button onClick={() => navigate('/contact')} className='bg-[#6b8d36] text-white py-2 rounded-xl mx-2'>Apply Now</button>
                        </div>
                    </FadeInSectionDownToUp>
                </div>
            </div>
        </div>
    )
}
