// import React from 'react'
// import AliceCarousel from 'react-alice-carousel'

// export default function TestimonialsMobile() {

//     const TestimonialStudents = []

//     return (
//         <div>
//             <AliceCarousel
//                 autoPlay
//                 disableButtonsControls
//                 disableDotsControls
//                 autoPlayInterval={2000}
//                 buttonsDisabled={false}
//                 dotsDisabled={true}
//                 infinite={true}
//                 swipeable={true}
//                 draggable={true}
//                 responsive={{
//                     0: { items: 1 },
//                     568: { items: 2 },
//                     1024: { items: 3 },
//                 }}
//             >

//                 <div className='bg-blue-500 bg-opacity-25 shadow-lg shadow-gray-300 px20 py-1 m-10 h-96 flex flex-col items-center rounded-md'>
//                     <div>
//                         <img src='https://ik.imagekit.io/mhcockpit1/Testimonials/Diya%20Roy.png' alt='Diya Roy' className='w-32' />
//                     </div>
//                     <div>
//                         <p className='text-sm text-gray-500 px-5 py-5 text-justify'>Studying under MH Cockpit has been really valuable as i get to learn a lot of things other than cpl core subjects. Starting from the experienced instructors who has worked in the aviation industry to the quality of knowledge it provides along with fun and spark, MH cockpit is helping me in every step to achieve my goal.</p>
//                     </div>
//                 </div>

//                 <div className='bg-blue-500 bg-opacity-25 shadow-lg shadow-gray-300 px20 py-1 m-10 h-96 flex flex-col items-center rounded-md'>
//                     <div>
//                         <img src='https://ik.imagekit.io/mhcockpit1/Testimonials/NAWAL.png' alt='Nawam' className='w-32' />
//                     </div>
//                     <div>
//                         <p className='text-sm text-gray-500 px-5 py-5 text-justify'>I’ve learnt a lot during my time here in MH Cockpit not just academically but also in all the other aspects of career development. The instructors are very approachable and are available to answer any questions at any time.</p>
//                     </div>
//                 </div>

//                 <div className='bg-blue-500 bg-opacity-25 shadow-lg shadow-gray-300 px20 py-1 m-10 h-96 flex flex-col items-center rounded-md'>
//                     <div>
//                         <img src='https://ik.imagekit.io/mhcockpit1/Testimonials/Soumyanetra.png' alt='Soumyanetra' className='w-32' />
//                     </div>
//                     <div>
//                         <p className='text-sm text-gray-500 px-5 py-5 text-justify'>Being a student who had limited exposure to the aviation industry, I feel glad that I choose MH Cockpit because the way they'll let you explore the industry is the best thing one could ever ask for.</p>
//                     </div>
//                 </div>

//                 <div className='bg-blue-500 bg-opacity-25 shadow-lg shadow-gray-300 px20 py-1 m-10 h-96 flex flex-col items-center rounded-md'>
//                     <div>
//                         <img src='https://ik.imagekit.io/mhcockpit1/Testimonials/ANUJ.png' alt='Anuj' className='w-32' />
//                     </div>
//                     <div>
//                         <p className='text-sm text-gray-500 px-5 py-5 text-justify'>MH Cockpit is one of the most experienced and highly qualified Instructors. The course curriculum is handcrafted around the needs and future desires of the Aviation Industry.</p>
//                     </div>
//                 </div>

//                 <div className='bg-blue-500 bg-opacity-25 shadow-lg shadow-gray-300 px20 py-1 m-10 h-96 flex flex-col items-center rounded-md'>
//                     <div>
//                         <img src='https://ik.imagekit.io/mhcockpit1/Testimonials/Poornima.png' alt='Poornima' className='w-32' />
//                     </div>
//                     <div>
//                         <p className='text-sm text-gray-500 px-5 py-5 text-justify'>The curriculum for my major has good structure which can give me the real experience for the field I desire to get into. I really like MH cockpit because I can get the education I want. At MH cockpit&nbsp; you will find people that will support you and guide you on the path to the degree.</p>
//                     </div>
//                 </div>

//                 <div className='bg-blue-500 bg-opacity-25 shadow-lg shadow-gray-300 px20 py-1 m-10 h-96 flex flex-col items-center rounded-md'>
//                     <div>
//                         <img src='https://ik.imagekit.io/mhcockpit1/Testimonials/Nidish%20vijay.png' alt='Nidish Vijay' className='w-32' />
//                     </div>
//                     <div>
//                         <p className='text-sm text-gray-500 px-5 py-5 text-justify'>The classes are not only theoretical. We start with theory but then we jump into practical journey of knowledge.</p>
//                     </div>
//                 </div>

//                 <div className='bg-blue-500 bg-opacity-25 shadow-lg shadow-gray-300 px20 py-1 m-10 h-96 flex flex-col items-center rounded-md'>
//                     <div>
//                         <img src='https://ik.imagekit.io/mhcockpit1/Testimonials/Nabendu.png' alt='Nabendu' className='w-32' />
//                     </div>
//                     <div>
//                         <p className='text-sm text-gray-500 px-5 py-5 text-justify'>When it comes to teaching, all the lecturers are very well experienced, they focus more on practical teaching along with the theoretical teaching. When a student has a problem, the teacher would walk to their desk and help the student.</p>
//                     </div>
//                 </div>

//                 <div className='bg-blue-500 bg-opacity-25 shadow-lg shadow-gray-300 px20 py-1 m-10 h-96 flex flex-col items-center rounded-md'>
//                     <div>
//                         <img src='https://ik.imagekit.io/mhcockpit1/Testimonials/MOHAMMAD-SHADAN.png' alt='Mohammad Shadan' className='w-32' />
//                     </div>
//                     <div>
//                         <p className='text-sm text-gray-500 px-5 py-5 text-justify'>MH Cockpit has given me a lot of opportunities to excel in studies and as well as in sports! And also it supported me at every step of my life! And right now wherever I am is just because of MH Cockpit.</p>
//                     </div>
//                 </div>

//                 <div className='bg-blue-500 bg-opacity-25 shadow-lg shadow-gray-300 px20 py-1 m-10 h-96 flex flex-col items-center rounded-md'>
//                     <div>
//                         <img src='https://ik.imagekit.io/mhcockpit1/Testimonials/VARSHIKA.png' alt='Varshika' className='w-32' />
//                     </div>
//                     <div>
//                         <p className='text-sm text-gray-500 px-5 py-5 text-justify'>I am glad to study B.Sc. Aeronautical science second year in MH Cockpit under these great people's guidance. Which is the great learning, they give us realtime experience to have great understanding... I'm excited to study here and gain great skills.</p>
//                     </div>
//                 </div>

//                 <div className='bg-blue-500 bg-opacity-25 shadow-lg shadow-gray-300 px20 py-1 m-10 h-96 flex flex-col items-center rounded-md'>
//                     <div>
//                         <img src='https://ik.imagekit.io/mhcockpit1/Testimonials/RAQEEB.png' alt='Raqeeb' className='w-32' />
//                     </div>
//                     <div>
//                         <p className='text-sm text-gray-500 px-5 py-5 text-justify'>My experience was great and memorable. The mentors over there helped me to enhance my academic and interpersonal skills.</p>
//                     </div>
//                 </div>

//             </AliceCarousel>
//         </div>
//     )
// }


import React from 'react';
import AliceCarousel from 'react-alice-carousel';

const testimonials = [
    {
        name: 'Diya Roy',
        image: 'https://ik.imagekit.io/mhcockpit1/Testimonials/Diya%20Roy.png',
        text: 'Studying under MH Cockpit has been really valuable as I get to learn a lot of things other than CPL core subjects. Starting from the experienced instructors who have worked in the aviation industry to the quality of knowledge it provides along with fun and spark, MH cockpit is helping me in every step to achieve my goal.',
    },
    {
        name: 'Nawal',
        image: 'https://ik.imagekit.io/mhcockpit1/Testimonials/NAWAL.png',
        text: 'I’ve learnt a lot during my time here in MH Cockpit not just academically but also in all the other aspects of career development. The instructors are very approachable and are available to answer any questions at any time.',
    },
    {
        name: 'Soumyanetra',
        image: 'https://ik.imagekit.io/mhcockpit1/Testimonials/Soumyanetra.png',
        text: 'Being a student who had limited exposure to the aviation industry, I feel glad that I chose MH Cockpit because the way they let you explore the industry is the best thing one could ever ask for.',
    },
    {
        name: 'Anuj',
        image: 'https://ik.imagekit.io/mhcockpit1/Testimonials/ANUJ.png',
        text: 'MH Cockpit is one of the most experienced and highly qualified instructors. The course curriculum is handcrafted around the needs and future desires of the Aviation Industry.',
    },
    {
        name: 'Poornima',
        image: 'https://ik.imagekit.io/mhcockpit1/Testimonials/Poornima.png',
        text: 'The curriculum for my major has a good structure which can give me the real experience for the field I desire to get into. I really like MH Cockpit because I can get the education I want. At MH Cockpit, you will find people that will support you and guide you on the path to the degree.',
    },
    {
        name: 'Nidish Vijay',
        image: 'https://ik.imagekit.io/mhcockpit1/Testimonials/Nidish%20vijay.png',
        text: 'The classes are not only theoretical. We start with theory but then we jump into practical journey of knowledge.',
    },
    {
        name: 'Nabendu',
        image: 'https://ik.imagekit.io/mhcockpit1/Testimonials/Nabendu.png',
        text: 'When it comes to teaching, all the lecturers are very well experienced. They focus more on practical teaching along with theoretical teaching. When a student has a problem, the teacher would walk to their desk and help the student.',
    },
    {
        name: 'Mohammad Shadan',
        image: 'https://ik.imagekit.io/mhcockpit1/Testimonials/MOHAMMAD-SHADAN.png',
        text: 'MH Cockpit has given me a lot of opportunities to excel in studies and as well as in sports! And also it supported me at every step of my life! And right now wherever I am is just because of MH Cockpit.',
    },
    {
        name: 'Varshika',
        image: 'https://ik.imagekit.io/mhcockpit1/Testimonials/VARSHIKA.png',
        text: 'I am glad to study B.Sc. Aeronautical Science second year in MH Cockpit under these great people\'s guidance. Which is the great learning, they give us real-time experience to have a great understanding... I\'m excited to study here and gain great skills.',
    },
    {
        name: 'Raqeeb',
        image: 'https://ik.imagekit.io/mhcockpit1/Testimonials/RAQEEB.png',
        text: 'My experience was great and memorable. The mentors over there helped me to enhance my academic and interpersonal skills.',
    }
];

const TestimonialsMobile = () => {
    return (
        <div>
            <AliceCarousel
                autoPlay
                disableButtonsControls
                disableDotsControls
                autoPlayInterval={2000}
                buttonsDisabled={false}
                dotsDisabled={true}
                infinite={true}
                swipeable={true}
                draggable={true}
                responsive={{
                    0: { items: 1 },
                    568: { items: 2 },
                    1024: { items: 3 },
                }}
            >
                {testimonials.map(({ name, image, text }, index) => (
                    <div key={index} className='bg-blue-500 bg-opacity-25 shadow-lg shadow-gray-300 px20 py-1 m-10 h-96 flex flex-col items-center rounded-md'>
                        <div>
                            <img src={image} alt={name} className='w-32' />
                        </div>
                        <div>
                            <p className='text-sm text-gray-500 px-5 py-5 text-justify'>{text}</p>
                        </div>
                    </div>
                ))}
            </AliceCarousel>
        </div>
    );
};

export default TestimonialsMobile;
