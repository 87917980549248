import React from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function Boeing() {
    return (
        <div>
            <div className='py-60 max-sm:py-20 pl-20 max-sm:pl-10 text-3xl'>
                <FadeInSectionDownToUp>
                    <h1 className='font-bold tracking-wide text-4xl'>Boeing <span className='text-gray-500'>737 & 777.</span></h1>
                    <h2>Boeing 737 & 777 Style Pannel & Components</h2>
                </FadeInSectionDownToUp>
            </div>
            <div className='flex max-sm:block justify-center items-center gap-40 xl:gap-40 sm:gap-20 py-5 px-10'>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='text-gray-500 text-2xl text-justify'>
                            The Runway may end, But the thrill <br className='max-sm:hidden' />
                            of Flight in a <b>Simulator</b> never does
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='text-3xl max-sm:text-2xl'>
                    <FadeInSectionDownToUp>
                        <p className='py-5'>
                            USB Plug n Play Modules to
                        </p>
                        <p className='font-bold'>
                            Start building your<br />
                            Home <span className='text-blue-700'>Cockpit</span> today !
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className='py-20'>
                <div className='relative'>
                    <div className='relative'>
                        <img src='https://ik.imagekit.io/mhcockpit1/Boeing%20737%20_%20777%20-%20Image.png' alt='Cessna' />
                    </div>
                    <div className='absolute bottom-1/2 xl:bottom-1/3 sm:bottom-1/3 max-sm:bottom-1/3 left-52 xl:left-52 sm:left-5 max-sm:left-10 max-sm:right-14 sm:right-1/4'>
                        <FadeInSectionDownToUp>
                            <button className='text-start text-white bg-black bg-opacity-50 py-5 px-7 rounded-3xl'>
                                <span className='text-3xl max-sm:text-xl'>Click here for</span><br />
                                <span className='font-bold clear-start text-6xl max-sm:text-3xl'>Quotation</span>
                            </button>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
            </div>
            <div className='px-40 xl:px-40 sm:px-10 max-sm:px-10 text-xl py-5 text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        SUSB Plug n Play Modules to start building your home cockpit today! Select from various options like DIY Kits, 3D printed affordable panels, Realistic 1:1 Laser cut & Engraved panels, Ready to Go USB plug & play type components to match your requirements.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='px-40 xl:px-40 sm:px-10 max-sm:px-10 text-xl py-5 text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        All products are compatible with Major B737, B777, B7xx series addons for FSX, P3D, Xplane and many more.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='px-40 xl:px-40 sm:px-10 max-sm:px-10 text-xl py-5 text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        Boeing B737 Style Overhead Panel.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-6 px-40 xl:px-40 sm:px-10 max-sm:px-5 py-5'>
                <div className='bg-black text-white p-8 w-full h-full rounded-3xl'>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-bold text-xl'>
                                Product Details
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='text-xl py-5 text-justify'>
                        <FadeInSectionDownToUp>
                            <p>
                                Overhead Module completely assembled and manufactured with high quality acrylic panels. Frame is made of laser cut steel and painted with anticorrosive industrial paint. The panel is non backlit type.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-semibold text-xl'>
                                Main Features of MCP are
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Realistic Korry type switches.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Panels in two plates for future backlighting.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Box type enclosure for Table Top installation.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    All indicators, switches and other elements are 1:1.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Set of Control cards to manage the entire module.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Classic B737 Auto Pilot Disconnect Pull Lever.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    USB plug n play.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Knobs are 3D printed in 1:1.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Approx Measurements (in cm) are: 30 x 9 x 10.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Approximate weight of the packaged product: 1Kg.
                                </li>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                </div>
                <div className='bg-black text-white p-8 w-full h-full rounded-3xl'>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-bold text-xl'>
                                Boeing B737 Style EFIS Panel Product Details
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='text-xl py-5 text-justify'>
                        <FadeInSectionDownToUp>
                            <p>
                                It is a full scale replica of B737 style Electronic Flight Instrument System Panel (EFIS). The module is USB Plug-n-Play. It is compatible with all major flight sim B737 addons like PMDG/Zibo etc. The panel is non backlit type.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-semibold text-xl'>
                                Main Features of EFIS Panel are
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Realistic Korry type switches.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Panels in two plates for future backlighting.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Box type enclosure for Table Top installation.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    All indicators, switches and other elements are 1:1.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Set of Control cards to manage the entire module.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Compatible with B737 style MCP as an extension module.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    USB plug n play.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Knobs are 3D printed in 1:1.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Approx Measurements (in cm) are: 18 x 9 x 10.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Approximate weight of the packaged product: 1Kg.
                                </li>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4 px-40 xl:px-40 sm:px-10 max-sm:px-5 py-5'>
                <div className='text-black p-8 w-full h-full rounded-3xl shadow-2xl'>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-bold text-xl'>
                                Boeing B737 Style Instrument Panel Frames Product Details
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='text-xl py-5 text-justify'>
                        <FadeInSectionDownToUp>
                            <p>
                                Main Instrument Panel (MIP) Structure & Pedestal frames manufactured with high quality stainless steel. Frames are laser cut in steel and painted with anticorrosive industrial paint. The panel Structure is compatible with all IFS India B737 panels & components.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-semibold text-xl'>
                                Main Features of Panels are
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Realistic Stainless Steel Structure.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Provision for all 1:1 panels and components.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Mounting holes for all monitors and screens.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Panel is approx. 1:1 for B737.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Cable cut outs for Glare shield & Pedestal Bay.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Structures are floor mounting type.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    A perfect product for DIY type people.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Compatible with DIY self-made panels also.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Measurements depends on type of product selected.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Approximate weight: 10Kg (MIP), 40kg (Full MIP), 20kg (Pedestal Bay).
                                </li>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                </div>
                <div className='text-black p-8 w-full h-full rounded-3xl shadow-2xl'>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-bold text-xl'>
                                Boeing B737 Style Other Panels Product Details
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='text-xl py-5 text-justify'>
                        <FadeInSectionDownToUp>
                            <p>
                                B737 Style Auxiliary Control Panels manufactured with high quality acrylic panels. USB Plug and play module, no dummy buttons. Final functionality depends on addon used. The panels are non-backlit type.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-semibold text-xl'>
                                Main Features of panels are
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Realistic switches.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Panels in two plates for future backlighting.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Box type enclosure for Table Top installation.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    All indicators, switches and other elements are 1:1.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Set of Control cards to manage the entire module.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    USB plug n play.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Knobs (if applicable in supply) are 3D printed in 1:1.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Measurements vary based on the product.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Approximate weight of the packaged product: 1Kg.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Contact us for fully assembled pedestal.
                                </li>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
