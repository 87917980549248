import React, { useEffect, useRef } from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp';
import { useNavigate } from 'react-router-dom';

export default function TypeRating() {

    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;

        if (video) {
            const playPromise = video.play();

            if (playPromise !== undefined) {
                playPromise
                    .then(_ => {
                        // Autoplay started
                        console.log('Autoplay started');
                    })
                    .catch(error => {
                        // Autoplay was prevented, unmute and play
                        video.muted = true; // Unmute the video
                        video.play().catch(error => {
                            console.error('Autoplay was blocked:', error);
                        });
                    });
            }
        }
    }, []);

    const navigate = useNavigate();

    return (
        <div>
            <div>
                <video autoPlay loop playsInline className='w-full'>
                    <source src={"https://mh-cockpit.blr1.cdn.digitaloceanspaces.com/Type%20Rating.mp4.mp4"} type='video/mp4' />
                </video>
            </div>
            <div className='px-20 pb-20 xl:px-20 lg:px-20 max-sm:px-5 sm:px-5'>
                <div className='w-full bg-white -mt-20 xl:-mt-56 max-sm:-mt-10 py-20 xl:py-20 max-sm:py-10 sm:py-10 px-20 xl:px-20 max-sm:px-5 sm:px-5 rounded-3xl shadow-2xl shadow-gray-700 relative'>
                    <FadeInSectionDownToUp>
                        <h1 className='text-5xl max-sm:text-3xl text-center font-semibold'>Type <span className='text-emerald-600'>Rating.</span></h1>
                    </FadeInSectionDownToUp>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='text-lg text-justify pt-20 max-sm:pt-10'>
                            Our Type Rating courses provide in-depth training tailored to specific aircraft models, ensuring you master the intricacies of each system. Whether it's for narrow-body, wide-body, or regional aircraft, our programs are crafted to meet the industry's highest standards.
                            </p>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <p className='text-3xl max-sm:text-2xl font-semibold pt-10'>
                            Experienced Instructors
                            </p>
                            <p className='text-lg text-justify pt-5 max-sm:pt-5'>
                            Learn from seasoned professionals with extensive experience on the aircraft type. Our instructors bring real-world insights and hands-on expertise, guiding you through both theoretical knowledge and practical applications.
                            </p>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <p className='text-3xl max-sm:text-2xl font-semibold pt-10'>
                            Cutting-Edge Simulators
                            </p>
                            <p className='text-lg text-justify pt-5 max-sm:pt-5'>
                            Immerse yourself in our state-of-the-art simulators that replicate the actual cockpit environment. Our training approach combines simulation exercises and flight scenarios, allowing you to hone your skills in a risk-free, controlled setting.
                            </p>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <p className='text-3xl max-sm:text-2xl font-semibold pt-10'>
                            Flexible Scheduling
                            </p>
                            <p className='text-lg text-justify pt-5 max-sm:pt-5'>
                            We understand the demands of a pilot's schedule. Our flexible training schedules accommodate your needs, ensuring you receive the highest quality education without compromising your professional commitments.
                            </p>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <p className='text-3xl max-sm:text-2xl font-semibold pt-10'>
                            Admission Requirements
                            </p>
                            <p className='text-lg text-justify pt-5 max-sm:pt-5'>
                            To enroll in our Type Rating programs, candidates must possess a Commercial Pilot License (CPL) and meet specific flight experience criteria. Ground school sessions supplement your practical training, covering aircraft systems, emergency procedures, and operational aspects.
                            </p>
                            <p className='text-lg text-justify pt-5 max-sm:pt-5'>
                            Take the next step in your aviation journey. Join our Type Rating programs and gain the expertise needed to command specific aircraft types with precision and confidence. Your pathway to excellence starts here.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='flex xl:flex-row lg:flex-row max-sm:flex-col sm:flex-col pt-14 gap-2'>
                        <div className='bg-black text-white w-1/4 lg:w-1/2 xl:w-1/4 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-emerald-600 font-bold text-xl pb-3'>Duration</p>
                                <p className='text-2xl'>2 Months</p>
                            </FadeInSectionDownToUp>
                        </div>
                        <div className='bg-black text-white w-3/4 lg:w-3/4 xl:w-3/4 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 max-sm:px-5 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-emerald-600 font-bold text-xl pb-3'>Eligibility</p>
                                <ul>
                                    <div className='flex'>
                                        <span className='text-xl text-emerald-600 font-bold mr-2'>&#9992;</span><li className=''>Valid CPL with ATPL frozen.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-emerald-600 font-bold mr-2'>&#9992;</span><li className=''>Valid ME IR or Type rating.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-emerald-600 font-bold mr-2'>&#9992;</span><li className=''>Valid Medical Class I.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-emerald-600 font-bold mr-2'>&#9992;</span><li className=''>Fluency in English.</li>
                                    </div>
                                </ul>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                    <div className='pt-2'>
                        <div className='bg-black text-white py-5 pl-20 max-sm:pl-5 flex justify-start items-center rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-emerald-600 font-bold text-xl pb-3'>Flying Training</p>
                                <ul>
                                    <div className='flex'>
                                        <span className='text-xl text-emerald-600 font-bold mr-2'>&#9992;</span><li className=''>+ 24 hours FBS training.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-emerald-600 font-bold mr-2'>&#9992;</span><li className=''>44 hours FFS training.</li>
                                    </div>
                                </ul>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                    <FadeInSectionDownToUp>
                        <div className='grid grid-cols-2 gap-2 justify-center items-center px-96 max-sm:px-0 sm:px-40 pt-10'>
                            <button onClick={() => navigate('/contact')} className='bg-emerald-600 text-white py-2 rounded-xl mx-2'>For Queries</button>
                            <button onClick={() => navigate('/contact')} className='bg-emerald-600 text-white py-2 rounded-xl mx-2'>Apply Now</button>
                        </div>
                    </FadeInSectionDownToUp>
                </div>
            </div>
        </div>
    )
}
