import React, { useEffect, useRef } from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp';
import { useNavigate } from 'react-router-dom';

export default function BscAviation() {

    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;

        if (video) {
            const playPromise = video.play();

            if (playPromise !== undefined) {
                playPromise
                    .then(_ => {
                        // Autoplay started
                        console.log('Autoplay started');
                    })
                    .catch(error => {
                        // Autoplay was prevented, unmute and play
                        video.muted = true; // Unmute the video
                        video.play().catch(error => {
                            console.error('Autoplay was blocked:', error);
                        });
                    });
            }
        }
    }, []);

    const navigate = useNavigate();

    return (
        <div>
            <div>
                <video autoPlay loop playsInline className='w-full'>
                    <source src={"https://mh-cockpit.blr1.cdn.digitaloceanspaces.com/B.Sc%20Aviation%202.mp4.mp4"} type='video/mp4' />
                </video>
            </div>
            <div className='px-20 pb-20 xl:px-20 lg:px-20 max-sm:px-5 sm:px-5'>
                <div className='w-full bg-white -mt-20 xl:-mt-56 max-sm:-mt-10 py-20 xl:py-20 max-sm:py-10 sm:py-10 px-20 xl:px-20 max-sm:px-5 sm:px-5 rounded-3xl shadow-2xl shadow-gray-700 relative'>
                    <FadeInSectionDownToUp>
                        <h1 className='text-5xl max-sm:text-3xl text-center font-semibold'>B.Sc <span className='text-[#6b8d36]'>Aviation.</span></h1>
                    </FadeInSectionDownToUp>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='text-lg text-justify pt-20 max-sm:pt-10'>
                                Gain new insights and deeper understanding of Aviation with the Graduation in Science Degree. This Multi Disciplinary, Graduate Level Programme to Enhance your knowledge in areas including Flying, Air Traffic Control and Airport & Airline Operations.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='flex xl:flex-row lg:flex-row max-sm:flex-col sm:flex-col pt-14 gap-2'>
                        <div className='bg-black text-white w-1/4 lg:w-1/2 xl:w-1/4 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-[#6b8d36] font-bold text-xl pb-3'>Duration</p>
                                <p className='text-2xl'>3 Years</p>
                            </FadeInSectionDownToUp>
                        </div>
                        <div className='bg-black text-white w-3/4 lg:w-3/4 xl:w-3/4 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 max-sm:px-5 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-[#6b8d36] font-bold text-xl pb-3'>Eligibility</p>
                                <ul>
                                    <div className='flex'>
                                        <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>10+2 with Maths, Physics & Chemistry.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>Minimum 50% of Marks from Recognized board.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>Lateral Entry for the Course is only for candidates who are in possesion of the CPL License.</li>
                                    </div>
                                </ul>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                    <div className='bg-black text-white flex justify-start items-center py-7 px-10 max-sm:px-5 rounded-3xl mt-2 pl-20 max-sm:pl-5'>
                        <FadeInSectionDownToUp>
                            <p className='text-[#6b8d36] font-bold text-xl pb-3'>Accreditation</p>
                            <ul>
                                <div className='flex'>
                                    <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>B.Sc in Aviation is approved by UGC (University Grants Commission), New Delhi.</li>
                                </div>
                            </ul>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='flex xl:flex-row lg:flex-row max-sm:flex-col sm:flex-col pt-2 gap-2'>
                        <div className='bg-black text-white w-1/2 lg:w-1/2 xl:w-1/2 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 max-sm:px-5 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-[#6b8d36] font-bold text-xl pb-3'>Career Opportunities.</p>
                                <ul>
                                    <div className='flex'>
                                        <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>Airline Pilot.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>Aircraft Technician.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>Airport/Airline Ground staffs.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>ATC Traffic Control Officer.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>Airport Flight Operations officer.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>Airline Cargo Operations officer.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>Can apply for State, Central Govt Possitions.</li>
                                    </div>
                                </ul>
                            </FadeInSectionDownToUp>
                        </div>
                        <div className='bg-black text-white w-1/2 lg:w-1/2 xl:w-1/2 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-[#6b8d36] font-bold text-xl pb-3'>Practical Exposure.</p>
                                <ul>
                                    <div className='flex'>
                                        <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>Flying exposure with Retd. IAF wing.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>Commanders.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>Leading Aerospace Companies.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>AirTraffic Control Department.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>Airport Internship.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>Airline Internship.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#6b8d36] font-bold mr-2'>&#9992;</span><li className=''>MRO.</li>
                                    </div>
                                </ul>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                    <div className='flex justify-center items-center py-10'>
                        <FadeInSectionDownToUp>
                            <a href='/private-pilot-license' className='text-[#6b8d36] underline text-3xl'>Course Content</a>
                        </FadeInSectionDownToUp>
                    </div>
                    <FadeInSectionDownToUp>
                        <div className='grid grid-cols-2 gap-2 justify-center items-center px-96 max-sm:px-0 sm:px-40'>
                            <button onClick={() => navigate('/contact')} className='bg-[#6b8d36] text-white py-2 rounded-xl mx-2'>For Queries</button>
                            <button onClick={() => navigate('/contact')} className='bg-[#6b8d36] text-white py-2 rounded-xl mx-2'>Apply Now</button>
                        </div>
                    </FadeInSectionDownToUp>
                </div>
            </div>
        </div>
    )
}
