import React from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'
import { useNavigate } from 'react-router-dom'

export default function AboutUs() {

    const navigate = useNavigate();

    return (
        <div className='grid grid-cols-3 xl:grid-cols-3 max-sm:block'>
            <div className='bg-[#161523] text-white p-3 pb-32 flex flex-col justify-between'>
                <FadeInSectionDownToUp>
                    <h1 className='text-4xl font-semibold py-14'>
                        Who we are.
                    </h1>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <p className='text-lg text-justify'>
                        With a legacy rooted in innovation, MH Cockpit redefines aviation training. Our comprehensive programs seamlessly blend theoretical knowledge with hands-on experience, ensuring our students are equipped to soar to new heights in their careers. From cutting-edge flight simulations to state-of-the-art laboratories, we provide an immersive learning environment that mirrors real-world aviation scenarios.
                    </p>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <button onClick={() => navigate('/ourgovernance')} className='border px-4 py-2 rounded-2xl self-center mt-52'>
                        Read More
                    </button>
                </FadeInSectionDownToUp>
            </div>
            <div className='bg-[#2293cb] text-white p-3 pb-32 flex flex-col justify-between'>
                <FadeInSectionDownToUp>
                    <h1 className='text-4xl font-semibold py-14'>
                        What We Do.
                    </h1>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <p className='text-lg text-justify'>
                        MH Cockpit is an Indian - Aviation Training, Technology and Research Organization. Committed to deliver Excellence in Aviation training into the new Generation of Pilots, AME, Aviation Professionals. MH Cockpit is also committed to create sustainable aerospace building next generation training aircraft for most safe, Efficient, Greener planet at the affordable flying cost.
                    </p>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <button onClick={() => navigate('/our-values')} className='border px-4 py-2 rounded-2xl self-center mt-52'>
                        Read More
                    </button>
                </FadeInSectionDownToUp>
            </div>
            <div className='bg-[#bc7154] text-white p-3 pb-32 flex flex-col justify-between'>
                <FadeInSectionDownToUp>
                    <h1 className='text-4xl font-semibold py-14'>
                        Our Presence.
                    </h1>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <p className='text-lg text-justify'>
                        With a global reach and a local impact, MH Cockpit operates at the intersection of international standards and community needs. Our presence extends beyond borders, yet we remain deeply connected to the communities we serve, creating a harmonious blend of global expertise and local relevance.
                    </p>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <button onClick={() => navigate('/our-presence')} className='border px-4 py-2 rounded-2xl self-center mt-52'>
                        Read More
                    </button>
                </FadeInSectionDownToUp>
            </div>
        </div>
    )
}
