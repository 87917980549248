import { useEffect } from 'react';
import metaDescriptions from './metaDescriptions'; // Import the mapping

const MetaDescription = ({ pageLink }) => {
  useEffect(() => {
    const description = metaDescriptions[pageLink] || "Default meta description if not found";

    const metaDescriptionTag = document.querySelector('meta[name="description"]');
    if (metaDescriptionTag) {
      metaDescriptionTag.setAttribute('content', description);
    } else {
      const newMetaTag = document.createElement('meta');
      newMetaTag.setAttribute('name', 'description');
      newMetaTag.setAttribute('content', description);
      document.head.appendChild(newMetaTag);
    }
  }, [pageLink]);

  return null;
};

export default MetaDescription;
