import React from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'
import { useNavigate } from 'react-router-dom'

export default function FlightSimulator() {

    const navigate = useNavigate();

    return (
        <div className='bg-black text-white'>
            <div>
                <video autoPlay muted loop className="w-full h-full object-cover">
                    <source src="https://mh-cockpit.blr1.cdn.digitaloceanspaces.com/Website%20-%20Flight%20Simulator.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className='pl-40 xl:pl-40 sm:pl-10 max-sm:pl-10 text-4xl max-sm:text-2xl font-bold tracking-tighter py-10'>
                <FadeInSectionDownToUp>
                    <h1>Flight <span className='text-gray-500'>Simulators.</span></h1>
                </FadeInSectionDownToUp>
            </div>
            <div className='px-40 xl:px-40 sm:px-10 max-sm:px-10 text-xl py-5 text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        Flight simulators are advanced software or hardware systems that replicate the experience of flying an aircraft. They simulate the behavior of an aircraft and its environment. Pilots use Simulators for training, practicing, and maintaining proficiency without the risks of actual flight. They offer realistic scenarios, Including various weather conditions and emergency situations. Flight simulators are crucial for pilot education, aircraft testing, and research and development within the aviation industry.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='px-40 xl:px-40 sm:px-10 max-sm:px-10 py-5'>
                <a href='/cessna'>
                    <div className='relative'>
                        <div className='relative'>
                            <video autoPlay muted loop className="w-full h-full object-cover rounded-[100px] xl:rounded-[100px] sm:rounded-3xl max-sm:rounded-3xl">
                                <source src="https://mh-cockpit.blr1.cdn.digitaloceanspaces.com/FS%20Cessna.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        <div className='absolute xl:bottom-1/2 sm:bottom-1/3 max-sm:bottom-1/3 left-20 xl:left-20 sm:left-5 max-sm:left-10'>
                            <FadeInSectionDownToUp>
                                <h1 className='text-4xl xl:text-4xl lg:text-6xl sm:text-3xl max-sm:text-xl font-bold text-white'>
                                    Cessna.
                                </h1>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                </a>
            </div>
            <div className='px-40 xl:px-40 sm:px-10 max-sm:px-10 py-5'>
                <a href='/airbus'>
                    <div className='relative'>
                        <div className='relative'>
                            <video autoPlay muted loop className="w-full h-full object-cover rounded-[100px] xl:rounded-[100px] sm:rounded-3xl max-sm:rounded-3xl">
                                <source src="https://mh-cockpit.blr1.cdn.digitaloceanspaces.com/Airbus%20320.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        <div className='absolute xl:bottom-1/2 sm:bottom-1/3 max-sm:bottom-1/3 right-20 xl:right-20 sm:right-5 max-sm:right-10'>
                            <FadeInSectionDownToUp>
                                <h1 className='text-4xl xl:text-4xl lg:text-6xl sm:text-3xl max-sm:text-xl font-bold text-white'>
                                    Airbus <span className='text-gray-500'>320.</span>
                                </h1>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                </a>
            </div>
            <div className='px-40 xl:px-40 sm:px-10 max-sm:px-10 py-5'>
                <a href='/boeing'>
                    <div className='relative'>
                        <div className='relative'>
                            <video autoPlay muted loop className="w-full h-full object-cover rounded-[100px] xl:rounded-[100px] sm:rounded-3xl max-sm:rounded-3xl">
                                <source src="https://mh-cockpit.blr1.cdn.digitaloceanspaces.com/Boeing%20737&777.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        <div className='absolute xl:bottom-1/2 sm:bottom-1/3 max-sm:bottom-1/3 left-20 xl:left-20 sm:left-5 max-sm:left-10'>
                            <FadeInSectionDownToUp>
                                <h1 className='text-4xl xl:text-4xl lg:text-6xl sm:text-3xl max-sm:text-xl font-bold text-white'>
                                    Boeing <span className='text-gray-500'>737 & 777.</span>
                                </h1>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                </a>
            </div>
            <FadeInSectionDownToUp>
                <div className='flex justify-center items-center gap-2 py-10'>
                    <center>
                        <button onClick={() => navigate('/contact')} className='bg-[#a1447d] text-white py-2 px-5 rounded-xl mx-2 w-full'>For Queries</button>
                    </center>
                    <center>
                        <button onClick={() => navigate('/contact')} className='bg-[#a1447d] text-white py-2 px-5 rounded-xl mx-2 w-full'>Apply Now</button>
                    </center>
                </div>
            </FadeInSectionDownToUp>
        </div>
    )
}
