import React, { useState } from 'react';
import "./Testimonials.css";

const Testimonials = () => {
  const [hoveredImage, setHoveredImage] = useState(null);

  return (
    <div className='px-10'>

      <div className='flex justify-center space-x-20'>

        <div
          onMouseEnter={() => setHoveredImage(1)}
          onMouseLeave={() => setHoveredImage(null)}
          className={`relative w-48 h-48 transition-transform duration-1000 ${hoveredImage && hoveredImage !== 1
            ? 'transform scale-75 blur-sm'
            : hoveredImage
              ? 'transform scale-125 z-10'
              : ''
            }`}
        >
          <img
            src="https://ik.imagekit.io/mhcockpit1/Testimonials/Diya%20Roy.png"
            alt="Diya Roy"
            className="w-full h-full object-cover transform scale-100 testimonials-wiggle"
          />
          {hoveredImage === 1 && (
            <div className="absolute -bottom-32 right-0 left-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-xs rounded-md px-3 py-3 text-justify">
              Studying under MH Cockpit has been really valuable as i get to learn a lot of things other than cpl core subjects. Starting from the experienced instructors who has worked in the aviation industry to the quality of knowledge it provides along with fun and spark, MH cockpit is helping me in every step to achieve my goal.
            </div>
          )}
        </div>

        <div
          onMouseEnter={() => setHoveredImage(2)}
          onMouseLeave={() => setHoveredImage(null)}
          className={`relative w-48 h-48 transition-transform duration-1000 ${hoveredImage && hoveredImage !== 2
            ? 'transform scale-75 blur-sm'
            : hoveredImage
              ? 'transform scale-125 z-10'
              : ''
            }`}
        >
          <img
            src="https://ik.imagekit.io/mhcockpit1/Testimonials/NAWAL.png"
            alt="Nawal"
            className="w-full h-full object-cover transform scale-100 testimonials-wiggle-1"
          />
          {hoveredImage === 2 && (
            <div className="absolute -bottom-20 right-0 left-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-xs rounded-md px-3 py-3 text-justify">
              I’ve learnt a lot during my time here in MH Cockpit not just academically but also in all the other aspects of career development. The instructors are very approachable and are available to answer any questions at any time.
            </div>
          )}
        </div>

        <div
          onMouseEnter={() => setHoveredImage(3)}
          onMouseLeave={() => setHoveredImage(null)}
          className={`relative w-48 h-48 transition-transform duration-1000 ${hoveredImage && hoveredImage !== 3
            ? 'transform scale-75 blur-sm'
            : hoveredImage
              ? 'transform scale-125 z-10'
              : ''
            }`}
        >
          <img
            src="https://ik.imagekit.io/mhcockpit1/Testimonials/Soumyanetra.png"
            alt="Soumyanetra"
            className="w-full h-full object-cover transform scale-100 testimonials-wiggle"
          />
          {hoveredImage === 3 && (
            <div className="absolute -bottom-16 right-0 left-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-xs rounded-md px-3 py-3 text-justify">
              Being a student who had limited exposure to the aviation industry, I feel glad that I choose MH Cockpit because the way they'll let you explore the industry is the best thing one could ever ask for.
            </div>
          )}
        </div>

      </div>

      <div className='flex justify-center space-x-28'>

        <div
          onMouseEnter={() => setHoveredImage(4)}
          onMouseLeave={() => setHoveredImage(null)}
          className={`relative w-48 h-48 transition-transform duration-1000 ${hoveredImage && hoveredImage !== 4
            ? 'transform scale-75 blur-sm'
            : hoveredImage
              ? 'transform scale-125 z-10'
              : ''
            }`}
        >
          <img
            src="https://ik.imagekit.io/mhcockpit1/Testimonials/ANUJ.png"
            alt="Anuj"
            className="w-full h-full object-cover transform scale-100 testimonials-wiggle-1"
          />
          {hoveredImage === 4 && (
            <div className="absolute -bottom-14 right-0 left-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-xs rounded-md px-3 py-3 text-justify">
              MH Cockpit is one of the most experienced and highly qualified Instructors. The course curriculum is handcrafted around the needs and future desires of the Aviation Industry.
            </div>
          )}
        </div>

        <div
          onMouseEnter={() => setHoveredImage(5)}
          onMouseLeave={() => setHoveredImage(null)}
          className={`relative w-48 h-48 transition-transform duration-1000 ${hoveredImage && hoveredImage !== 5
            ? 'transform scale-75 blur-sm'
            : hoveredImage
              ? 'transform scale-125 z-10'
              : ''
            }`}
        >
          <img
            src="https://ik.imagekit.io/mhcockpit1/Testimonials/Poornima.png"
            alt="Poornima"
            className="w-full h-full object-cover transform scale-100 testimonials-wiggle"
          />
          {hoveredImage === 5 && (
            <div className="absolute -bottom-28 right-0 left-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-xs rounded-md px-3 py-3 text-justify">
              The curriculum for my major has good structure which can give me the real experience for the field I desire to get into. I really like MH cockpit because I can get the education I want. At MH cockpit&nbsp; you will find people that will support you and guide you on the path to the degree.
            </div>
          )}
        </div>

        <div
          onMouseEnter={() => setHoveredImage(6)}
          onMouseLeave={() => setHoveredImage(null)}
          className={`relative w-48 h-48 transition-transform duration-1000 ${hoveredImage && hoveredImage !== 6
            ? 'transform scale-75 blur-sm'
            : hoveredImage
              ? 'transform scale-125 z-10'
              : ''
            }`}
        >
          <img
            src="https://ik.imagekit.io/mhcockpit1/Testimonials/Nidish%20vijay.png"
            alt="Nidish Vijay"
            className="w-full h-full object-cover transform scale-100 testimonials-wiggle-1"
          />
          {hoveredImage === 6 && (
            <div className="absolute bottom-0 right-0 left-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-xs rounded-md px-3 py-3 text-justify">
              The classes are not only theoretical. We start with theory but then we jump into practical journey of knowledge.
            </div>
          )}
        </div>

        <div
          onMouseEnter={() => setHoveredImage(7)}
          onMouseLeave={() => setHoveredImage(null)}
          className={`relative w-48 h-48 transition-transform duration-1000 ${hoveredImage && hoveredImage !== 7
            ? 'transform scale-75 blur-sm'
            : hoveredImage
              ? 'transform scale-125 z-10'
              : ''
            }`}
        >
          <img
            src="https://ik.imagekit.io/mhcockpit1/Testimonials/Nabendu.png"
            alt="Nabendu"
            className="w-full h-full object-cover transform scale-100 testimonials-wiggle"
          />
          {hoveredImage === 7 && (
            <div className="absolute -bottom-20 right-0 left-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-xs rounded-md px-3 py-3 text-justify">
              When it comes to teaching, all the lecturers are very well experienced, they focus more on practical teaching along with the theoretical teaching. When a student has a problem, the teacher would walk to their desk and help the student.
            </div>
          )}
        </div>

      </div>

      <div className='flex justify-center space-x-20'>

        <div
          onMouseEnter={() => setHoveredImage(8)}
          onMouseLeave={() => setHoveredImage(null)}
          className={`relative w-48 h-48 transition-transform duration-1000 ${hoveredImage && hoveredImage !== 8
            ? 'transform scale-75 blur-sm'
            : hoveredImage
              ? 'transform scale-125 z-10'
              : ''
            }`}
        >
          <img
            src="https://ik.imagekit.io/mhcockpit1/Testimonials/MOHAMMAD-SHADAN.png"
            alt="Mohammad Shadan"
            className="w-full h-full object-cover transform scale-100 testimonials-wiggle"
          />
          {hoveredImage === 8 && (
            <div className="absolute -bottom-14 right-0 left-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-xs rounded-md px-3 py-3 text-justify">
              MH Cockpit has given me a lot of opportunities to excel in studies and as well as in sports! And also it supported me at every step of my life! And right now wherever I am is just because of MH Cockpit.
            </div>
          )}
        </div>

        <div
          onMouseEnter={() => setHoveredImage(9)}
          onMouseLeave={() => setHoveredImage(null)}
          className={`relative w-48 h-48 transition-transform duration-1000 ${hoveredImage && hoveredImage !== 9
            ? 'transform scale-75 blur-sm'
            : hoveredImage
              ? 'transform scale-125 z-10'
              : ''
            }`}
        >
          <img
            src="https://ik.imagekit.io/mhcockpit1/Testimonials/VARSHIKA.png"
            alt="Varshika"
            className="w-full h-full object-cover transform scale-100 testimonials-wiggle-1"
          />
          {hoveredImage === 9 && (
            <div className="absolute -bottom-20 right-0 left-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-xs rounded-md px-3 py-3 text-justify">
              I am glad to study B.Sc. Aeronautical science second year in MH Cockpit under these great people's guidance. Which is the great learning, they give us realtime experience to have great understanding... I'm excited to study here and gain great skills.
            </div>
          )}
        </div>

        <div
          onMouseEnter={() => setHoveredImage(10)}
          onMouseLeave={() => setHoveredImage(null)}
          className={`relative w-48 h-48 transition-transform duration-1000 ${hoveredImage && hoveredImage !== 10
            ? 'transform scale-75 blur-sm'
            : hoveredImage
              ? 'transform scale-125 z-10'
              : ''
            }`}
        >
          <img
            src="https://ik.imagekit.io/mhcockpit1/Testimonials/RAQEEB.png"
            alt="Raqeeb"
            className="w-full h-full object-cover transform scale-100 testimonials-wiggle"
          />
          {hoveredImage === 10 && (
            <div className="absolute -bottom-10 right-0 left-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-xs rounded-md px-3 py-3 text-justify">
              My experience was great and memorable. The mentors over there helped me to enhance my academic and interpersonal skills.
            </div>
          )}
        </div>

      </div>

      {/* <div className='flex justify-center items-center py-10'>
        <a href='' className='border border-gray-500 px-5 py-1 text-gray-400 font-semibold rounded-md tracking-wider'>We have a lot of happy <span className='text-blue-950'>students</span></a>
      </div> */}

    </div>
  );
};

export default Testimonials;
