import React from 'react';
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';

function ACMI() {

    return (
        <div>
            <div className="relative text-justify">
                <div className="inset-0 overflow-hidden">
                    <img
                        className='w-full h-full object-cover animate-zoomOut'
                        src="https://ik.imagekit.io/mhcockpit1/ACMI%20Leasing.webp"
                        alt="ACMI Leasing"
                    />
                </div>
                <div className="relative py-10">
                    <div className='pl-40 max-sm:pl-10 text-4xl font-semibold tracking-tighter py-10'>
                        <FadeInSectionDownToUp>
                            <h1>ACMI Leasing.</h1>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Air Charter Service’s dedicated aircraft leasing division provides wet, damp (ACMI), dry and hybrid lease contracts to airlines around the world. Whether it’s a last-minute sub-charter or additional aircraft to fulfil a busy summer flight schedule, our flexible solutions allow operators to meet seasonal and unexpected demand with dedicated aircraft.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Our expert team can handle every aspect of your lease, from negotiating agreements to managing the operation and lease throughout its contract period and post lease reconciliations. We can also utilise our in-house travel, private jet and cargo teams to arrange crew accommodation and transport spare parts and crews wherever they’re needed.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>

                    </div>
                    <center>
                        <div className="text-4xl max-sm:text-2xl font-semibold pt-2">
                            <FadeInSectionDownToUp>
                                <h1>Why Choose <span className="text-gray-500">MH Cockpit?</span></h1>
                            </FadeInSectionDownToUp>
                        </div>
                    </center>
                    <div className="text-xl px-40 max-sm:px-10 pt-2">
                        <FadeInSectionDownToUp>
                            <h1 className='font-semibold'>Experience</h1>
                        </FadeInSectionDownToUp>
                        <ul className="py-3">
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        MH has experts in aircraft leasing and our knowledgeable team has built long-lasting relationships with airlines around the world.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className="text-xl px-40 max-sm:px-10 pt-2">
                        <FadeInSectionDownToUp>
                            <h1 className='font-semibold'>Global Coverage</h1>
                        </FadeInSectionDownToUp>
                        <ul className="py-3">
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        The leasing team is supported by our 27 regional offices around the world, allowing us to offer local knowledge, and manage cultural differences on a global scale.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className="text-xl px-40 max-sm:px-10 pt-2">
                        <FadeInSectionDownToUp>
                            <h1 className='font-semibold'>Buying Power</h1>
                        </FadeInSectionDownToUp>
                        <ul className="py-3">
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Thanks to the number of global contracts we operate each year, we always offer the best prices and availability.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className="text-xl px-40 max-sm:px-10 pt-2">
                        <FadeInSectionDownToUp>
                            <h1 className='font-semibold'>24/7 Service</h1>
                        </FadeInSectionDownToUp>
                        <ul className="py-3">
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        We’re available around the clock, offering forward-thinking strategic and operational support for emergency capacity requirements.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ACMI;
