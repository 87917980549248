import React from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function Blog3() {
    return (
        <div>
            <div className='xl:pt-0 sm:pt-12'>
                <FadeInSectionDownToUp>
                    <h1 className='text-center font-semibold text-3xl xl:text-3xl sm:text-xl max-sm:text-lg py-5'>A Day in the Life of an Aviation Student.</h1>
                </FadeInSectionDownToUp>
                <hr />
            </div>
            <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 text-justify'>
                <div className='py-5'>
                    <img src='https://ik.imagekit.io/mhcockpit1/blogs/Day%20in%20a%20Life%20of%20a%20Aviation%20Students.webp' alt='A Day in the Life of an Aviation Student.' />
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            The journey to becoming a pilot is an exhilarating and challenging adventure. Aviation students not only navigate the skies but also go through a rigorous training regimen that prepares them for a career above the clouds. Join us as we delve into a day in the life of an aviation student, where passion meets precision, and dreams take flight.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Morning Routine
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            The day of an aviation student begins early, with a passion for flight fuelling their every move. Mornings are crucial for mental preparation and physical well-being. A hearty breakfast is a must to fuel the body and mind for the day ahead. Many students engage in some form of exercise, enhancing their focus and stamina and preparing them for the exciting day ahead.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Ground School
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            Aviation students spend a significant portion of their day learning the theoretical and practical aspects of navigation, aerodynamics, meteorology, and aircraft systems. The professors and instructors impart knowledge that is not only theoretical but also applicable to real-life scenarios in the cockpit.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Simulator Training
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            Simulator training provides a controlled environment where students can practice flight manoeuvres, emergency procedures, and navigation without leaving the ground. These sessions are instrumental in building muscle memory and honing decision-making skills crucial for a successful aviation career.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Flight Training
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            The highlight of the day for any aviation student is the time spent on the actual flight. Whether it's a single-engine aircraft or a more complex machine, students apply their theoretical knowledge and simulator experience to the skies. Under the watchful eye of experienced flight instructors, they learn to take off, navigate through airspace, and execute smooth landings.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Lunch Break
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            After an intense morning of classes and flight training, students take a well-deserved break for lunch. This is not only a time to refuel the body but also an opportunity to socialize with peers, share experiences, and build camaraderie with fellow aviation enthusiasts.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Study and Preparation
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            The aviation industry demands attention to detail, and students spend their afternoons reviewing class notes, studying aviation regulations, and preparing for upcoming exams. The ability to absorb vast amounts of information and apply it with precision is crucial for success in this field.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Evening Routine
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            As the day winds down, aviation students often engage in debrief sessions with instructors, discussing their performance during flight training and addressing any questions or concerns. Some students use the evening to log their flight hours, ensuring they meet the requirements for various certifications.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Conclusion
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            A Day in the Life of an Aviation Student is a well-orchestrated blend of academic learning, practical training, and a deep passion for flight. From sunrise to sunset, these students dedicate themselves to mastering the skills and knowledge necessary to soar among the clouds. Each day is an opportunity to get closer to the realization of their dream – taking command of an aircraft and navigating the boundless skies with skill and confidence.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
        </div>
    )
}
