import React from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function Blog25() {
    return (
        <div>
            <div className='xl:pt-0 sm:pt-12'>
                <FadeInSectionDownToUp>
                    <h1 className='text-center font-semibold text-3xl xl:text-3xl sm:text-xl max-sm:text-lg py-5'>Tamil Nadu: Your Launchpad for an Aviation Career in India</h1>
                </FadeInSectionDownToUp>
                <hr />
            </div>
            <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 text-justify'>
                <div className='py-5'>
                    <img src='https://ik.imagekit.io/mhcockpit1/blogs/Tamilnadu%20Launchpad.webp' alt='Tamil Nadu: Your Launchpad for an Aviation Career in India' />
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            India's aviation sector is zooming, fueled by a booming economy and a desire to travel. This creates a massive demand for skilled professionals, making an aviation degree your ticket to an exciting career. But where to launch your journey? Look no further than Tamil Nadu, a rising star in aviation education and training.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Here's why Tamil Nadu takes off:
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className="text-lg mb-5 text-justify">
                    <ul className='space-y-3'>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Star Institutions:</b> Ditch the doubt! Renowned universities and colleges offer various programs, from piloting the airspace to mastering airport operations or keeping aircraft shipshape. They collaborate with global airlines, ensuring you get globally recognized qualifications and industry-relevant knowledge.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Beyond Book Smarts:</b> Forget memorizing! Tamil Nadu's institutes invest in cutting- edge facilities like flight simulators, advanced workshops, and well-equipped labs. This hands-on approach equips you with the practical skills airlines crave.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Land Your Dream Job:</b> Don't sweat the future! Leading airlines actively recruit here. Many institutions have dedicated placement cells that connect you with potential employers, giving you a head start in your career race.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Aviation Hubs at Your Doorstep:</b> Imagine training near bustling airports like Chennai, Coimbatore, and Madurai! This proximity allows for practical training opportunities, industry visits, and potential internships, offering invaluable real-world insights.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Government Tailwind:</b> The Tamil Nadu government understands the power of aviation. They actively support the sector through scholarships, infrastructure development, and backing for aviation institutes, making it an attractive destination for aspiring aviation professionals.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                    </ul>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            But it's not just academics:
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className="text-lg mb-5 text-justify">
                    <ul className='space-y-3'>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Engage yourself in Tamil Nadu's vibrant culture:</b> Experience delicious food,captivating music, and rich traditions.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <div className='flex'>
                                <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                    <b>Feel at home with the welcoming people:</b> Make friends and feel supported throughout your journey.
                                </li>
                            </div>
                        </FadeInSectionDownToUp>
                    </ul>
                </div>
            </div>
        </div>
    )
}
