import React, { useState } from 'react'
// import { GalleryMOU } from '../../Components/Gallery/GalleryData'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'
import GalleryModal from '../../Components/Gallery/GalleryModal';

export default function Gallery() {

    const GalleryImages = Array.from({ length: 111 }, (_, i) => ({
        src: `https://ik.imagekit.io/mhcockpit1/Gallery/%20%20(${i + 1}).webp`,
    }));

    const [modalImageSrc, setModalImageSrc] = useState(null);
    
    const openModal = (src) => {
        setModalImageSrc(src);
    };

    const closeModal = () => {
        setModalImageSrc(null);
    };

    return (
        <div>
            <div className="relative text-justify">
                <div className="inset-0 overflow-hidden">
                    <img
                        className='w-full h-full object-cover animate-zoomOut'
                        src="https://ik.imagekit.io/mhcockpit1/GALLERY.webp"
                        alt="DGCA - License conversion"
                    />
                </div>
                <div className="relative py-10">
                    {/* <div className='grid grid-cols-3 max-sm:grid-cols-1 gap-10 px-20 max-sm:px-3'>
                        {GalleryMOU.map((image, index) => (
                            <div key={index}>
                                <FadeInSectionDownToUp>
                                    <img src={image.src} alt='MOU' />
                                    <p>{image.description}</p>
                                </FadeInSectionDownToUp>
                            </div>
                        ))}
                    </div> */}
                    <div className='grid grid-cols-3 max-sm:grid-cols-2 gap-10 max-sm:gap-3 px-20 max-sm:px-3 py-5'>
                        {GalleryImages.map((image, index) => (
                            <div key={index}>
                                <FadeInSectionDownToUp>
                                    <img src={image.src} alt='Gallery' onClick={() => openModal(image.src)} />
                                </FadeInSectionDownToUp>
                            </div>
                        ))}
                    </div>
                    <GalleryModal
                        isOpen={!!modalImageSrc}
                        imageSrc={modalImageSrc}
                        onClose={closeModal}
                    />
                </div>
            </div>
        </div>
    )
}
