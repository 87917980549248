import React from 'react';
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';

function AME() {

    return (
        <div>
            <div className="relative text-justify">
                <div className="inset-0 overflow-hidden">
                    <img
                        className='w-full h-full object-cover animate-zoomOut'
                        src="https://ik.imagekit.io/mhcockpit1/AME%20Instructors.webp"
                        alt="DGCA - License conversion"
                    />
                </div>
                <div className="relative py-10">
                    <div className='pl-40 xl:pl-40 sm:pl-20 max-sm:pl-10 text-4xl max-sm:text-2xl font-semibold tracking-tighter py-10'>
                        <FadeInSectionDownToUp>
                            <h1>AME Instructors.</h1>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5'>
                        <div className='py-5 text-3xl max-sm:text-lg font-bold text-center bg-black text-white rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p>Job Description.</p>
                                <p>Department : Mechanical / Avionics</p>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Skills Required.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Teaching experience in an AME institute approved by DGCA.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Job Requirments.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        BAMEL – DGCA License Holder in ES, IS and RN (Avionics), HA and JE (Mechanical).
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Knowledge of trends, developments, new technologies affecting the Mechanical/Avionics program.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Ability to communicate effectively, verbally and in writing, and to relate to others in a professional, helpful manner.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Functions of AME Instructor.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        The Instructor of Mechanical/Avionics is responsible for the instruction of courses in the area of Mechanical/Avionics, and related courses for majors and non-majors with the primary responsibility of providing a quality instruction for a diverse student population and performing instructional duties and responsibilities in accordance with the philosophy, mission, policies and procedures of the college.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <div className='px-6 max-sm:px-0 py-5 text-xl'>
                                <FadeInSectionDownToUp>
                                    <p>
                                        Within the scope of college policies and procedures, this position:<br />
                                        Teaches assigned Mechanical/Avionics related courses and associated labs in accordance with the college’s workload policy; maintains written instructional standards; facilitates instruction using alternative delivery methods as needed; informs students in writing of instructional standards; posts and maintains office hours; participates in the assessment of student learning outcomes; establishes, maintains and submits accurate student and instructional records in a timely manner.
                                    </p>
                                </FadeInSectionDownToUp>
                            </div>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Develops new courses, curriculum, instructional materials, and evaluation tools; reviews and updates course outlines, textbooks, laboratories and course procedure sheets for Mechanical/Avionics courses; assists with the preparation of course proposals, curriculum updates if any.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Applies and maintains standards of quality operating methods, processes, systems, and procedures; implements changes as necessary to maintain a successful Mechanical/Avionics program; integrates knowledge of industry trends and professional training to continuously improve program quality.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Participates in Mechanical/Avionics course scheduling, department, division and college meetings; serves on college committees as assigned Performs other related duties as assigned.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='flex justify-center items-center'>
                        <FadeInSectionDownToUp>
                            <button
                                className='px-5 py-1 bg-slate-600 text-white font-semibold rounded-lg animate-pulse hover:scale-105 transition-transform duration-700 ease-in-out'
                            >
                                <a href='https://forms.gle/BQKYYuKCrrqaae2KA' target='blank'>
                                    Apply Now
                                </a>
                            </button>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AME;
