import React from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function Airbus() {
    return (
        <div>
            <div className='py-60 max-sm:py-20 pl-20 max-sm:pl-10 text-3xl'>
                <FadeInSectionDownToUp>
                    <h1 className='font-bold tracking-wide text-4xl'>Airbus <span className='text-gray-500'>320.</span></h1>
                    <h2>Airbus 320 Style Pannel & Components</h2>
                </FadeInSectionDownToUp>
            </div>
            <div className='flex max-sm:block justify-center items-center gap-40 xl:gap-40 sm:gap-20 py-5 px-10'>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='text-gray-500 text-2xl text-justify'>
                            The Runway may end, But the thrill <br className='max-sm:hidden' />
                            of Flight in a <b>Simulator</b> never does
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='text-3xl max-sm:text-2xl'>
                    <FadeInSectionDownToUp>
                        <p className='py-5'>
                            USB Plug n Play Modules to
                        </p>
                        <p className='font-bold'>
                            Start building your<br />
                            Home <span className='text-blue-700'>Cockpit</span> today !
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className='py-20'>
                <div className='relative'>
                    <div className='relative'>
                        <img src='https://ik.imagekit.io/mhcockpit1/Airbus%20320%20-%20Image.png' alt='Cessna' />
                    </div>
                    <div className='absolute bottom-1/2 xl:bottom-1/3 sm:bottom-1/3 max-sm:bottom-1/3 left-52 xl:left-52 sm:left-5 max-sm:left-10 max-sm:right-14 sm:right-1/4'>
                        <FadeInSectionDownToUp>
                            <button className='text-start text-white bg-black bg-opacity-50 py-5 px-7 rounded-3xl'>
                                <span className='text-3xl max-sm:text-xl'>Click here for</span><br />
                                <span className='font-bold clear-start text-6xl max-sm:text-3xl'>Quotation</span>
                            </button>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
            </div>
            <div className='px-40 xl:px-40 sm:px-10 max-sm:px-10 text-xl py-5 text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        Select from various options like DIY Kits, 3D printed affordable panels. Realistic 11 Laser cut & Engraved panels, Ready to Go USB plug & play type components to match your requirements.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='px-40 xl:px-40 sm:px-10 max-sm:px-10 text-xl py-5 text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        All products are compatible with A2A airbus C172, FSX, P3D, Xplane and FS2020.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-6 px-40 xl:px-40 sm:px-10 max-sm:px-5 py-5'>
                <div className='bg-black text-white p-8 w-full h-full rounded-3xl'>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-bold text-xl'>
                                Airbus Product Details
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='text-xl py-5 text-justify'>
                        <FadeInSectionDownToUp>
                            <p>
                                Overhead Module completely assembled and manufactured with high quality acrylic panels. Frame is made of laser cut steel and painted with anticorrosive industrial paint. The panel is non backlit type.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-semibold text-xl'>
                                Main Features of Overhead Panel are
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Realistic Korry type switches.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Panels in two plates for future backlighting.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Steel frame with mounting holes.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    All indicators, switches and other elements are 1:1.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Set of Control cards to manage the entire module.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    All Overhead buttons are functional.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    USB plug n play.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Buttons & Knobs are 3D printed in 1:1.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Approx Measurements (in cm) are: 62.5 x 61.9 x 11.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Approximate weight of the packaged product: 20Kg.
                                </li>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                </div>
                <div className='bg-black text-white p-8 w-full h-full rounded-3xl'>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-bold text-xl'>
                                Airbus A320 Style FCU
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='text-xl py-5 text-justify'>
                        <FadeInSectionDownToUp>
                            <p>
                                A320 Style Flight Control Unit (FCU) manufactured with high quality acrylic panels. USB Plug and play module, no dummy buttons. Final functionality depends on addon used. The panel is non backlit type.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-semibold text-xl'>
                                Main Features of FCU are
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Realistic Korry type switches.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Panels in two plates for future backlighting.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Box type enclosure for Table Top installation.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    All indicators, switches and other elements are 1:1.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Set of Control cards to manage the entire module.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Rotary switches have Airbus type PUSH-PULL functionality.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    USB plug n play.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Knobs are 3D printed in 1:1.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Approx Measurements (in cm) are: 26 x 90 x 10.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-start'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Approximate weight of the packaged product: 1Kg.
                                </li>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4 px-40 xl:px-40 sm:px-10 max-sm:px-5 py-5'>
                <div className='text-black p-8 w-full h-full rounded-3xl shadow-2xl'>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-bold text-xl'>
                                Airbus A320 Style EFIS Panel
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='text-xl py-5 text-justify'>
                        <FadeInSectionDownToUp>
                            <p>
                                It is a full scale replica of AIRBUS Electronic Flight Instrument System Panel (EFIS). The module is USB Plug-n-Play. It is compatible with all major flight sim A320 addons. The panel is non backlit type.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-semibold text-xl'>
                                Main Features of EFIS Panel are
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Realistic Korry type switches.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Panels in two plates for future backlighting.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Box type enclosure for Table Top installation.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    All indicators, switches and other elements are 1:1.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Set of Control cards to manage the entire module.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Baro Rotary has Airbus type PUSH functionality to tune 1013 QNH.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    USB plug n play.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Knobs are 3D printed in 1:1.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Approx Measurements (in cm) are: 16 x 90 x 10.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Approximate weight of the packaged product: 1Kg.
                                </li>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                </div>
                <div className='text-black p-8 w-full h-full rounded-3xl shadow-2xl'>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-bold text-xl'>
                                Airbus A320 Style Throttle Quadrant Product Details
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='text-xl py-5 text-justify'>
                        <FadeInSectionDownToUp>
                            <p>
                                Overhead Module completely assembled and manufactured with high quality acrylic panels. Frame is made of laser cut steel and painted with anticorrosive industrial paint. The panel is non backlit type.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-semibold text-xl'>
                                Main Features of Throttle Unit are
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Removable Flap & Spoiler Panels.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Panels in two plates for future backlighting.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Steel internals for realistic throttle resistance.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    All throttle parts are approx 1:1.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Control card to manage the entire module.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    All buttons are functional.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    USB plug n play.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Product is 3D printed in approx 1:1.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Approx Measurements (in cm) are: 30 x 30 x 20.
                                </li>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <li className='flex items-center'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span>
                                    Approximate weight of the packaged product: 3Kg.
                                </li>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='flex justify-center items-start gap-2 px-40 xl:px-40 sm:px-10 max-sm:px-5 py-5'>
                <div className='bg-black text-white p-8 w-full h-full rounded-3xl shadow-2xl'>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-bold text-xl'>
                                Airbus A320 Style Instrument Panel Frames
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='text-xl py-5 text-justify'>
                        <FadeInSectionDownToUp>
                            <p>
                                MIP & Pedestal frames manufactured with high quality stainless steel. Frames are laser cut in steel and painted with anticorrosive industrial paint. The panel Structure is compatible with all our A320 panels & components.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='font-semibold text-xl'>
                                Main Features of FCU are
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg mb-5 text-justify py-5">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Realistic switches.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Panels in two plates for future backlighting.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Box type enclosure for Table Top installation.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        All indicators, switches and other elements are 1:1.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Set of Control cards to manage the entire module.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        USB plug n play,.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Knobs are 3D printed in 1:1.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Measurements vary based on the product.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Approximate weight of the packaged product: 1Kg
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Contact us for fully assembled pedestal
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
