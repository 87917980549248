import React from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function TermsAndConditions() {
    return (
        <div>
            <div className='px-20 max-sm:px-5 py-5 text-3xl font-semibold'>
                <FadeInSectionDownToUp>
                    <h1>MH Cockpit Terms and Conditions</h1>
                </FadeInSectionDownToUp>
            </div>
            <hr />
            <div className='px-20 max-sm:px-5 py-5 text-2xl font-semibold'>
                <FadeInSectionDownToUp>
                    <p>
                        Introduction
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-3 px-20 max-sm:px-5 text-lg text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        These Terms and Conditions ("Terms") govern the use of any services, products, or information (collectively the "Services") provided by MH Cockpit ("Company," "we," "us," or "our"). By accessing or using our Services, you ("user," "you," or "your") signify that you have read, understood, and agree to be bound by these Terms. If you do not agree, please do not use the Services.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='px-20 max-sm:px-5 py-5 text-2xl font-semibold'>
                <FadeInSectionDownToUp>
                    <p>
                        Services
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-3 px-20 max-sm:px-5 text-lg text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        Description: Provide a clear outline of the specific services offered by MH Cockpit. Be explicit about features, limitations, and what the user can expect. Changes: The Company reserves the right to modify, suspend, or discontinue the Services, or any portion thereof, at any time with or without notice. You agree that the Company will not be liable to you or any third party for such changes.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='px-20 max-sm:px-5 py-5 text-2xl font-semibold'>
                <FadeInSectionDownToUp>
                    <p>
                        User Accounts
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-3 px-20 max-sm:px-5 text-lg text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        Registration: You may be required to register for an account to access certain features of the Services. You are responsible for maintaining the confidentiality of your account information, including your password. Accuracy: You represent and warrant that all information you provide is accurate and complete. You agree to update your information as necessary. Unauthorized Use: You are responsible for all activity that occurs under your account. Notify the Company immediately of any unauthorized use.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='px-20 max-sm:px-5 py-5 text-2xl font-semibold'>
                <FadeInSectionDownToUp>
                    <p>
                        Eligibility
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-3 px-20 max-sm:px-5 text-lg text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        Age: State the minimum age requirement for using the Services (usually 18 years or older). If your services can be used by minors, consider additional provisions and requirements for parental consent.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='px-20 max-sm:px-5 py-5 text-2xl font-semibold'>
                <FadeInSectionDownToUp>
                    <p>
                        Intellectual Property
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-3 px-20 max-sm:px-5 text-lg text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        Ownership: The Services and all content, including text, graphics, logos, software, and trademarks, are the property of the Company or its licensors and are protected by applicable intellectual property laws. Limited License: You are granted a limited, non-exclusive, non-transferable license to access and use the Services solely for your personal or internal business purposes, subject to these Terms. Restrictions: You shall not copy, modify, distribute, sell, or lease any part of our Services. Nor may you reverse engineer or attempt to extract the source code.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='px-20 max-sm:px-5 py-5 text-2xl font-semibold'>
                <FadeInSectionDownToUp>
                    <p>
                        User Content
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-3 px-20 max-sm:px-5 text-lg text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        Responsibility: You are solely responsible for any content you upload, post, or transmit through the Services ("User Content"). Grant of License: You grant the Company a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, and distribute your User Content in connection with the Services. Representations and Warranties: You represent and warrant that: You own or have the necessary rights to your User Content. Your User Content does not infringe on intellectual property rights, privacy, publicity, or other rights of any third party. Your User Content does not violate any applicable laws or regulations.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='px-20 max-sm:px-5 py-5 text-2xl font-semibold'>
                <FadeInSectionDownToUp>
                    <p>
                        Acceptable Use
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-3 px-20 max-sm:px-5 text-lg text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        You agree to use the Services only for lawful purposes. Prohibited activities include: Transmitting spam or other unsolicited communications. Engaging in any activity that disrupts or interferes with the Services. Attempting to gain unauthorized access to the Services. Using the Services to violate any applicable law or regulation.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='px-20 max-sm:px-5 py-5 text-2xl font-semibold'>
                <FadeInSectionDownToUp>
                    <p>
                        Fees and Payment
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-3 px-20 max-sm:px-5 text-lg text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        Fees: Clearly outline any fees associated with your services (subscriptions, one-time fees, etc.). Payment Terms: Specify your payment terms, billing frequencies, and accepted payment methods. Changes: The Company reserves the right to change its fees at any time, with reasonable notice.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='px-20 max-sm:px-5 py-5 text-2xl font-semibold'>
                <FadeInSectionDownToUp>
                    <p>
                        Disclaimer of Warranties
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-3 px-20 max-sm:px-5 text-lg text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED. THE COMPANY DISCLAIMS ALL WARRANTIES, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='px-20 max-sm:px-5 py-5 text-2xl font-semibold'>
                <FadeInSectionDownToUp>
                    <p>
                        Limitation of Liability
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-3 px-20 max-sm:px-5 text-lg text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE COMPANY'S TOTAL LIABILITY ARISING OUT OF THESE TERMS SHALL NOT EXCEED THE FEES PAID BY YOU.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='px-20 max-sm:px-5 py-5 text-2xl font-semibold'>
                <FadeInSectionDownToUp>
                    <p>
                        Indemnification
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-3 px-20 max-sm:px-5 text-lg text-justify'>
                <FadeInSectionDownToUp>
                    <p>
                        You agree to indemnify and hold the Company harmless from any claims, losses, damages, and expenses arising from your use of the Services or breach of these Terms.
                    </p>
                </FadeInSectionDownToUp>
            </div>
        </div>
    )
}
